import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as OpenIcon } from '../../content/icons/Icon-Open.svg';
import MomentHelper from '../../helpers/MomentHelper';
import diaryProvisioningStatus from '../../enums/appManagement/diaryProvisioningStatus';
import DataTable from './DataTable';
import { Tooltip } from 'reactstrap';
import { useIntl } from 'react-intl';
import { ReactComponent as InfoIcon } from '../../content/icons/Icon-Info.svg';

function TestDiaryTableOld({
    data,
    application,
    handleApplicationSetup,
    isLoading,
    renderEmptyResult,
    showLinkToDiary,
}) {
    const intl = useIntl();
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    let columns = [
        {
            id: 'appName',
            name: intl.formatMessage({ id: 'AppManagement.TestDiaryTable.Name' }),
            selector: (x) => x.name,
            displayFormat: (x) => <b>{x.name}</b>,
        },
        {
            id: 'appId',
            name: intl.formatMessage({ id: 'AppManagement.TestDiaryTable.ID' }),
            selector: (x) => x.id,
        },
        {
            id: 'createdBy',
            name: intl.formatMessage({ id: 'AppManagement.TestDiaryTable.CreatedBy' }),
            selector: (x) => x.createdByUser.email,
        },
        {
            id: 'createdOn',
            name: intl.formatMessage({ id: 'AppManagement.TestDiaryTable.CreatedOn' }),
            selector: (x) => x.createdDate,
            displayFormat: (x) => MomentHelper.newInstance(x.createdDate).format('LL'),
        },
        {
            id: 'type',
            name: intl.formatMessage({ id: 'AppManagement.TestDiaryTable.Type' }),
            displayFormat: (x) => {
                const type =
                    x.type === 'NewWithDemoData'
                        ? 'td-type with-data-type'
                        : x.type === 'New'
                        ? 'td-type no-data-type'
                        : 'td-type linked-type';
                const typeMessage =
                    x.type === 'NewWithDemoData' ? 'New with data' : x.type === 'New' ? 'New' : 'Linked';
                return <div className={type}>{typeMessage}</div>;
            },
        },
        {
            id: 'status',
            name: intl.formatMessage({ id: 'AppManagement.TestDiaryTable.Status' }),
            displayFormat: (x) => {
                let className = 'td-diary-status';
                if (x.status === diaryProvisioningStatus.active) {
                    className += ' td-diary-status--active';
                } else if (x.status === diaryProvisioningStatus.pending) {
                    className += ' td-diary-status--pending';
                } else if (x.status === diaryProvisioningStatus.disabled) {
                    className += ' td-diary-status--disabled';
                }
                return <div className={className}>{x.status.toUpperCase()}</div>;
            },
        },
        {
            id: 'connectedApps',
            name: intl.formatMessage({ id: 'AppManagement.TestDiaryTable.ConnectedApps' }),
            displayFormat: (x) => {
                return (
                    <div>
                        {x.connectedApps.map((app, index) => (
                            <p key={index} className="test-diary-connected-app text-capitalize">
                                {app}
                            </p>
                        ))}
                        {application && handleApplicationSetup && (
                            <div className="install-link">
                                <button className="button-link diary-install-button" onClick={handleAppSetupClick(x)}>
                                    Uninstall app
                                </button>
                            </div>
                        )}
                    </div>
                );
            },
            headerCellElement: (
                <>
                    <span className="badge">
                        <i color="secondary" id={'connected-apps-tooltip-info'}>
                            <InfoIcon />
                        </i>
                    </span>
                    <Tooltip
                        className="connected-apps-tooltip"
                        id="connected-apps-tooltip-id"
                        isOpen={tooltipOpen}
                        placement="top"
                        target="connected-apps-tooltip-info"
                        toggle={() => {
                            setTooltipOpen(!tooltipOpen);
                        }}
                    >
                        You can connect apps to your test diary by logging into the diary and installing from the app
                        store.
                    </Tooltip>
                </>
            ),
            headerCellElements: 'header-cell-elements',
        },
        {
            id: 'linkToDiary',
            name: '',
            displayFormat: (x) => (
                <Link className="diary-link" target="_blank" to={{ pathname: x.diaryUrl }}>
                    <OpenIcon />
                </Link>
            ),
        },
    ];

    if (!showLinkToDiary) {
        columns = columns.filter((x) => x.id !== 'linkToDiary');
    }

    const handleAppSetupClick = (testDiary) => {
        return () => handleApplicationSetup(testDiary);
    };

    return (
        <div className="test-diaries-table">
            <DataTable
                className="mb-0"
                columns={columns}
                data={data}
                isLoading={isLoading}
                renderEmptyResult={renderEmptyResult}
            />
        </div>
    );
}

TestDiaryTableOld.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    application: PropTypes.object,
    handleApplicationSetup: PropTypes.func,
    isLoading: PropTypes.bool,
    renderEmptyResult: PropTypes.elementType,
    showAccessToken: PropTypes.bool,
    showLinkToDiary: PropTypes.bool,
};

export default TestDiaryTableOld;
