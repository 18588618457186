import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
import AdminAppService from '../../../services/admin/AppService';
import appType from '../../../enums/appType';
import LoadingSpinner from '../../common/LoadingSpinner';
import SideBarContentPage from '../../common/SideBarContentPage';
import appNavigationTabs from '../../../enums/appNavigationTabs';
import PublicAppsPage from './PublicAppsPage';
import PrivateAppsPage from './PrivateAppsPage';
import { useIntl } from 'react-intl';
import App from '../../../model/appManagement/App';

const AppListBasePage = () => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(true);
    const [publicAppsLabel, setPublicAppsLabel] = useState(
        intl.formatMessage({ id: 'Admin.AppList.PublicAppsTitle' }, { length: 0 })
    );
    const [privateAppsLabel, setPrivateAppsLabel] = useState(
        intl.formatMessage({ id: 'Admin.AppList.PrivateAppsTitle' }, { length: 0 })
    );
    const [currentTabKey, setCurrentTabKey] = useState('publicApps');
    const [appsList, setAppsList] = useState<App[]>([]);

    useEffect(() => {
        AdminAppService.getAllApps()
            .then((response) => {
                setAppsList(response);
                let publicApps = response.filter((apps) => apps.appType === appType.public);
                let privateApps = response.filter((apps) => apps.appType === appType.private);

                setPublicAppsLabel(
                    intl.formatMessage({ id: 'Admin.AppList.PublicAppsTitle' }, { length: publicApps.length })
                );
                setPrivateAppsLabel(
                    intl.formatMessage({ id: 'Admin.AppList.PrivateAppsTitle' }, { length: privateApps.length })
                );
            })
            .catch((error) => {
                // TODO: Handle error
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [privateAppsLabel, intl]);

    const appsListNavigationTabLabels = () => {
        return [
            {
                key: appNavigationTabs.configure,
                name: intl.formatMessage({ id: 'Admin.AppList.ManageApps' }),
                isActive: true,
                subItems: [
                    {
                        key: 'publicApps',
                        name: publicAppsLabel,
                    },
                    {
                        key: 'privateApps',
                        name: privateAppsLabel,
                    },
                ],
            },
        ];
    };

    function getTabs() {
        // insert navigation components here with appropriate key
        const tabs = [
            <PrivateAppsPage
                onTabChanged={onTabChanged}
                key="privateApps"
                title={privateAppsLabel}
                apps={appsList.filter((apps) => apps.appType === appType.private)}
            />,
            <PublicAppsPage
                onTabChanged={onTabChanged}
                key="publicApps"
                title={publicAppsLabel}
                apps={appsList.filter((apps) => apps.appType === appType.public)}
            />,
        ];

        return tabs;
    }

    function onTabChanged(tabKey: string) {
        if (tabKey !== currentTabKey) {
            setCurrentTabKey(tabKey);
        }
    }

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="app-list-base-page">
            <Fragment>
                <SideBarContentPage
                    tabLabels={appsListNavigationTabLabels()}
                    tabs={getTabs()}
                    onTabChanged={onTabChanged}
                    currentTabKey={currentTabKey}
                    isLoading={isLoading}
                />
            </Fragment>
        </div>
    );
};
export default AppListBasePage;
