import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AppStoreListingSubmissionLog from '../../../model/appManagement/admin/AppStoreListingSubmissionLog';
import App from '../../../model/appManagement/App';
import ErrorMessage from '../../../model/services/ErrorMessage';
import AppService from '../../../services/admin/AppService';
import DataTable, { DataTableColumn } from '../../common/DataTable';
import { FormGroup, Col, Label } from 'reactstrap';
import { ReactComponent as InfoIcon } from '../../../content/icons/Icon-Info.svg';

const AppStoreListingSubmissionLogsTable = (props: AppStoreListingSubmissionLogsTableProps) => {
    const intl = useIntl();

    const [logs, setLogs] = useState<AppStoreListingSubmissionLog[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        AppService.getAppStoreListingSubmissionLogs(props.application.appId)
            .then((response) => {
                setLogs(response);
            })
            .catch((error: ErrorMessage) => {})
            .finally(() => {
                setIsLoading(false);
            });
    }, [setIsLoading, props.application.appId]);

    const getColumns = () => {
        let columns: DataTableColumn[] = [
            {
                name: intl.formatMessage({ id: 'AppManagement.ListingSubmissionLogTable.DateTime' }),
                className: 'date',
                selector: (x: AppStoreListingSubmissionLog) => x.lastUpdated,
            },
            {
                name: intl.formatMessage({ id: 'AppManagement.ListingSubmissionLogTable.UpdatedBy' }),
                className: 'updated-by',
                selector: (x: AppStoreListingSubmissionLog) => x.updatedByUser,
            },
            {
                name: intl.formatMessage({ id: 'AppManagement.ListingSubmissionLogTable.Status' }),
                className: 'status',
                selector: (x: AppStoreListingSubmissionLog) => x.newStatus,
            },
            {
                name: intl.formatMessage({ id: 'AppManagement.ListingSubmissionLogTable.RejectionReason' }),
                className: 'rejection-reason',
                selector: (x: AppStoreListingSubmissionLog) => (x.rejectionReason ? x.rejectionReason : 'N/A'),
            },
            {
                name: intl.formatMessage({ id: 'AppManagement.ListingSubmissionLogTable.Comments' }),
                className: 'comments',
                selector: (x: AppStoreListingSubmissionLog) => x.comments,
            },
        ];
        return columns;
    };

    return (
        <FormGroup row>
            {logs.length && (
                <Fragment>
                    <Label sm={12} className="pt-0 pb-0">
                        <h5>
                            <FormattedMessage id="AppManagement.ListingSubmissionLogTable.Logs" />
                        </h5>
                    </Label>
                    <Col className="app-submission-logs" sm={12}>
                        <DataTable columns={getColumns()} data={logs} isLoading={isLoading} />
                    </Col>
                </Fragment>
            )}
            {!logs.length && (
                <div className="submission-page-info-section mb-3">
                    <h6>
                        <InfoIcon />
                        <FormattedMessage id="AppManagement.ListingSubmissionLogTable.NoSubmissions" />
                    </h6>
                </div>
            )}
        </FormGroup>
    );
};

export interface AppStoreListingSubmissionLogsTableProps {
    application: App;
}

export default AppStoreListingSubmissionLogsTable;
