/* eslint-disable */
import { adminApi } from '../../helpers/ApiHelper';
import ErrorMessage from '../../model/services/ErrorMessage';
import GetTestDiariesAdminRequest from '../../model/TestDiaries/admin/GetTestDiariesAdminRequest';
import TestProvider from '../../model/TestDiaries/TestProvider';
import PagedResult from '../../model/utility/PagedResult';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const verifyTestProvider = async (providerId: number, organisationId: number) => {
    return await adminApi
        .post<TestProvider, string>(`TestDiaries/Verify`, { providerId, organisationId })
}

const linkTestProviderToOrganisation = async (providerId: number, organisationId: number) => {
    return await adminApi
        .post<TestProvider, string>(`TestDiaries`, { providerId, organisationId })
}

const getTestDiaries = async ({ pageNumber, pageSize, searchFilter }: GetTestDiariesAdminRequest) => {
    return await adminApi
        .get<PagedResult<TestProvider>, string>(`TestDiaries`, {
            search: searchFilter,
            pageSize: pageSize,
            pageNumber: pageNumber,
        })
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const unlinkTestDiary = async (testDiaryId: number, organisationId: number) => {
    return await adminApi
        .post<undefined, string>(`TestDiaries/${testDiaryId}/${organisationId}/Unlink`)
        .then((response) => {
            return response;
        });
};

export default {
    getTestDiaries,
    unlinkTestDiary,
    verifyTestProvider,
    linkTestProviderToOrganisation
};
