import * as React from 'react';
import { Fragment } from 'react';
import LoadingSpinner from './LoadingSpinner';
import { Table } from 'reactstrap';
import App from '../../model/appManagement/App';
import { useIntl, FormattedMessage } from 'react-intl';

const DataTable = ({
    columns,
    data,
    isLoading,
    nextPageUrl,
    handleLoadMoreClick,
    expandRow,
    renderEmptyResult,
    className,
    rowOnChange,
    rowClassName,
}: DataTableProps) => {
    const intl = useIntl();

    const renderPagination = () => {
        return (
            <div className="data-list__pagination">
                <button className="data-list__pagination__button button-link" onClick={handleLoadMoreClick}>
                    <FormattedMessage id="CommonComponents.DataTable.LoadMore" />
                </button>
            </div>
        );
    };

    return (
        <Fragment>
            <Table className={`data-table ${className}`}>
                <thead>
                    <tr>
                        {columns.map((col, colIindex) => {
                            return (
                                <th className={col.className} key={colIindex}>
                                    <div className={col.headerCellElements}>
                                        <div className="col-name">{col.name}</div>
                                        <div>{col.headerCellElement}</div>
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {isLoading && (
                        <tr>
                            <td colSpan={columns.length}>
                                <LoadingSpinner
                                    noMargin={true}
                                    loadingText={intl.formatMessage({ id: 'CommonComponents.DataTable.Loading' })}
                                />
                            </td>
                        </tr>
                    )}
                    {data !== undefined &&
                        !isLoading &&
                        data.length > 0 &&
                        data.map((row, rowIndex) => {
                            return (
                                <Fragment key={rowIndex}>
                                    <tr
                                        className={rowClassName ? rowClassName : ''}
                                        onClick={rowOnChange ? () => rowOnChange(rowIndex) : undefined}
                                    >
                                        {columns.map((col, colIindex) => (
                                            <td key={colIindex}>
                                                {col.displayFormat ? col.displayFormat(row) : col.selector(row)}
                                            </td>
                                        ))}
                                    </tr>
                                    {expandRow?.expanded === rowIndex && expandRow?.renderer && (
                                        <tr>
                                            <td colSpan={columns.length}>{expandRow?.renderer(row)}</td>
                                        </tr>
                                    )}
                                </Fragment>
                            );
                        })}
                    {data !== undefined && data.length <= 0 && !isLoading && (
                        <tr>
                            <td colSpan={columns.length} className="data-list__no-results">
                                {renderEmptyResult ? renderEmptyResult() : 'No results.'}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {data !== undefined && data.length > 0 && nextPageUrl && renderPagination()}
        </Fragment>
    );
};

export interface DataTableProps {
    columns: DataTableColumn[];
    data?: Object[];
    isLoading?: boolean;
    handleLoadMoreClick?: React.MouseEventHandler<HTMLButtonElement>;
    renderPagination?: Function;
    renderEmptyResult?: Function;
    nextPageUrl?: string;
    className?: string;
    application?: App;
    expandRow?: DataTableExpandRow;
    rowOnChange?: Function;
    rowClassName?: string;
}

export interface DataTableColumn {
    name: string;
    className: string;
    headerCellElement?: JSX.Element;
    headerCellElements?: string;
    // Need a solution to eliminate these any values
    selector: (x: any) => any;
    displayFormat?: (x: any) => any;
}

export interface DataTableExpandRow {
    expanded: number;
    renderer: <T>(row: T) => JSX.Element;
}

export default DataTable;
