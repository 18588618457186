import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import signInStatus from '../enums/signInStatus';
import Organisation from '../model/user/Organisation';
import AccountService from '../services/AccountService';
import { useAuthentication } from './AuthenticationContext';

const OrganisationContext = React.createContext<IOrganisationContext>({
    userOrganisations: [],
    defaultOrganisation: undefined,
    setDefaultOrganisation: () => {},
});

interface IOrganisationContext {
    userOrganisations: Organisation[];
    defaultOrganisation?: Organisation;
    setDefaultOrganisation: Function;
}

type OrganisationProviderProps = {
    children: React.ReactNode;
};

export const useOrganisation = () => {
    return useContext(OrganisationContext);
};

export const OrganisationProvider = ({ children }: OrganisationProviderProps) => {
    const [userOrganisations, setUserOrganisations] = useState<Organisation[]>([]);
    const [defaultOrganisation, setUserDefaultOrganisation] = useState<Organisation>();
    const [isLoading, setIsLoading] = useState(true);
    const { authInfo } = useAuthentication();

    useEffect(() => {
        if (authInfo && authInfo.status === signInStatus.Success && !authInfo.isAdmin) {
            AccountService.getUserOrganisations().then((userOrganisations: any) => {
                setUserOrganisations(userOrganisations);
                let defaultOrganisation =
                    userOrganisations.find((x: Organisation) => x.isDefault) ?? userOrganisations[0];

                if (defaultOrganisation) setUserDefaultOrganisation(defaultOrganisation);

                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }, [authInfo]);

    function setDefaultOrganisation(organisationId: number) {
        if (authInfo && authInfo.username) {
            AccountService.setDefaultOrganisation(authInfo.username, organisationId).then(() => {
                let defaultOrganisation = userOrganisations.find((x) => x.id === organisationId);
                if (defaultOrganisation) setUserDefaultOrganisation(defaultOrganisation);
            });
        }
    }

    const value = {
        userOrganisations,
        defaultOrganisation: defaultOrganisation,
        setDefaultOrganisation,
    };

    return <OrganisationContext.Provider value={value}>{!isLoading && children}</OrganisationContext.Provider>;
};
