import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CopyToClipboard from '../../../common/CopyToClipboard';
import { FormGroup } from 'reactstrap';

const RedirectUrlCard = (props: RedirectUrlCardProps) => {
    const intl = useIntl();

    return (
        <div className="oauth-card">
            <h5 className="mb-3">
                <FormattedMessage id="AppManagement.AppDetails.RedirectUrl" />
            </h5>
            <p className="mb-3">
                <FormattedMessage id="AppManagement.AppDetails.RedirectUrlDescription" />
            </p>
            <p className="mb-3">
                <FormattedMessage id="AppManagement.AppDetails.RedirectUrlSubDescription" />
            </p>
            <FormGroup>
                <div className="install-url">
                    <div className="copy-box">
                        <div className="copy-text">
                            <input
                                name="redirectUrlInput"
                                type="text"
                                placeholder={intl.formatMessage({
                                    id: 'AppManagement.AppDetails.RedirectUrlPlaceholder',
                                })}
                                value={props.redirectUrl}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    props.setRedirectUrl(e.currentTarget.value)
                                }
                            />
                        </div>
                        <div className="copy-icon">
                            <CopyToClipboard id="clientIdCopy" text={props.redirectUrl} isWhite={true} />
                        </div>
                    </div>
                </div>
            </FormGroup>
        </div>
    );
};

export interface RedirectUrlCardProps {
    redirectUrl: string;
    setRedirectUrl(url: string): void;
}

export default RedirectUrlCard;
