import React from 'react';
import ConsumerApiKeyList from './ConsumerApiKeyList';
import { Badge } from 'reactstrap';
import PropTypes from 'prop-types';

const heading = 'Consumer API Keys';

const ConsumerApiSection = (props) => {
    return (
        <>
            <div className="my-4">
                <h2>
                    {heading}
                    {props.isTestEnvironment && (
                        <Badge className="settings-badge" color="secondary">
                            {props.activeEnvironmentString} API Keys
                        </Badge>
                    )}
                </h2>
            </div>
            <ConsumerApiKeyList
                isTestEnvironment={props.isTestEnvironment}
                apiKey={props.apiKey}
                setApiKey={props.setApiKey}
            />
        </>
    );
};

ConsumerApiSection.propTypes = {
    activeEnvironmentString: PropTypes.string.isRequired,
    apiKey: PropTypes.shape({
        primaryToken: PropTypes.string.isRequired,
        secondaryToken: PropTypes.string,
    }).isRequired,
    isTestEnvironment: PropTypes.bool.isRequired,
    setApiKey: PropTypes.func.isRequired,
};

export default ConsumerApiSection;
