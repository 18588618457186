import React from 'react';
import { Badge, Form, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import EposAccessibleProviderList from './EposAccessibleProviderList';
import EposCredentialDisplay from './EposCredentialDisplay';

const heading = 'Epos Credentials';

const EposCredentialSection = (props) => {
    return (
        <>
            <div className="my-4">
                <h2>
                    {heading}
                    {props.isTestEnvironment && (
                        <Badge className="settings-badge" color="secondary">
                            {props.activeEnvironmentString} API Keys
                        </Badge>
                    )}
                </h2>
            </div>

            <Form key={props.activeEnvironmentString} className="epos-credentials-form my-4">
                <FormGroup>
                    <Label for="consumer-key">Consumer Key</Label>
                    <EposCredentialDisplay value={props.eposCredentials.consumerKey} id="consumer-key" />
                </FormGroup>

                <FormGroup>
                    <Label for="consumer-secret">Consumer Secret</Label>
                    <EposCredentialDisplay value={props.eposCredentials.consumerSecret} id="consumer-secret" />
                </FormGroup>
            </Form>

            <EposAccessibleProviderList
                activeEnvironmentString={props.activeEnvironmentString}
                isTestEnvironment={props.isTestEnvironment}
                eposCredentials={props.eposCredentials}
                setEposCredentials={props.setEposCredentials}
            />
        </>
    );
};

EposCredentialSection.propTypes = {
    activeEnvironmentString: PropTypes.string.isRequired,
    isTestEnvironment: PropTypes.bool.isRequired,
    eposCredentials: PropTypes.object.isRequired,
    setEposCredentials: PropTypes.func.isRequired,
};

export default EposCredentialSection;
