import React, { useState, useEffect, useCallback, Fragment } from 'react';
import Tag from '../common/Tag';
import { ReactComponent as OpenLinkIcon } from '../../content/icons/Open-Link.svg';
import tagColour from '../../enums/tagColour';
import LoadingSpinner from '../common/LoadingSpinner';
import CertificationService from '../../services/CertificationService';
import Collapsible from './CertificationCollapsible';
import infoBarType from '../../enums/infoBarType';
import InfoBarManager from '../common/InfoBarManager';
import { Button } from 'reactstrap';
import appNavigationTabs from '../../enums/appNavigationTabs';
import { FormattedMessage, useIntl } from 'react-intl';
import apiType from '../../enums/apiType';

function AppCertification(props) {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(true);
    const [checklistData, setChecklistData] = useState([]);
    const [infoBars, setInfoBars] = useState([]);
    const [isCheckListDisabled, setIsCheckListDisabled] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const setupChecklistData = useCallback(
        (checklistData) => {
            checklistData.forEach((item, index) => {
                item.checklistAnswerItems.forEach((checkbox) => {
                    checkbox.option = new Option('Done', checkbox.checklistAnswerId);
                });
                let answeredCount = 0;
                item.checklistQuestions.forEach((answered) => {
                    answeredCount += answered.answeredCheckboxes.length;
                });
                const questionsInSection =
                    item.checklistAnswerItems.filter((c) => !c.isNull).length * item.checklistQuestions.length;
                item.isOpen = questionsInSection === answeredCount ? false : true;
                item.index = index;
                item.apiType = props.application.apiType;
            });
            setChecklistData(checklistData);
        },
        [props.application.apiType]
    );

    const getApiTypeName = (type) => {
        if (type === apiType.consumerApi) {
            return intl.formatMessage({ id: 'AppManagement.AppCertification.ConsumerApi' });
        } else if (type === apiType.dataExtractApi) {
            return intl.formatMessage({ id: 'AppManagement.AppCertification.DataExtractApi' });
        } else if (type.toLowerCase() === apiType.eposApi.toLowerCase()) {
            return intl.formatMessage({ id: 'AppManagement.AppCertification.EposApi' });
        } else {
            return 'No type found';
        }
    };

    const validateChecklist = useCallback(
        (data) => {
            //enable continue button if all required items checked
            let requiredNotAnsweredCount = 0;
            let requiredTotalAns = 0;
            data.forEach((item) =>
                item.checklistQuestions.forEach((question) => {
                    if (question.isRequired) {
                        requiredTotalAns += 1 * item.checklistAnswerItems.filter((c) => !c.isNull).length;
                        const answeredRequired = question.answeredCheckboxes.filter((ans) => {
                            return ans.checklistQuestionId === question.checklistQuestionId && !ans.isNull;
                        });
                        requiredNotAnsweredCount += answeredRequired.length;
                    }
                })
            );
            if (requiredNotAnsweredCount === requiredTotalAns) {
                setIsFormValid(true);
            } else {
                setIsFormValid(false);
            }
        },
        [setIsFormValid]
    );
    function addErrorBar(message) {
        setInfoBars((i) => [...i, { type: infoBarType.error, message: message }]);
    }

    useEffect(() => {
        CertificationService.getCertificationChecklist(props.application.apiType, props.application.appId)
            .then((response) => {
                setupChecklistData(response.checklistSections);
                validateChecklist(response.checklistSections);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [props, props.application.apiType, setIsLoading, setChecklistData, validateChecklist, setupChecklistData]);

    const setIsOpen = (index, isopen) => {
        const copySections = [...checklistData];
        const updated = copySections.map((section) =>
            section.index === index ? { ...section, isOpen: isopen } : section
        );
        setChecklistData(updated);
    };

    const checkListChange = (value, checkBoxAnswerId, sectionIndex) => {
        setIsCheckListDisabled(true);
        const updatedIsChecked = !value.isChecked;

        if (updatedIsChecked) {
            CertificationService.saveChecklistAnswer(
                props.application.appId,
                value.checklistQuestionId,
                checkBoxAnswerId,
                updatedIsChecked
            )
                .then((response) => {
                    if (response.ok) {
                        if (response.data != null) {
                            updateChecklist(
                                updatedIsChecked,
                                checkBoxAnswerId,
                                value.checklistQuestionId,
                                sectionIndex,
                                response
                            );
                        } else {
                            addErrorBar(response.errorMessage);
                        }
                    }
                })
                .finally(() => {
                    setIsCheckListDisabled(false);
                });
        } else {
            CertificationService.deleteChecklistAnswer(
                props.application.id,
                value.checklistQuestionId,
                checkBoxAnswerId,
                updatedIsChecked
            )
                .then((response) => {
                    if (!response.ok) {
                        addErrorBar(response.errorMessage);
                    }
                })
                .finally(() => {
                    setIsCheckListDisabled(false);
                });
        }
    };

    function updateChecklist(isChecked, checkBoxAnswerId, questionId, sectionIndex, updatedCheckList) {
        setIsCheckListDisabled(true);
        const copyChecklistData = [...checklistData];
        if (!isChecked) {
            const updatedAns = copyChecklistData
                .find((section) => section.index === sectionIndex)
                .checklistQuestions.find((question) => {
                    return question.checklistQuestionId === questionId;
                })
                .answeredCheckboxes.filter((answer) => {
                    return answer.checklistAnswerId !== checkBoxAnswerId;
                });
            copyChecklistData
                .find((section) => section.index === sectionIndex)
                .checklistQuestions.find((question) => question.checklistQuestionId === questionId).answeredCheckboxes =
                updatedAns;
        } else {
            //if checked - add to the answerArray
            copyChecklistData
                .find((section) => section.index === sectionIndex)
                .checklistQuestions.find((question) => question.checklistQuestionId === questionId)
                .answeredCheckboxes.push(updatedCheckList);
        }
        setupChecklistData(copyChecklistData);
        validateChecklist(copyChecklistData);
        setIsCheckListDisabled(false);
    }

    const mainCheckListHeader = () => {
        switch (props.application.apiType) {
            case 'ConsumerApi':
                return (
                    <Fragment>
                        <div className="col-12">
                            <div className="row certification-header">
                                <div className="col-6">
                                    <FormattedMessage id="AppManagement.AppCertification.TestCaseScenarios" />
                                </div>
                                <div className="col-2">
                                    <FormattedMessage id="AppManagement.AppCertification.RequirementLevel" />
                                </div>
                                <div className="col-2">
                                    <span className="mr-4 float-right">
                                        <FormattedMessage id="AppManagement.AppCertification.StandardTest" />
                                    </span>
                                </div>
                                <div className="col-2">
                                    <span className="mr-4 float-right">
                                        <FormattedMessage id="AppManagement.AppCertification.PaymentViaStripe" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                );
            case 'EposApi':
                return (
                    <Fragment>
                        <div className="col-12">
                            <div className="row certification-header">
                                <div className="col-6">
                                    <FormattedMessage id="AppManagement.AppCertification.EposActions" />
                                </div>
                                <div className="col-3">
                                    <FormattedMessage id="AppManagement.AppCertification.RequirementLevel" />
                                </div>
                                <div className="col-3 ">
                                    <span className="mr-4 float-right">
                                        <FormattedMessage id="AppManagement.AppCertification.Checklist" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                );
            case 'DataExtractApi':
                return (
                    <Fragment>
                        <div className="col-12">
                            <div className="row certification-header">
                                <div className="col-8">
                                    <FormattedMessage id="AppManagement.AppCertification.TestCaseScenario" />
                                </div>
                                <div className="col-4">
                                    <span className="mr-4 float-right">
                                        <FormattedMessage id="AppManagement.AppCertification.Checklist" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                );
            default:
                return (
                    <Fragment>
                        <div className="col-12">
                            <FormattedMessage id="AppManagement.AppCertification.NoApiSelected" />
                        </div>
                    </Fragment>
                );
        }
    };

    return (
        <div className="">
            {isLoading ? (
                <LoadingSpinner loadingText={intl.formatMessage({ id: 'AppManagement.ApiCertification.Loading' })} />
            ) : (
                <Fragment>
                    <div className="certification-page">
                        <h1>
                            <FormattedMessage id="AppManagement.AppCertification.Certifications" />
                        </h1>
                        <div>
                            <p className="mb-0">
                                <FormattedMessage id="AppManagement.AppManagementPage.CertificationsDescription" />
                            </p>
                            <a className="open-link" href="/">
                                {intl.formatMessage(
                                    { id: 'AppManagement.AppCertification.LearnMoreAbout' },
                                    { apiType: getApiTypeName(props.application.apiType) }
                                )}
                                <i className="mx-2">
                                    <OpenLinkIcon />
                                </i>
                            </a>
                        </div>
                        <div className="mt-3">
                            <div className="col-md">
                                <div className="row">
                                    <div className="col-auto">
                                        <h3>
                                            <FormattedMessage id="AppManagement.AppCertification.SelectedApi" />
                                        </h3>
                                    </div>
                                    <div className="col-auto">
                                        <Tag value={getApiTypeName(props.application.apiType)} color={tagColour.Navy} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-2">
                            <InfoBarManager infoBarArray={infoBars} />
                            {mainCheckListHeader()}
                            {checklistData.map((checklistSection) => {
                                return (
                                    <Collapsible
                                        cSection={checklistSection}
                                        key={checklistSection.index}
                                        onChecklistChange={checkListChange}
                                        isChecklistDisabled={isCheckListDisabled}
                                        onToggleCollapse={setIsOpen}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <Button
                                className="mt-2"
                                color="primary"
                                disabled={!isFormValid}
                                onClick={() => props.onTabChanged(appNavigationTabs.webhookConfiguration)}
                            >
                                <FormattedMessage id="AppManagement.AppCertification.SaveAndContinue" />
                            </Button>
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
}
export default AppCertification;
