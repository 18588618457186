import React, { Fragment } from 'react';
import ValidationInput from '../../common/ValidationInput';
import { FormGroup, Button, Label } from 'reactstrap';
import useValidation from '../../../hooks/useValidation';
import ImageUpload from '../../common/ImageUpload';
import { ReactComponent as DeleteIcon } from '../../../content/icons/Icon-Delete.svg';
import PropTypes from 'prop-types';

function AppSetupSection(props) {
    const { errors } = useValidation();

    function onValueChange(key, value, uniqueIdentifier) {
        props.onValueChange(key, value, uniqueIdentifier, props.sectionType);
    }

    function onImageChange(imageList, uniqueIdentifier) {
        const image = imageList[0] ? imageList[0] : null;
        props.onImageChange(image, uniqueIdentifier, props.sectionType);
    }

    return (
        <div className="starting-guide-section-container row">
            <div className="starting-guide-section">
                <div className="col-md-3 mr-4">
                    <h6>{props.sectionTitle}</h6>
                    <p>Step title, description and image are all required. (at least 1 step)</p>
                </div>
                <div className="col-md-5">
                    {props.sectionDetailsList.map((section, index) => {
                        return (
                            <Fragment key={section.uniqueIdentifier}>
                                <FormGroup className={index > 0 ? 'mt-5' : ''}>
                                    <Label for="title">Step title</Label>
                                    <div className="step-title-input">
                                        <ValidationInput
                                            type="text"
                                            name="title"
                                            value={section.title}
                                            maxLength={25}
                                            errors={errors}
                                            onChange={(e) =>
                                                onValueChange('title', e.currentTarget.value, section.uniqueIdentifier)
                                            }
                                        />
                                        {index > 0 && (
                                            <div
                                                className="delete-button"
                                                onClick={() =>
                                                    props.removeSectionStep(section.uniqueIdentifier, props.sectionType)
                                                }
                                            >
                                                <DeleteIcon />
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="description">Step description</Label>
                                    <ValidationInput
                                        type="textarea"
                                        rows="4"
                                        name="description"
                                        value={section.description}
                                        errors={errors}
                                        onChange={(e) =>
                                            onValueChange(
                                                'description',
                                                e.currentTarget.value,
                                                section.uniqueIdentifier
                                            )
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <ImageUpload
                                        images={section.image ? [section.image] : []}
                                        onChange={(e) => onImageChange(e, section.uniqueIdentifier)}
                                    />
                                </FormGroup>
                            </Fragment>
                        );
                    })}

                    <Button className="add-more-button" onClick={() => props.insertNewSectionStep(props.sectionType)}>
                        + Add more
                    </Button>
                </div>
            </div>
        </div>
    );
}

AppSetupSection.propTypes = {
    sectionTitle: PropTypes.string.isRequired,
    onValueChange: PropTypes.func.isRequired,
    onImageChange: PropTypes.func.isRequired,
    sectionDetailsList: PropTypes.array.isRequired,
    insertNewSectionStep: PropTypes.func.isRequired,
    removeSectionStep: PropTypes.func.isRequired,
    sectionType: PropTypes.oneOf(['Setup', 'Usage']),
};

export default AppSetupSection;
