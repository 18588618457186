import * as React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormGroup, TextField, InputAdornment, IconButton, Button, Alert } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ReactComponent as ResIcon } from '../../content/images/RD_hub_logo.svg';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import config from '../../config/app';
import signInStatus from '../../enums/signInStatus';
import { useConfigurations } from '../../contexts/ConfigurationsContext';
import { useIntl, FormattedMessage } from 'react-intl';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import useTrackPage from '../../hooks/useTrackPage';
import ErrorMessage from '../../model/services/ErrorMessage';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import HubAlertManager from '../common/hubAlert/HubAlertManager';
import { HubAlertProps } from '../common/hubAlert/HubAlert';

interface LoginForm {
    username: string;
    password: string;
}

const LoginPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const intl = useIntl();
    const { from } = (location.state as LocationState) || { from: { pathname: '/' } };
    const [showPassword, setShowPassword] = useState(false);
    const [alerts, setAlerts] = useState<HubAlertProps[]>([]);
    const [showBlankAlert, setShowBlankAlert] = useState(false);
    const { canCreateAccount } = useConfigurations();
    const auth = useAuthentication();

    const loginFormSchema = Yup.object<LoginForm>().shape({
        username: Yup.string()
            .required(intl.formatMessage({ id: 'Login.LoginPage.EmailEmpty' }))
            .email(intl.formatMessage({ id: 'Login.LoginPage.EmailInvalid' })),
        password: Yup.string().required(intl.formatMessage({ id: 'Login.LoginPage.PasswordRequired' })),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<LoginForm>({ resolver: yupResolver(loginFormSchema) });

    useTrackPage('Hub Account Login page');

    const trackUserProperties = (email: string) => {
        const userProperties = { email: email };
        AnalyticsHelper.identifyUserWithProperties(userProperties);
    };

    const submitLogin = (data: LoginForm) => {
        auth.login(data.username, data.password)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    if (response.status === signInStatus.Success) {
                        navigate(from);
                    } else if (response.status === signInStatus.EmailNotVerified) {
                        navigate(config.verifyAccountPath, {
                            state: { email: response.username, password: data.password },
                        });
                    } else if (response.status === signInStatus.LockedOut) {
                        setError('root', {
                            type: 'custom',
                            message: intl.formatMessage({ id: 'Login.LoginPage.LockedOutMessage' }),
                        });
                    } else {
                        setError('root', {
                            type: 'custom',
                            message: intl.formatMessage({ id: 'Login.LoginPage.IncorrectUsernamePassword' }),
                        });
                    }
                } else {
                    setError('root', {
                        type: 'custom',
                        message: intl.formatMessage({ id: 'Login.LoginPage.IncorrectUsernamePassword' }),
                    });
                    trackUserProperties(data.username);
                }
            })
            .catch((err: ErrorMessage) => {
                setAlerts((e) => [...e, { type: 'error', message: err.errorMessage }]);
            });
    };

    const navigateToForgotPasswordPage = () => {
        AnalyticsHelper.trackClickWithProperties('Web AccountLogin Forgotpassword');
        navigate(config.forgotPasswordPath);
    };

    const navigateToCreateAccountPage = () => {
        AnalyticsHelper.trackClick('Web AccountLogin AcctCreate');
        navigate(config.createAccountPath);
    };

    const handleMouseDownPassword = (e: React.MouseEvent) => {
        e.preventDefault();
    };

    const renderLoginForm = () => {
        return (
            <form onSubmit={handleSubmit(submitLogin)} className="login-form">
                <div className="account-form__title">
                    <FormattedMessage id="Login.LoginPage.LogInToYourAccount" />
                </div>
                {renderError()}
                <FormGroup className="login-form-group">
                    <TextField
                        type="text"
                        label={intl.formatMessage({ id: 'Common.EmailAddress' })}
                        placeholder={intl.formatMessage({ id: 'Login.LoginPage.EmailPlaceholder' })}
                        className="username"
                        {...register('username', {
                            onChange: () => {
                                if (showBlankAlert) setShowBlankAlert(false);
                            },
                        })}
                        variant="outlined"
                        error={!!errors.username || !!errors.root?.message}
                        helperText={errors.username?.message}
                        data-testid="username"
                    />
                    <TextField
                        type={showPassword ? 'text' : 'password'}
                        label={intl.formatMessage({ id: 'Common.Password' })}
                        placeholder={intl.formatMessage({ id: 'Common.Password' })}
                        {...register('password', {
                            onChange: () => {
                                if (showBlankAlert) setShowBlankAlert(false);
                            },
                        })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        className="password"
                        error={!!errors.password || !!errors.root?.message}
                        helperText={errors.password?.message}
                        data-testid="password"
                    />

                    <Button type="submit" variant="contained" className="submit" data-testid="login-form-submit-button">
                        <FormattedMessage id="Common.LogIn" />
                    </Button>
                </FormGroup>

                {canCreateAccount && (
                    <FormGroup>
                        <div className="d-flex align-items-center justify-content-center">
                            <FormattedMessage id="Login.LoginPage.DontHaveAccount" />
                            <button
                                type="button"
                                className="btn btn-link btn-link--hub pl-1"
                                onClick={() => navigateToCreateAccountPage()}
                            >
                                <FormattedMessage id="Login.LoginPage.CreateAccount" />
                            </button>
                        </div>
                    </FormGroup>
                )}
                <FormGroup>
                    <div className="d-flex align-items-center justify-content-center">
                        <button
                            type="button"
                            className="btn btn-link btn-link--hub"
                            onClick={() => {
                                navigateToForgotPasswordPage();
                            }}
                        >
                            <FormattedMessage id="Login.LoginPage.ForgotPassword" />
                        </button>
                    </div>
                </FormGroup>
            </form>
        );
    };

    const renderError = (): JSX.Element | undefined => {
        if (!!errors.root?.message) {
            return (
                <Alert variant="filled" severity="error" className="incorrect-details-error">
                    {errors.root?.message}
                </Alert>
            );
        }
    };

    return (
        <div className="login-page">
            <div className="logo">
                <ResIcon />
                <span className="beta">BETA</span>
            </div>
            <div className="content-card">{renderLoginForm()}</div>
            <HubAlertManager alerts={alerts} />
        </div>
    );
};

interface LocationState {
    from: From;
}
interface From {
    pathname: string;
}

export default LoginPage;
