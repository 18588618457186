import * as React from 'react';
import { useState, useEffect } from 'react';
import AppService from '../../services/AppService';
import { useOrganisation } from '../../contexts/OrganisationContext';
import { ReactComponent as AppIcon } from '../../content/icons/Icon-Apps.svg';
import AppManagementCard from './AppManagementCard';
import LoadingSpinner from '../common/LoadingSpinner';
import CreateAppModal from './CreateAppModal';
import { FormattedMessage, useIntl } from 'react-intl';
import App from '../../model/appManagement/App';
import { ReactComponent as NoAppsIcon } from '../../content/icons/Icon-NoApps.svg';
import ErrorMessage from '../../model/services/ErrorMessage';
import Paginator from '../common/Paginator';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useNotification } from '../../contexts/NotificationProvider';

const pageSize = 9;

const AppManagementPage = () => {
    const [applications, setApplications] = useState<App[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const intl = useIntl();
    const { displayNotification } = useNotification();

    const { defaultOrganisation } = useOrganisation();

    useEffect(() => {
        setIsLoading(true);
        AppService.getApplications(defaultOrganisation!.id)
            .then((response) => {
                // Sort into decending order
                response.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
                setApplications(response);
            })
            .finally(() => {
                setIsLoading(false);
            })
            .catch((error: ErrorMessage) => {
                // TODO: show error toast when error is thrown
                displayNotification({
                    message: error.errorMessage,
                });
                setIsLoading(false);
            });
    }, [defaultOrganisation, displayNotification]);

    const showCreateModal = () => {
        setIsCreateModalOpen(true);
    };

    const handlePaginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    let renderEmptyAppList = () => {
        return (
            <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                    <Stack
                        sx={{
                            maxWidth: '450px',
                        }}
                        component={Paper}
                        elevation={0}
                        alignItems="center"
                        spacing={2}
                        p={4}
                    >
                        <NoAppsIcon className="logo" />
                        <Typography align="center" variant="h5">
                            <b>
                                <FormattedMessage id="AppManagement.AppManagementPage.NoAppsTitle" />
                            </b>
                        </Typography>
                        <Typography align="center" variant="subtitle1">
                            <FormattedMessage id="AppManagement.AppManagementPage.NoAppsDescription" />
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        );
    };

    return (
        <React.Fragment>
            <Grid container spacing={5} direction="column" className="app-management-page">
                <Grid item component={Paper} square elevation={0}>
                    <Container maxWidth="md">
                        <Grid container justifyContent="space-between" alignItems="flex-end" p={2} spacing={2}>
                            <Grid item>
                                <Stack alignItems="center" direction="row" spacing={2}>
                                    <AppIcon className="logo" />
                                    <Typography variant="h4">
                                        <FormattedMessage id="AppManagement.AppManagementPage.AppsTitle" />
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={showCreateModal}>
                                    <AddIcon />
                                    <FormattedMessage id="AppManagement.AppManagementPage.CreateNewApp" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                <Grid item>
                    <Container maxWidth="md">
                        {isLoading ? (
                            <LoadingSpinner
                                loadingText={intl.formatMessage({ id: 'AppManagement.AppManagementPage.LoadingText' })}
                            />
                        ) : (
                            <React.Fragment>
                                <Grid container spacing={2}>
                                    {applications
                                        .slice(currentPage * pageSize - pageSize, currentPage * pageSize)
                                        .map((app, index) => {
                                            return (
                                                <Grid key={index} item xs={4}>
                                                    <AppManagementCard app={app} />
                                                </Grid>
                                            );
                                        })}
                                </Grid>
                                {applications.length > pageSize && (
                                    <Stack p={5} alignItems="center">
                                        <Paginator
                                            pages={Math.ceil(applications.length / pageSize)}
                                            handlePaginate={handlePaginate}
                                        />
                                    </Stack>
                                )}
                                {applications.length === 0 && renderEmptyAppList()}
                            </React.Fragment>
                        )}
                    </Container>
                </Grid>
            </Grid>
            <CreateAppModal isOpen={isCreateModalOpen} setIsOpen={setIsCreateModalOpen} />
        </React.Fragment>
    );
};

export default AppManagementPage;
