import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../common/Tag';
import tagColour from '../../enums/tagColour';
import appSubmissionStatus from '../../enums/appSubmissionStatus';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import { useIntl } from 'react-intl';

function AppSubmissionStatusTag({ submissionStatus }) {
    const { authInfo } = useAuthentication();
    const intl = useIntl();
    const submissionRejected = intl.formatMessage({ id: 'AppManagement.AppSubmissionStatus.Submission' }) + ' ';
    intl.formatMessage({ id: 'AppManagement.AppSubmissionStatus.Rejected' });

    switch (submissionStatus) {
        case appSubmissionStatus.submitted:
            return (
                <Tag
                    value={intl.formatMessage({ id: 'AppManagement.AppSubmissionStatus.Submitted' })}
                    color={authInfo.isAdmin ? tagColour.Navy : tagColour.LightGreen}
                />
            );
        case appSubmissionStatus.rejected:
            return (
                <Tag
                    value={
                        authInfo.isAdmin
                            ? intl.formatMessage({ id: 'AppManagement.AppSubmissionStatus.Rejected' })
                            : submissionRejected
                    }
                    color={tagColour.Orange}
                />
            );
        case appSubmissionStatus.listingRejected:
            return (
                <Tag
                    value={intl.formatMessage({ id: 'AppManagement.AppSubmissionStatus.ListingRejected' })}
                    color={tagColour.Orange}
                />
            );
        case appSubmissionStatus.approved:
            return (
                <Tag
                    value={intl.formatMessage({ id: 'AppManagement.AppSubmissionStatus.Approved' })}
                    color={tagColour.Green}
                />
            );
        case appSubmissionStatus.listingSubmitted:
            return (
                <Tag
                    value={intl.formatMessage({ id: 'AppManagement.AppSubmissionStatus.ListingSubmitted' })}
                    color={authInfo.isAdmin ? tagColour.Navy : tagColour.LightGreen}
                />
            );
        case appSubmissionStatus.listingApproved:
            return (
                <Tag
                    value={intl.formatMessage({ id: 'AppManagement.AppSubmissionStatus.ListingApproved' })}
                    color={tagColour.Green}
                />
            );
        case appSubmissionStatus.actionsRequired:
            return (
                <Tag
                    value={intl.formatMessage({ id: 'AppManagement.AppSubmissionStatus.ActionRequired' })}
                    color={tagColour.Blue}
                />
            );
        case appSubmissionStatus.accountClosed:
            return (
                <Tag
                    value={intl.formatMessage({ id: 'AppManagement.AppSubmissionStatus.AccountClosed' })}
                    color={tagColour.Disabled}
                />
            );
        case appSubmissionStatus.notSubmitted:
        default:
            return (
                <Tag
                    value={intl.formatMessage({ id: 'AppManagement.AppSubmissionStatus.NotSubmitted' })}
                    color={tagColour.Grey}
                />
            );
    }
}

AppSubmissionStatusTag.propTypes = {
    submissionStatus: PropTypes.string.isRequired,
};

export default AppSubmissionStatusTag;
