import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalBody, ModalHeader, Popover, PopoverBody } from 'reactstrap';
import LoadingSpinner from '../../../common/LoadingSpinner';

const ClientSecretModal = (props: ClientSecretModalProps) => {
    const [isCopyTooltipOpen, setIsCopyTooltipOpen] = useState(false);

    const closeModal = () => {
        props.onClose();
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(props.clientSecret);
    };

    return (
        <Modal
            centered
            isOpen={props.isOpen}
            size="md"
            className="client-secret-modal"
            toggle={() => {
                closeModal();
            }}
        >
            {props.isLoading && <LoadingSpinner />}
            {!props.isLoading && (
                <React.Fragment>
                    <ModalHeader toggle={() => closeModal()}>
                        <FormattedMessage id="AppManagement.AppDetails.NewSecretGenerated" />
                    </ModalHeader>
                    <ModalBody>
                        <div className="client-secret-warning">
                            <FormattedMessage id="AppManagement.AppDetails.NewSecretWarning" />
                        </div>
                        <div className="client-secret-string">{props.clientSecret}</div>
                        <div className="d-flex justify-content-end">
                            <Button
                                id="copy-client-secret-btn"
                                onClick={() => copyToClipboard()}
                                onMouseLeave={() => setIsCopyTooltipOpen(false)}
                            >
                                <FormattedMessage id="AppManagement.AppDetails.CopyClientSecret" />
                            </Button>
                            <Popover
                                placement="top"
                                isOpen={isCopyTooltipOpen}
                                target="copy-client-secret-btn"
                                toggle={() => setIsCopyTooltipOpen(true)}
                            >
                                <PopoverBody>Copied</PopoverBody>
                            </Popover>
                        </div>
                    </ModalBody>
                </React.Fragment>
            )}
        </Modal>
    );
};

export interface ClientSecretModalProps {
    isOpen: boolean;
    clientSecret: string;
    onClose(): void;
    isLoading: boolean;
}

export default ClientSecretModal;
