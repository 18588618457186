import { Route, Routes } from 'react-router-dom';
import { default as TestAppCallback } from './TestAppCallback';
import { default as TestAppInstallPage } from './TestAppInstallPage';

const TestClientApplicationRoutes = () => (
    <Routes>
        <Route path={'/callback'} element={<TestAppCallback />} />
        <Route path={'/app-install'} element={<TestAppInstallPage />} />
    </Routes>
);

export default TestClientApplicationRoutes;
