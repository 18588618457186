export const displayRoleName = (roleName: string) => {
    if (roleName === 'Owner' || roleName === 'Admin' || roleName === 'Member') {
        return roleName;
    }
    return roleName.substring(roleName.indexOf('_') + 1);
};

export const systemRoleName = (organisationId: number, roleName: string) => {
    if (roleName === 'Owner' || roleName === 'Admin' || roleName === 'Member') {
        return roleName;
    }
    return `${organisationId}_${roleName}`;
};
