/* eslint-disable */
import { api, apiBase } from '../helpers/ApiHelper';
import ApiRequestLogEntry from '../model/apiLogs/ApiRequestLogEntry';
import ApiRequestLogEntryDetails from '../model/apiLogs/ApiRequestLogEntryDetails';
import GetApiLogEntryDetailRequest from '../model/apiLogs/GetApiLogEntryDetailsRequest';
import ErrorMessage from '../model/services/ErrorMessage';
import PagedResult from '../model/utility/PagedResult';

const baseUrl = 'ApiRequestLogEntries';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const findApiRequestLogEntries = async (
    appId: number,
    organisationId: number,
    responseStatus: string,
    environment: string
) => {
    let params = {
        responseStatus: responseStatus,
        environment: environment,
        appId: appId,
        organisationId: organisationId,
    };

    return await api.get<PagedResult<ApiRequestLogEntry>, string>(baseUrl, params).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const loadNextPage = async (nextPageUrl: string) => {
    return await apiBase.get<PagedResult<ApiRequestLogEntry>, string>(nextPageUrl).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const getApiRequestLogEntryDetails = async ({
    id,
    environment,
    organisationId,
    appId,
}: GetApiLogEntryDetailRequest) => {
    let url = `${baseUrl}/${id}?environment=${environment}&organisationId=${organisationId}&appId=${appId}`;
    return await api.get<ApiRequestLogEntryDetails, string>(url).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

export default {
    findApiRequestLogEntries,
    loadNextPage,
    getApiRequestLogEntryDetails,
};
