import React from 'react';
import CheckBoxGroup from '../common/CheckBoxGroup';
import useValidation from './../../hooks/useValidation';
function AppCategories(props) {
    const { errors, register } = useValidation();

    const getAppCategoryOptions = () => {
        return [
            new Option(props.appCategoryEnum.analytics, props.appCategoryEnum.analytics),
            new Option(props.appCategoryEnum.automation, props.appCategoryEnum.automation),
            new Option(props.appCategoryEnum.crm, props.appCategoryEnum.crm),
            new Option(props.appCategoryEnum.epos, props.appCategoryEnum.epos),
            new Option('Lead capture and management', props.appCategoryEnum.leadCaptureAndManagement),
            new Option('Marketing automation', props.appCategoryEnum.marketingAutomation),
            new Option(props.appCategoryEnum.scheduling, props.appCategoryEnum.scheduling),
            new Option('Survey and feedback', props.appCategoryEnum.surveyAndFeedback),
        ];
    };

    const categoryCheckboxOnChange = (option) => {
        let categoryArrayCopy = props.categoryArray ?? [];

        if (!option.checked) {
            categoryArrayCopy = categoryArrayCopy.filter((value) => value !== option.id);
        } else {
            if (!categoryArrayCopy.includes(option.id)) {
                // Removing first entry from the selected category array to only allow up to 2 selected categories
                while (categoryArrayCopy.length >= 2) {
                    categoryArrayCopy.shift();
                }
                categoryArrayCopy.push(option.id);
            }
        }
        let newCategoryArray = [...categoryArrayCopy];
        props.setCategoryArray(newCategoryArray);
    };

    const validateCategories = (value) => {
        if ((value ?? []).length > 0) {
            return true;
        }
        return 'Select at least 1 category';
    };

    return (
        <div className="custom-control-cursor-pointer">
            <CheckBoxGroup
                name="categories"
                disabled={props.isFormDisabled}
                options={props.categoriesArray || getAppCategoryOptions()}
                onChange={categoryCheckboxOnChange}
                checkedValues={props.categoryArray}
                errors={errors}
                innerRef={register({
                    validate: validateCategories,
                })}
                ariaRequired
            />
        </div>
    );
}

export default AppCategories;
