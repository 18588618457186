import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { create } from 'apisauce';
import { Container, Form, Input, FormGroup, Label } from 'reactstrap';

function TestAppCallback(props) {
    const [token, setToken] = useState();
    // Grab the authorization code from the query string parameters.
    const baseUrl = window.location.origin;
    const search = useLocation().search;
    const code = new URLSearchParams(search).get('code');
    // const [restaurant, setRestaurant] = useState();

    useEffect(() => {
        // Grab the code verifier from the session storage.
        const codeVerifier = sessionStorage.getItem('app-install-code-verifier');
        // Grab the redirect uri from the session storage.
        const redirectUri = sessionStorage.getItem('app-install-redirect-url');
        // Get the clientId and client secret. (The client app should have this information when they setup the app in the developer portal)
        const clientId = sessionStorage.getItem('client-id');
        const clientSecret = sessionStorage.getItem('client-secret');
        const authBaseUrl = sessionStorage.getItem('auth-base-url');
        const tokenEndpoint = `${authBaseUrl}/oauth/token`;

        const params = new URLSearchParams();
        params.append('client_id', clientId);
        params.append('client_secret', clientSecret);
        params.append('code', code);
        params.append('grant_type', 'authorization_code');
        params.append('redirect_uri', `${baseUrl}/testapp/callback`);
        params.append('code_verifier', codeVerifier);

        let api = create({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        const getAccessToken = () => {
            return api.post(tokenEndpoint, params).then((result) => {
                setToken(result.data);
                return result;
            });
        };

        const redirectToDiary = (result) => {
            // Removing code verifier, redirect url, client id, and client secret that was stored in session storage.
            sessionStorage.removeItem('app-install-code-verifier');
            sessionStorage.removeItem('app-install-redirect-url');
            sessionStorage.removeItem('client-id');
            sessionStorage.removeItem('client-secret');
            sessionStorage.removeItem('auth-base-url');

            let uri = `${redirectUri}?install_success=${result.ok}`;
            if (result.data.error_description) {
                uri = `${uri}&error_message=${result.data.error_description}`;
            }

            setTimeout(() => {
                window.location.replace(uri);
            }, 5000);
        };

        getAccessToken()
            // .then(getRestaurantDetails)
            .then(redirectToDiary);
    }, [search, code, baseUrl]);

    // const getRestaurantDetails = (result) => {
    //     if (result.ok) {
    //         let resDiaryApi = create({
    //             headers: {
    //                 Authorization: `Bearer ${result.data.access_token}`,
    //             },
    //             accesscontrolalloworigin: '*',
    //             accesscontrolallowMethods: 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
    //         });

    //         return resDiaryApi
    //             .get(`https://localhost:44302/api/ConsumerApi/v1/Restaurant/DustinTestaurant`)
    //             .then((apiresult) => {
    //                 setRestaurant(apiresult.data);
    //                 return result;
    //             });
    //     }
    //     return result;
    // };

    return (
        <Container className="account-page">
            <div className="flex-container-center">
                <Form className="account-form">
                    Test Application Callback
                    <FormGroup className="mb-0">
                        <div className="d-flex">
                            <Label>Authorization Code</Label>
                        </div>
                        <Input type="text" value={code} />
                    </FormGroup>
                    {!token && <p> Exchanging authorizatin code for token... </p>}
                    {token && (
                        <>
                            <FormGroup className="mb-0">
                                <div className="d-flex">
                                    <Label>Token type</Label>
                                </div>
                                <Input type="text" value={token.token_type} />
                            </FormGroup>
                            <FormGroup className="mb-0">
                                <div className="d-flex">
                                    <Label>Access token</Label>
                                </div>
                                <Input type="text" value={token.access_token} />
                            </FormGroup>
                            <FormGroup className="mb-0">
                                <div className="d-flex">
                                    <Label>Refresh token</Label>
                                </div>
                                <Input type="text" value={token.refresh_token} />
                            </FormGroup>
                            <FormGroup className="mb-0">
                                <div className="d-flex">
                                    <Label>Scope</Label>
                                </div>
                                <Input type="text" value={token.scope} />
                            </FormGroup>
                            <FormGroup className="mb-0">
                                <div className="d-flex">
                                    <Label>Expires in (seconds)</Label>
                                </div>
                                <Input type="text" value={token.expires_in} />
                            </FormGroup>
                            <p>Redirecting back to the diary in 5 seconds....</p>
                        </>
                    )}
                </Form>
            </div>
        </Container>
    );
}

export default TestAppCallback;
