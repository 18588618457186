import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import CheckBox from '../common/CheckBox';
import { ReactComponent as ChevronUp } from '../../content/icons/Chevron.svg';
import { ReactComponent as ChevronDown } from '../../content/icons/chevron-down.svg';

function CertificationCollapsible({ cSection, isChecklistDisabled, onChecklistChange, onToggleCollapse }) {
    const getAnsweredScore = () => {
        let answeredScore = 0;
        cSection.checklistQuestions.forEach((q) => {
            answeredScore += q.answeredCheckboxes.filter((c) => !c.isNull).length;
        });
        const totalCheckBoxes =
            cSection.checklistAnswerItems.filter((c) => !c.isNull).length * cSection.checklistQuestions.length;
        const addMargin = cSection.checklistQuestions.length > 9 ? 'ml-0' : 'ml-2';
        return <span className={addMargin}> {`${answeredScore}/${totalCheckBoxes}  done`} </span>;
    };

    const handleCheckboxChange = (checkbox, x) => {
        x.isChecked = setIsChecked(x, checkbox.checklistAnswerId);
        onChecklistChange(x, checkbox.checklistAnswerId, cSection.index);
    };

    const setIsChecked = (x, checklistAnswerId) => {
        const checked = x.answeredCheckboxes.find(
            (ans) => ans.checklistAnswerId === checklistAnswerId && ans.checklistQuestionId === x.checklistQuestionId
        );
        return typeof checked === 'undefined' ? false : checked.isChecked;
    };

    const formatText = (text) => {
        if (text.indexOf('¬') !== 0 && text.indexOf('^') !== 0) {
            return <div> {text} </div>;
        } else {
            const list = text.split('¬');
            return (
                <div>
                    {list.map((listItem, index) => {
                        const title = getEnclosedText(listItem, '^');
                        const subList = listItem
                            .substring(listItem.indexOf('^', listItem.indexOf('^') + 1) + 1, listItem.length)
                            .split('|');
                        return (
                            <div key={index}>
                                <b> {title} </b>
                                <ul>
                                    {subList.map((subListItem, i) => {
                                        if (subListItem.indexOf('~') >= 0) {
                                            return <span key={i}>{getLinkedText(subListItem, i)}</span>;
                                        } else {
                                            return <li key={i}>{subListItem}</li>;
                                        }
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </div>
            );
        }
    };

    function getEnclosedText(textStr, char) {
        return textStr.substring(textStr.indexOf(char) + 1, textStr.indexOf(char, textStr.indexOf(char) + 1));
    }

    function getLinkedText(text, i) {
        const rawLinkText = getEnclosedText(text, '~');
        const linkText = getEnclosedText(rawLinkText, '[');
        return (
            <li key={i}>
                <a href={linkText}>{rawLinkText.substring(0, rawLinkText.indexOf('['))}</a>{' '}
                {text.substring(text.indexOf('~', text.indexOf('~') + 1) + 1, text.length)}
            </li>
        );
    }

    const getColumns = (row) => {
        const columns = [
            {
                isCheckbox: false,
                displayFormat: (x) => {
                    return formatText(x.questionText);
                },
            },
            {
                isCheckbox: false,
                selector: (x) => (x.isRequired ? 'Required' : 'Optional'),
            },
        ];
        cSection.checklistAnswerItems.forEach((checkbox) => {
            if (!checkbox.isNull) {
                columns.push({
                    isCheckbox: true,
                    displayFormat: (x) => {
                        return (
                            <CheckBox
                                name={x.questionText}
                                id={checkbox.checklistAnswerId.toString() + '_' + x.checklistQuestionId.toString()}
                                key={`${checkbox.checklistAnswerId}_${x.checklistQuestionId}`}
                                option={checkbox.option}
                                onChange={() => {
                                    handleCheckboxChange(checkbox, x);
                                }}
                                isChecked={setIsChecked(x, checkbox.checklistAnswerId)}
                                disabled={isChecklistDisabled}
                                ariaRequired={x.isRequired}
                                ariaLabel={checkbox.option.text}
                            />
                        );
                    },
                });
            } else {
                columns.push({
                    isCheckbox: true,
                    displayFormat: (x) => {
                        return <div>N/A</div>;
                    },
                });
            }
        });
        return columns;
    };

    const collapseChecklist = () => {
        return (
            <Fragment>
                <div className="collapsible">
                    <div className="col-12">
                        <div className="row header" onClick={() => onToggleCollapse(cSection.index, !cSection.isOpen)}>
                            <div className="col-8">{cSection.sectionText}</div>
                            <div className="col-4">
                                <div className="float-right">
                                    {getAnsweredScore()}
                                    <span className="ml-4">{cSection.isOpen ? <ChevronUp /> : <ChevronDown />}</span>
                                </div>
                            </div>
                        </div>
                        <Collapse isOpen={cSection.isOpen}>
                            {cSection.checklistQuestions.length > 0 &&
                                cSection.checklistQuestions.map((row, rowIndex) => {
                                    return (
                                        <Fragment key={rowIndex}>
                                            <div className="row collapsible-content-row">
                                                <div className="col-12">
                                                    <div className="row">
                                                        {getColumns(row).map((col, colIndex) => {
                                                            const checkboxClass = col.isCheckbox
                                                                ? 'mr-4 float-right'
                                                                : '';
                                                            return (
                                                                <div
                                                                    className={
                                                                        colIndex === 0 ? 'col-6' : 'col content-section'
                                                                    }
                                                                    key={colIndex}
                                                                >
                                                                    <span className={checkboxClass}>
                                                                        {col.displayFormat
                                                                            ? col.displayFormat(row, rowIndex)
                                                                            : col.selector(row, rowIndex)}
                                                                    </span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    );
                                })}
                        </Collapse>
                    </div>
                </div>
            </Fragment>
        );
    };

    return <Fragment>{collapseChecklist()}</Fragment>;
}
CertificationCollapsible.propTypes = {
    cSection: PropTypes.object,
    isChecklistDisabled: PropTypes.bool,
    onChecklistChange: PropTypes.func,
    onToggleCollapse: PropTypes.func,
};
export default CertificationCollapsible;
