import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { Button, Label, Form, FormGroup, Col } from 'reactstrap';
import useValidation from '../../hooks/useValidation';
import CheckBoxGroup from '../common/CheckBoxGroup';
import ValidationInput from '../common/ValidationInput';
import AppService from '../../services/AppService';
import appSubmissionStatus from '../../enums/appSubmissionStatus';
import appCategoryEnum from '../../enums/appCategories';
import appNavigationTabs from '../../enums/appNavigationTabs';
import AppImageContainer from './AppImageContainer';
import ValidationMessage from '../common/ValidationMessage';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import { FormattedMessage, useIntl } from 'react-intl';

const BasicInformationPage = (props) => {
    const intl = useIntl();
    const { authInfo } = useAuthentication();
    props.application.builtBy =
        props.application.builtBy !== null ? props.application.builtBy : props.application.organisationName;
    const [basicInformation, setBasicInformation] = useState(props.application);
    const [isFormDisabled, setIsFormDisabled] = useState(
        props.application.submissionStatus !== appSubmissionStatus.notSubmitted
    );
    const { register, errors, errorCount, submit, setValue, getValues } = useValidation();
    const [appImage, setAppImage] = useState(null);
    const [isInitiating, setIsInitiating] = useState(true);

    const [isEditing, setIsEditing] = useState(false);

    const toggleEditing = () => {
        setIsEditing(!isEditing);
        setIsFormDisabled(!isFormDisabled);
    };

    const validateLogo = useCallback(
        (logo) => {
            const url = getValues('logoUrl');
            if (!logo && !url) {
                return intl.formatMessage({ id: 'AppManagement.AppBasicInformationPage.LogoValidationMessage' });
            }
            return true;
        },
        [getValues, intl]
    );

    useEffect(() => {
        if (isInitiating) {
            register({ name: 'logo' }, { validate: validateLogo });
            setValue('logo', appImage);
            setIsInitiating(false);
        }
    }, [register, setValue, validateLogo, isInitiating, appImage]);

    const getAppCategoryOptions = () => {
        return [
            new Option(appCategoryEnum.analytics, appCategoryEnum.analytics),
            new Option(appCategoryEnum.automation, appCategoryEnum.automation),
            new Option(appCategoryEnum.crm, appCategoryEnum.crm),
            new Option(appCategoryEnum.epos, appCategoryEnum.epos),
            new Option('Lead capture and management', appCategoryEnum.leadCaptureAndManagement),
            new Option('Marketing automation', appCategoryEnum.marketingAutomation),
            new Option(appCategoryEnum.scheduling, appCategoryEnum.scheduling),
            //   new Option('Survey and feedback', appCategoryEnum.surveyAndFeedback), // is this no longer required?
        ];
    };

    const appCategoryCheckboxOnChange = (option) => {
        let categoryArray = basicInformation.categories;

        if (!option.checked) {
            categoryArray = categoryArray.filter((value) => value !== option.id);
        } else {
            if (!categoryArray.includes(option.id)) {
                // Removing first entry from the selected category array to only allow up to 2 selected categories
                while (categoryArray.length >= 2) {
                    categoryArray.shift();
                }
                categoryArray.push(option.id);
            }
        }

        let newBasicInformation = {
            ...basicInformation,
            categories: categoryArray,
        };

        setBasicInformation(newBasicInformation);
    };

    const setBasicInformationFieldValue = (fieldName, value) => {
        let newBasicInformation = {
            ...basicInformation,
            [fieldName]: value,
        };
        setBasicInformation(newBasicInformation);
    };

    const configureFormData = (basicInformation, appImage) => {
        let data = new FormData();
        data.append('basicInformation', JSON.stringify(basicInformation));
        data.append('image', appImage?.file);
        return data;
    };

    const submitBasicInformation = () => {
        const data = configureFormData(basicInformation, appImage);
        props.setImageUpdated(true);
        props.setLogoUrl(null);
        AppService.updateApplicationBasicInfo(props.application.id, data).then((response) => {
            if (!('errorMessage' in response)) {
                props.onChange(basicInformation);
                props.setImageUpdated(false);
                props.onTabChanged(appNavigationTabs.authentication);
            }
        });
    };

    const validateCategories = (value) => {
        if ((value ?? []).length > 0) {
            return true;
        }

        return intl.formatMessage({ id: 'AppManagement.AppBasicInformationPage.CategoriesValidateText' });
    };

    function onAppImageChange(imageList) {
        let imageToUpload = imageList[0];
        setAppImage(imageToUpload);
        setValue('logo', imageToUpload, { shouldValidate: true, shouldDirty: true });
    }

    function getSaveButton() {
        if (!authInfo.isAdmin || isEditing) {
            return (
                <Button type="submit" color="primary" className="mr-2">
                    <FormattedMessage id="Common.SaveAndContinue" />
                </Button>
            );
        }
    }

    return (
        <Fragment>
            <h1 className="mb-3">
                <FormattedMessage id="AppManagement.AppBasicInformationPage.PageTitle" />
            </h1>
            <p className="mb-3">
                <FormattedMessage id="AppManagement.AppBasicInformationPage.PageDescription" />
            </p>
            <Form onSubmit={submit(submitBasicInformation)}>
                <fieldset disabled={!isEditing && authInfo.isAdmin}>
                    <FormGroup row>
                        <Label sm={12} className="pt-0 pb-0">
                            <h6>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.AppIcon" />
                            </h6>
                        </Label>
                        <Label sm={4} className="pt-0 pb-0">
                            <span>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.DimensionText" />
                            </span>
                            <br />
                            <span>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.FileSizeText" />
                            </span>
                            <br />
                            <span>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.FileFormatText" />
                            </span>
                        </Label>
                        <Col sm={6}>
                            <Fragment>
                                <AppImageContainer
                                    appImage={appImage}
                                    onChange={onAppImageChange}
                                    requireUpload
                                    imageUpdated={props.imageUpdated}
                                    application={props.application}
                                />

                                <div className="validation-summary">
                                    {errorCount > 0 && errors['logo'] && (
                                        <ValidationMessage message={errors['logo'].message} />
                                    )}
                                </div>
                            </Fragment>
                        </Col>
                    </FormGroup>
                    <br />
                    <FormGroup row>
                        <Label sm={12} className="pt-0 pb-0">
                            <h6>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.AppName" />
                            </h6>
                        </Label>
                        <Label sm={4}>
                            <span>
                                {' '}
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.AppNameDescription" />
                            </span>
                        </Label>
                        <Col sm={6}>
                            <ValidationInput
                                disabled={isFormDisabled}
                                testId="basicInformationAppName"
                                type="text"
                                name="title"
                                value={basicInformation.title}
                                onChange={(e) => setBasicInformationFieldValue('title', e.currentTarget.value)}
                                innerRef={register({
                                    required: intl.formatMessage({
                                        id: 'AppManagement.AppBasicInformationPage.AppNameInnerRef',
                                    }),
                                })}
                                maxLength={25}
                                errors={errors}
                                displayCharacterCount={true}
                            />
                        </Col>
                    </FormGroup>
                    <br />
                    <FormGroup row>
                        <Label sm={12} className="pt-0 pb-0">
                            <h6>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.Description" />
                            </h6>
                        </Label>
                        <Label for="appDescription" sm={4}>
                            <span>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.DescriptionText" />
                            </span>
                        </Label>
                        <Col sm={6}>
                            <ValidationInput
                                disabled={isFormDisabled}
                                testId="basicInformationAppDescription"
                                type="textarea"
                                name="description"
                                placeholder={intl.formatMessage({
                                    id: 'AppManagement.AppBasicInformationPage.DescriptionPlaceholder',
                                })}
                                value={basicInformation.description}
                                onChange={(e) => setBasicInformationFieldValue('description', e.currentTarget.value)}
                                innerRef={register({
                                    required: intl.formatMessage({
                                        id: 'AppManagement.AppBasicInformationPage.DescriptionInnerRef',
                                    }),
                                })}
                                maxLength={70}
                                errors={errors}
                                displayCharacterCount={true}
                            />
                        </Col>
                    </FormGroup>
                    <br />
                    <FormGroup row>
                        <Label sm={12} className="pt-0 pb-0">
                            <h6>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.BuiltBy" />
                            </h6>
                        </Label>
                        <Label for="appBuiltBy" sm={4}>
                            <span>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.BuiltByDescription" />
                            </span>
                        </Label>
                        <Col sm={6}>
                            <ValidationInput
                                disabled={isFormDisabled}
                                testId="basicInformationAppBuiltBy"
                                type="text"
                                name="builtBy"
                                value={basicInformation.builtBy}
                                onChange={(e) => setBasicInformationFieldValue('builtBy', e.currentTarget.value)}
                                innerRef={register({
                                    required: intl.formatMessage({
                                        id: 'AppManagement.AppBasicInformationPage.BuiltByInnerRef',
                                    }),
                                })}
                                errors={errors}
                            />
                        </Col>
                    </FormGroup>
                    <br />
                    <FormGroup row>
                        <Label sm={12} className="pt-0 pb-0">
                            <h6>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.TermsOfServiceUrl" />
                            </h6>
                        </Label>
                        <Label for="appTermsOfService" sm={4}>
                            <span>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.TermsOfServiceUrlDescription" />
                            </span>
                        </Label>
                        <Col sm={6}>
                            <ValidationInput
                                disabled={isFormDisabled}
                                testId="basicInformationAppTermsOfServiceURL"
                                type="url"
                                name="termsOfServiceUrl"
                                placeholder={intl.formatMessage({
                                    id: 'AppManagement.AppBasicInformationPage.TermsOfServicePlaceholder',
                                })}
                                value={basicInformation.termsOfServiceUrl}
                                onChange={(e) =>
                                    setBasicInformationFieldValue('termsOfServiceUrl', e.currentTarget.value)
                                }
                                innerRef={register({
                                    required: intl.formatMessage({
                                        id: 'AppManagement.AppBasicInformationPage.TermsOfServiceRequired',
                                    }),
                                    pattern: {
                                        value: /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/gi, // eslint-disable-line no-control-regex
                                        message: intl.formatMessage({
                                            id: 'AppManagement.AppBasicInformationPage.TermsOfServiceInnerRef',
                                        }),
                                    },
                                })}
                                errors={errors}
                            />
                        </Col>
                    </FormGroup>
                    <br />
                    <FormGroup row>
                        <Label sm={12} className="pt-0 pb-0">
                            <h6>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.PrivacyNoticeUrl" />
                            </h6>
                        </Label>
                        <Label for="appProvacyPolicyURL" sm={4}>
                            <span>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.PrivacyNoticeUrlDescription" />
                            </span>
                        </Label>
                        <Col sm={6}>
                            <ValidationInput
                                disabled={isFormDisabled}
                                testId="basicInformationAppPrivacyPolicyURL"
                                type="url"
                                name="privacyPolicyUrl"
                                placeholder={intl.formatMessage({
                                    id: 'AppManagement.AppBasicInformationPage.PrivacyPolicyPlaceholder',
                                })}
                                value={basicInformation.privacyPolicyUrl}
                                onChange={(e) =>
                                    setBasicInformationFieldValue('privacyPolicyUrl', e.currentTarget.value)
                                }
                                innerRef={register({
                                    required: intl.formatMessage({
                                        id: 'AppManagement.AppBasicInformationPage.PrivacyNoticeRequired',
                                    }),
                                    pattern: {
                                        value: /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/gi, // eslint-disable-line no-control-regex
                                        message: intl.formatMessage({
                                            id: 'AppManagement.AppBasicInformationPage.PrivacyPolicyInnerRef',
                                        }),
                                    },
                                })}
                                errors={errors}
                            />
                        </Col>
                    </FormGroup>
                    <br />
                    <FormGroup row>
                        <Label sm={12} className="pt-0 pb-0">
                            <h6>
                                {' '}
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.SupportEmail" />
                            </h6>
                        </Label>
                        <Label for="appSupportEmail" sm={4}>
                            <span>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.SupportEmailDescription" />
                            </span>
                        </Label>
                        <Col sm={6}>
                            <ValidationInput
                                disabled={isFormDisabled}
                                testId="basicInformationAppSupportEmail"
                                type="email"
                                placeholder={intl.formatMessage({
                                    id: 'AppManagement.AppBasicInformationPage.SupportEmailPlaceholder',
                                })}
                                name="supportEmail"
                                value={basicInformation.supportEmail}
                                onChange={(e) => setBasicInformationFieldValue('supportEmail', e.currentTarget.value)}
                                innerRef={register({
                                    required: intl.formatMessage({
                                        id: 'AppManagement.AppBasicInformationPage.SupportEmailRequired',
                                    }),
                                    pattern: {
                                        value: /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/gi, // eslint-disable-line no-control-regex
                                        message: intl.formatMessage({
                                            id: 'AppManagement.AppBasicInformationPage.SupportEmailInnerRef',
                                        }),
                                    },
                                })}
                                errors={errors}
                            />
                        </Col>
                    </FormGroup>
                    <br />

                    <FormGroup row>
                        <Label sm={12} className="pt-0 pb-0">
                            <h6>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.Categories" />
                            </h6>
                        </Label>
                        <Label for="appCategories" sm={4}>
                            <span>
                                <FormattedMessage id="AppManagement.AppBasicInformationPage.CategoriesDescription" />
                            </span>
                        </Label>
                        <Col sm={6}>
                            <div className="custom-control-cursor-pointer">
                                <CheckBoxGroup
                                    name="categories"
                                    disabled={isFormDisabled}
                                    options={getAppCategoryOptions()}
                                    onChange={appCategoryCheckboxOnChange}
                                    checkedValues={basicInformation.categories}
                                    innerRef={register({
                                        validate: validateCategories,
                                    })}
                                    errors={errors}
                                    ariaRequired
                                />
                            </div>
                        </Col>
                    </FormGroup>
                </fieldset>
                <hr />
                <FormGroup row>
                    <div className="col-md-5 modal-button-group">
                        {authInfo.isAdmin && !isEditing && (
                            <Button
                                type="submit"
                                color="primary"
                                disabled={props.application.submissionStatus === appSubmissionStatus.approved}
                                onClick={() => toggleEditing()}
                            >
                                <FormattedMessage id="Common.Edit" />
                            </Button>
                        )}

                        {getSaveButton()}

                        {authInfo.isAdmin && isEditing && (
                            <Button type="submit" color="outline-primary" onClick={() => toggleEditing()}>
                                <FormattedMessage id="Common.Cancel" />
                            </Button>
                        )}
                    </div>
                </FormGroup>
            </Form>
        </Fragment>
    );
};

export default BasicInformationPage;
