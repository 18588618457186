import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'reactstrap';
import InstallUrlCard from './InstallUrlCard';
import RedirectUrlCard from './RedirectUrlCard';
import AppAuthenticationSettings from '../../../../model/appManagement/AppAuthenticationSettings';
import AppService from '../../../../services/AppService';
import { useNotification } from '../../../../contexts/NotificationProvider';

const OAuthSettingsPage = ({ appAuthSettings }: OAuthSettingsPageProps) => {
    const [installUrl, setInstallUrl] = useState(appAuthSettings.installUrl);
    const [redirectUrl, setRedirectUrl] = useState(appAuthSettings.redirectUrl);
    const { displayNotification } = useNotification();
    const intl = useIntl();

    const saveApiSettings = (e: React.FormEvent) => {
        e.preventDefault();
        let updatedSettings: AppAuthenticationSettings = {
            ...appAuthSettings,
            installUrl: installUrl,
            redirectUrl: redirectUrl,
        };
        AppService.saveApplicationAuthenticationSettings(updatedSettings).then((response) => {
            setInstallUrl(response.installUrl);
            setRedirectUrl(response.redirectUrl);

            displayNotification({
                message: intl.formatMessage({ id: 'AppManagement.AppDetails.SettingSavedSuccessMessage' }),
            });
        });
    };

    return (
        <React.Fragment>
            <div>
                <h3 className="mb-3">
                    <FormattedMessage id="AppManagement.AppDetails.Settings" />
                </h3>
                <p className="mb-2">
                    <FormattedMessage id="AppManagement.AppDetails.SettingsDescription" />
                </p>
            </div>
            <Form onSubmit={saveApiSettings}>
                <InstallUrlCard installUrl={installUrl!} setInstallUrl={setInstallUrl!} />
                <RedirectUrlCard redirectUrl={redirectUrl!} setRedirectUrl={setRedirectUrl!} />
                <div className="oauth-card">
                    <div className="d-flex justify-content-start w-100">
                        <input
                            type="submit"
                            className="btn btn-primary"
                            value={intl.formatMessage({ id: 'Common.Save' })}
                        />
                    </div>
                </div>
            </Form>
        </React.Fragment>
    );
};

export interface OAuthSettingsPageProps {
    appAuthSettings: AppAuthenticationSettings;
}

export default OAuthSettingsPage;
