import * as React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

interface IConditionalRouteProps {
    condition: boolean | undefined;
    defaultUrl?: string;
    replace?: boolean;
    state?: any;
}

function ConditionalRoute({ condition, defaultUrl, replace, state }: IConditionalRouteProps) {
    return condition ? <Outlet /> : <Navigate replace={replace} state={state} to={defaultUrl || ''} />;
}

export default ConditionalRoute;
