/* eslint-disable */
import { adminApi } from '../../helpers/ApiHelper';
import AppSubmissionApproval from '../../model/appManagement/admin/AppSubmissionApproval';
import AppSubmissionLog from '../../model/appManagement/admin/AppSubmissionLog';
import ErrorMessage from '../../model/services/ErrorMessage';
import App from '../../model/appManagement/App';
import AppStoreListingApproval from '../../model/appManagement/admin/AppStoreListingApproval';
import AppStoreListingSubmissionLog from '../../model/appManagement/admin/AppStoreListingSubmissionLog';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const getAppSubmissionLogs = async (appId: number) => {
    return await adminApi.get<AppSubmissionLog[], string>(`Apps/${appId}/AppSubmissionLogs`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const createAppSubmissionApproval = async (appId: number, appSubmissionApproval: AppSubmissionApproval) => {
    return await adminApi
        .post<boolean, string>(`Apps/${appId}/AppSubmissionApproval`, appSubmissionApproval)
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const getAllApps = async () => {
    return await adminApi.get<App[], string>('Apps').then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                throw errorMessage;
            }
        } else {
            throw undefinedResponse();
        }
    });
};

const submitListingApproval = async (approval: AppStoreListingApproval) => {
    return await adminApi
        .put<AppStoreListingApproval[], string>(`Apps/${approval.appId}/SubmitApproval`, approval)
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    throw errorMessage;
                }
            } else {
                throw undefinedResponse();
            }
        });
};

const getAppStoreListingSubmissionLogs = async (appId: string) => {
    return await adminApi
        .get<AppStoreListingSubmissionLog[], string>(`Apps/${appId}/SubmissionLogs`)
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    throw errorMessage;
                }
            } else {
                throw undefinedResponse();
            }
        });
};

export default {
    createAppSubmissionApproval,
    getAppSubmissionLogs,
    getAllApps,
    submitListingApproval,
    getAppStoreListingSubmissionLogs,
};
