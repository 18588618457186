const appCategories = {
    analytics: 'Analytics',
    automation: 'Automation',
    crm: 'CRM',
    epos: 'EPOS',
    leadCaptureAndManagement: 'LeadCaptureAndManagement',
    marketingAutomation: 'MarketingAutomation',
    scheduling: 'Scheduling',
};

export default appCategories;
