/* eslint-disable */
import environment from '../enums/environment';
import { api } from '../helpers/ApiHelper';
import EposApiCredentialsResult from '../model/eposApi/EposApiCredentialsResult';
import ErrorMessage from '../model/services/ErrorMessage';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const getCredentials = async (isTestEnvironment: boolean) => {
    const response = await api.get<EposApiCredentialsResult, string>('EposApiCredentials').then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
    if ('errorMessage' in response) {
        throw response.errorMessage;
    }
    return isTestEnvironment ? response.test : response.production;
};

const regenerateSecondSecret = async (providerId: number, isTestEnvironment: boolean) => {
    const env = isTestEnvironment ? environment.test : environment.production;
    return await api
        .post<string, string>(`EposApiCredentials/RegenerateSecret?providerId=${providerId}&environment=${env}`)
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

export default {
    getCredentials,
    regenerateSecondSecret,
};
