import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import ImageUpload from '../common/ImageUpload';
import { ReactComponent as AppPlaceholderIcon } from '../../content/images/App-placeholder.svg';
import AppService from '../../services/AppService';
import { ImageListType, ImageType } from 'react-images-uploading';
import App from '../../model/appManagement/App';

function AppImageContainer(props: AppImageContainerProps) {
    const [logoUrl, setLogoUrl] = useState<string>();
    const [isLoading, setisLoading] = useState(false);

    useEffect(() => {
        setisLoading(true);
        AppService.getApplicationLogoUrl(props.application.organisationId, props.application.appId)
            .then((response) => {
                if (typeof response === 'string') {
                    setLogoUrl(response);
                }
            })
            .finally(() => {
                setisLoading(false);
            });
    }, [props.application, props.imageUpdated]);

    useEffect(() => {
        setLogoUrl(props.logoUrl);
    }, [props.logoUrl]);

    const onAppImageChange = (imageList: ImageListType) => {
        props.onChange!(imageList);
    };

    const getImageContent = () => {
        if (props.requireUpload) {
            let applicationImage = null;
            if (props.appImage) {
                applicationImage = props.appImage;
            } else if (logoUrl) {
                applicationImage = { data_url: logoUrl };
            }
            return (
                <ImageUpload
                    images={applicationImage ? [applicationImage] : []}
                    onChange={onAppImageChange}
                    isDisabled={props.isDisabled}
                />
            );
        } else {
            if (logoUrl) {
                return <img src={logoUrl} alt="" />;
            } else {
                return <AppPlaceholderIcon />;
            }
        }
    };

    if (isLoading) return null;

    return <Fragment>{getImageContent()}</Fragment>;
}

interface AppImageContainerProps {
    appImage?: ImageType;
    onChange?(imageList: ImageListType): void;
    requireUpload?: boolean;
    application: App;
    isDisabled?: boolean;
    logoUrl?: string;
    imageUpdated?: boolean;
}

export default AppImageContainer;
