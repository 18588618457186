import React from 'react';
import Switch from 'react-switch';
import PropTypes from 'prop-types';

const Toggle = (props) => {
    return (
        <label>
            <span>{props.label}</span>

            <Switch
                disabled={props.disabled}
                onChange={props.handleChange}
                checked={props.checked}
                className={props.className ? `toggle-switch ${props.className}` : 'toggle-switch'}
                borderRadius={5}
                handleDiameter={28}
                height={32}
                width={80}
                onColor="#054169"
                offColor="#B2B2B1"
                uncheckedIcon={<span className="toggle-option-label">{props.uncheckedLabel}</span>}
                checkedIcon={<span className="toggle-option-label">{props.checkedLabel}</span>}
            />
        </label>
    );
};

Toggle.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    handleChange: PropTypes.func,
    checkedLabel: PropTypes.string,
    uncheckedLabel: PropTypes.string,
    className: PropTypes.string,
};

export default Toggle;
