/* eslint-disable */
import { api, apiBase } from '../helpers/ApiHelper';
import { getEnvironment } from '../helpers/EnvironmentHelper';
import ErrorMessage from '../model/services/ErrorMessage';
import PagedResult from '../model/utility/PagedResult';
import GetLogsRequest from '../model/webhooks/GetLogsRequest';
import GetSettingsRequest from '../model/webhooks/GetSettingsRequest';
import ReEnableWebhooksRequest from '../model/webhooks/ReEnableWebhooksRequest';
import UpdateSettingsRequest from '../model/webhooks/UpdateSettingsRequest';
import WebhooksLogEntry from '../model/webhooks/WebhooksLogEntry';
import WebhooksUserSettings from '../model/webhooks/WebhooksUserSetttings';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const getLogs = async ({
    isTestEnvironment,
    responseStatus,
    pageCursor,
    organisationId,
    appId,
    nextPageUrl,
}: GetLogsRequest) => {
    if (nextPageUrl) {
        return apiBase.get(nextPageUrl);
    }

    let url = `Webhooks/Logs`;
    let params = {
        environment: getEnvironment(isTestEnvironment),
        responseStatus: responseStatus,
        pageCursor,
        organisationId: organisationId,
        appId: appId,
    };
    return api.get<PagedResult<WebhooksLogEntry>, string>(url, params).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const getSettings = ({ isTestEnvironment, appId, organisationId }: GetSettingsRequest) => {
    let url = `Webhooks/Settings`;
    let params = {
        environment: getEnvironment(isTestEnvironment),
        appId: appId,
        organisationId: organisationId,
    };
    return api.get<WebhooksUserSettings, string>(url, params).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const updateSettings = ({ isTestEnvironment, appId, organisationId, settings }: UpdateSettingsRequest) => {
    let url = `Webhooks/Settings`;
    let params = {
        environment: getEnvironment(isTestEnvironment),
        appId,
        organisationId,
        settings: settings,
    };

    return api.post<undefined, string>(url, params).then((response) => {
        if (response.data === undefined && response.ok) {
            return true;
        } else if (response.data !== undefined && !response.ok) {
            let errorMessage: ErrorMessage = { errorMessage: response.data };
            return errorMessage;
        } else {
            return undefinedResponse();
        }
    });
};

const reEnableWebhooks = async ({ isTestEnvironment, appId, organisationId }: ReEnableWebhooksRequest) => {
    let url = `Webhooks/Settings/ReEnable`;
    let params = {
        environment: getEnvironment(isTestEnvironment),
        appId,
        organisationId,
    };

    return api.post<undefined, string>(url, params).then((response) => {
        if (response.data === undefined && response.ok) {
            return true;
        } else if (response.data !== undefined && !response.ok) {
            let errorMessage: ErrorMessage = { errorMessage: response.data };
            return errorMessage;
        } else {
            return undefinedResponse();
        }
    });
};

export default {
    getLogs,
    getSettings,
    reEnableWebhooks,
    updateSettings,
};
