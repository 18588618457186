import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
import App from '../../model/appManagement/App';
import AppService from '../../services/AppService';
import { ImageListType, ImageType } from 'react-images-uploading';
import ImageUpload from '../common/ImageUpload';

const AppListingImagesContainer = (props: AppListingImagesContainerProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [imageList, setImageList] = useState<ImageListType>([]);
    const maxImages = 6;

    useEffect(() => {
        setIsLoading(true);
        AppService.getAllAppStoreListingImageUrls(props.application.id, props.application.organisationId)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    let images = response.map((item: ImageUrl): ImageType => {
                        return { dataURL: item.url };
                    });
                    setImageList(images);
                    console.log(images);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [setIsLoading, setImageList, props.application.id, props.application.organisationId]);

    const configureFormData = (images: ImageType[]): FormData => {
        // Find available image numbers
        let availableImageNumbers: number[] = [];
        for (let i = 0; i < maxImages; i++) {
            availableImageNumbers.push(i);
        }
        console.log(availableImageNumbers);
        imageList.forEach((image) => {
            if (!image.file && image.dataURL) {
                let urlSplit = image.dataURL.split('/');
                availableImageNumbers.splice(availableImageNumbers.indexOf(Number(urlSplit[urlSplit.length - 1])), 1);
            }
        });

        // Create upload form
        let formData = new FormData();
        images.forEach((image: ImageType, index: number) => {
            formData.append(`image${index}`, image.file!);
            formData.append(`imageNumber${index}`, availableImageNumbers.shift()!.toString());
        });
        formData.append('orgId', props.application.organisationId.toString());

        return formData;
    };

    const imagesOnChange = (images: ImageListType): void => {
        // Upload new images
        let toUpload: ImageType[] = [];

        for (let i = 0; i < images.length; i++) {
            if (images[i].file) {
                toUpload.push(images[i]);
            }
        }

        if (toUpload.length > 0) {
            AppService.uploadAppStoreListingImages(props.application.id, configureFormData(toUpload)).then(
                (response) => {
                    if (response !== true) {
                        // TODO: Handle upload error
                    } else {
                        getImages();
                    }
                }
            );
        }
    };

    const getImages = () => {
        AppService.getAllAppStoreListingImageUrls(props.application.id, props.application.organisationId).then(
            (response) => {
                if (!('errorMessage' in response)) {
                    let images = response.map((item: ImageUrl): ImageType => {
                        return { dataURL: item.url };
                    });
                    setImageList(images);
                }
            }
        );
    };

    const onImageRemove = (image: ImageType) => {
        setIsLoading(true);
        let index = imageList.indexOf(image);
        let urlSplit = imageList[index].dataURL!.split('/');
        let imageNumber = Number(urlSplit[urlSplit.length - 1]);
        imageList.splice(imageNumber, 1);
        AppService.deleteAppStoreListingImage(props.application.id, imageNumber, props.application.organisationId).then(
            (response) => {
                if (response === true) {
                    // Show modal
                    getImages();
                    setIsLoading(false);
                }
            }
        );
    };

    if (isLoading) return <div></div>;

    return (
        <Fragment>
            <ImageUpload
                images={imageList}
                maxImages={maxImages}
                onChange={imagesOnChange}
                onDelete={onImageRemove}
                isDisabled={props.isDisabled}
            />
        </Fragment>
    );
};

interface AppListingImagesContainerProps {
    application: App;
    isDisabled: boolean;
}

interface ImageUrl {
    imageNumber: number;
    url: string;
}

export default AppListingImagesContainer;
