import * as React from 'react';
import SidebarMenuItem from '../../model/layout/SidebarMenuItem';
import SidebarTitle from '../common/sidebar/SidebarTitle';

const Sidebar = (props: SidebarProps) => {
    // Render titles
    return (
        <nav className={'dp-sidebar'}>
            {props.menuItems.map((item, index) => {
                return <SidebarTitle item={item} key={index} />;
            })}
        </nav>
    );
};

export interface SidebarProps {
    menuItems: SidebarMenuItem[];
}

export default Sidebar;
