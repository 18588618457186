import { v4 as uuidv4 } from 'uuid';
import AppPricing from '../enums/appPricing';

class AppStoreListing {
    constructor(data) {
        this.id = data ? data.id : 0;
        this.name = data ? data.name : '';
        this.shortDescription = data ? data.shortDescription : '';
        this.longDescription = data ? data.longDescription : '';
        this.builtBy = data ? data.builtBy : '';
        this.categories = data ? data.categories : [];
        this.videoLink = data ? data.videoLink : '';
        this.pricing = data ? data.pricing : AppPricing.Free;
        this.websiteUrl = data ? data.websiteUrl : '';
        this.termsOfServiceUrl = data ? data.termsOfServiceUrl : '';
        this.privacyPolicyUrl = data ? data.privacyPolicyUrl : '';
        this.supportEmail = data ? data.supportEmail : '';
        this.uniqueIdentifies = uuidv4();
        this.submissionStatus = data ? data.submissionStatus : '';
    }

    toJson() {
        return {
            Id: this.id,
            Name: this.name,
            ShortDescription: this.shortDescription,
            LongDescription: this.longDescription,
            BuiltBy: this.builtBy,
            Categories: this.categories,
            VideoLink: this.videoLink,
            Pricing: this.pricing,
            WebsiteUrl: this.websiteUrl,
            TermsOfServiceUrl: this.termsOfServiceUrl,
            PrivacyPolicyUrl: this.privacyPolicyUrl,
            SupportEmail: this.supportEmail,
            SubmissionStatus: this.submissionStatus,
        };
    }
}

export default AppStoreListing;
