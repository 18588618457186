import React, { useState, useEffect } from 'react';
import SuccessIconWrapper from '../common/SuccessIconWrapper';
import appNavigationTabs from '../../enums/appNavigationTabs';
import ProgressTrackingBar from '../common/ProgressTrackingBar';
import ApplicationProgressHelper from '../../helpers/ApplicationProgressHelper';
import { ReactComponent as ChevronIcon } from '../../content/icons/Chevron.svg';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import environment from '../../enums/environment';
import ButtonBar from '../common/ButtonBar';
import { Col, FormGroup, Label } from 'reactstrap';

function AppGetStartedPage(props) {
    const [progressValue, setProgressValue] = useState(0);

    const intl = useIntl();

    useEffect(() => {
        if (props.appProgress != null) {
            setProgressValue(ApplicationProgressHelper.getProgress(props.appProgress));
        }
    }, [props.appProgress]);

    const sectionItems = [
        appNavigationTabs.basicInformation,
        appNavigationTabs.authentication,
        appNavigationTabs.certification,
        appNavigationTabs.webhookConfiguration,
        appNavigationTabs.testDiary,
        appNavigationTabs.submitApp,
    ];

    const environmentTypes = [
        {
            index: 'test',
            value: environment.test,
            isActive: props.isTestEnvironment,
            label: 'Test',
        },
        {
            index: 'live',
            value: environment.production,
            isActive: !props.isTestEnvironment,
            label: 'Live',
        },
    ];

    function getSectionTitle(item) {
        switch (item) {
            case appNavigationTabs.basicInformation:
                return intl.formatMessage({ id: 'AppManagement.AppGetStartedPage.BasicInformation' });
            case appNavigationTabs.authentication:
                return intl.formatMessage({ id: 'AppManagement.AppGetStartedPage.Authentication' });
            case appNavigationTabs.certification:
                return intl.formatMessage({ id: 'AppManagement.AppGetStartedPage.Certifications' });
            case appNavigationTabs.webhookConfiguration:
                return intl.formatMessage({ id: 'AppManagement.AppGetStartedPage.WebhooksConfiguration' });
            case appNavigationTabs.testDiary:
                return intl.formatMessage({ id: 'AppManagement.AppGetStartedPage.TestDiaries' });
            case appNavigationTabs.submitApp:
                return intl.formatMessage({ id: 'AppManagement.AppGetStartedPage.SubmitApp' });
            default:
                return '';
        }
    }

    function getSectionDescriptionText(item) {
        switch (item) {
            case appNavigationTabs.basicInformation:
                return intl.formatMessage({ id: 'AppManagement.AppGetStartedPage.BasicInformationDescription' });
            case appNavigationTabs.authentication:
                return intl.formatMessage({ id: 'AppManagement.AppGetStartedPage.AuthenticationDescription' });
            case appNavigationTabs.certification:
                return intl.formatMessage({ id: 'AppManagement.AppGetStartedPage.CertificationsDescription' });
            case appNavigationTabs.webhookConfiguration:
                return intl.formatMessage({ id: 'AppManagement.AppGetStartedPage.WebhooksConfigurationDescription' });
            case appNavigationTabs.testDiary:
                return intl.formatMessage({ id: 'AppManagement.AppGetStartedPage.TestDiariesDescription' });
            case appNavigationTabs.submitApp:
                return intl.formatMessage({ id: 'AppManagement.AppGetStartedPage.SubmitAppDescription' });
            default:
                return '';
        }
    }

    function isSectionComplete(section) {
        if (props.appProgress === null) {
            return false;
        }
        if (props.appProgress.basicInformationComplete && section === appNavigationTabs.basicInformation) {
            return true;
        }
        if (props.appProgress.certificationsComplete && section === appNavigationTabs.certification) {
            return true;
        }
        if (props.appProgress.webhooksConfigurationComplete && section === appNavigationTabs.webhookConfiguration) {
            return true;
        }
        if (props.appProgress.testDiariesComplete && section === appNavigationTabs.testDiary) {
            return true;
        }
        if (props.appProgress.appSubmitted && section === appNavigationTabs.submitApp) {
            return true;
        }

        if (section === appNavigationTabs.authentication) {
            return true;
        }

        return false;
    }

    return (
        <div className="get-started-page">
            <h1>
                <FormattedMessage id="AppManagement.AppGetStartedPage.GetStartedHeader" />
            </h1>
            <div className="d-flex mb-4">
                <div className="progress-value">
                    <span className="mr-1">
                        <FormattedMessage id="AppManagement.AppGetStartedPage.Progress" />
                    </span>
                    <span className="font-weight-bold">{`${progressValue}%`}</span>
                </div>
                <ProgressTrackingBar value={progressValue} />
            </div>
            <FormGroup row>
                <Label sm={4}>
                    <h5>Environment</h5>
                </Label>
                <Col sm={6}>
                    <ButtonBar items={environmentTypes} setValue={props.switchEnvironment} />
                </Col>
            </FormGroup>
            <h6 className="mb-3">
                <FormattedMessage id="AppManagement.AppGetStartedPage.GetStartedDescription" />
            </h6>

            {sectionItems.map((section) => {
                return (
                    <div
                        key={section}
                        className={!isSectionComplete(section) ? 'section' : 'section completed'}
                        onClick={() => props.onTabChanged(section)}
                    >
                        <div className="icon-container">
                            <SuccessIconWrapper status={isSectionComplete(section) ? 'completed' : 'notStarted'} />
                        </div>
                        <div>
                            <h6>{getSectionTitle(section)}</h6>
                            <p>{getSectionDescriptionText(section)}</p>
                        </div>
                        {!isSectionComplete(section) && (
                            <div className="arrow-container">
                                <ChevronIcon className="rotate-90deg" />
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

AppGetStartedPage.propTypes = {
    application: PropTypes.object.isRequired,
    onTabChanged: PropTypes.func.isRequired,
    appProgress: PropTypes.object.isRequired,
};

export default AppGetStartedPage;
