import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Label } from 'reactstrap';
import TextWithIcon from '../common/TextWithIcon';
import AppImageContainer from './AppImageContainer';
import CopyToClipboard from '../common/CopyToClipboard';
import App from '../../model/appManagement/App';
import AppService from '../../services/AppService';
import ErrorMessage from '../../model/services/ErrorMessage';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import LoadingSpinner from '../common/LoadingSpinner';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import { ReactComponent as BackIcon } from '../../content/icons/Icon-Arrow-Left.svg';
import SideBarContentPage, { SideBarTabLabel } from '../common/SideBarContentPage';
import { useOrganisation } from '../../contexts/OrganisationContext';
import OAuthCredentialsPage from './appDetails/credentials/OAuthCredentialsPage';
import OAuthSettingsPage from './appDetails/settings/OAuthSettingsPage';
import TestDiariesPage from './appDetails/testDiaries/TestDiariesPage';
import AppAuthenticationSettings from '../../model/appManagement/AppAuthenticationSettings';
import { HubAlertProps } from '../common/hubAlert/HubAlert';
import HubAlertManager from '../common/hubAlert/HubAlertManager';

const AppDetailsPage = () => {
    const [alerts, setAlerts] = useState<HubAlertProps[]>([]);
    const [application, setApplication] = useState<App>();
    const [authSettings, setAuthSettings] = useState<AppAuthenticationSettings>();
    const isTestEnvironment = true;
    const [isApplicationLoading, setIsApplicationLoading] = useState(true);
    const [isAuthSettingsLoading, setIsAuthSettingsLoading] = useState(true);
    const [logoUrl, setLogoUrl] = useState<string>();
    const [currentTabKey, setCurrentTabKey] = useState(AppDetailsTabKeys.OAuthCredentials);
    const intl = useIntl();
    const { authInfo } = useAuthentication();
    const organisation = useOrganisation();
    const navigate = useNavigate();
    const { appId } = useParams();
    const { state } = useLocation();

    useEffect(() => {
        // Check for the client secret after a redirect from creating app.
        if (state?.clientSecret) {
            //TODO: Show client secret in modal (awaiting for final designs)
            // showing the client secret in an alert for now.
            alert(`Client Secret: ${state.clientSecret}`);
        }
    }, [state]);

    // Get application
    useEffect(() => {
        AppService.getApplicationById(appId!, organisation.defaultOrganisation!.id)
            .then((response) => {
                setApplication(response);
            })
            .catch((error: ErrorMessage) => {
                setAlerts((i) => [...i, { type: 'error', message: error.errorMessage }]);
            })
            .finally(() => {
                setIsApplicationLoading(false);
            });

        AppService.getApplicationAuthenticationSettings(appId!, isTestEnvironment)
            .then((response) => {
                setAuthSettings(response);
            })
            .catch((error: ErrorMessage) => {
                setAlerts((i) => [...i, { type: 'error', message: error.errorMessage }]);
            })
            .finally(() => {
                setIsAuthSettingsLoading(false);
            });
    }, [appId, isTestEnvironment, organisation]);

    // Get logo url
    useEffect(() => {
        AppService.getApplicationLogoUrl(organisation.defaultOrganisation!.id, appId!)
            .then((response) => {
                setLogoUrl(response);
            })
            .catch((error: ErrorMessage) => {
                // setInfoBars((i) => [...i, { type: infoBarType.error, message: error.errorMessage }]);
            });
    }, [appId, organisation.defaultOrganisation]);

    const getAppId = () => {
        return (
            <div className="app-id-container">
                <Label>
                    <FormattedMessage id="AppManagement.AppNavigationBasePage.AppID" />
                    {` ${application!.appId}`}
                </Label>
                <CopyToClipboard id="appId" text={application!.appId} />
            </div>
        );
    };

    const getTabLabels = (): SideBarTabLabel[] => {
        return [
            {
                key: AppDetailsTabKeys.OAuthCredentials,
                name: intl.formatMessage({ id: 'AppManagement.AppDetails.OAuth' }),
                isActive: currentTabKey === AppDetailsTabKeys.OAuthCredentials,
                subItems: [
                    {
                        key: AppDetailsTabKeys.OAuthCredentials,
                        name: intl.formatMessage({ id: 'AppManagement.AppDetails.Credentials' }),
                        isActive: currentTabKey === AppDetailsTabKeys.OAuthCredentials,
                    },
                    {
                        key: AppDetailsTabKeys.OAuthSettings,
                        name: intl.formatMessage({ id: 'AppManagement.AppDetails.Settings' }),
                        isActive: currentTabKey === AppDetailsTabKeys.OAuthSettings,
                    },
                ],
            },
            {
                key: AppDetailsTabKeys.TestDiaries,
                name: intl.formatMessage({ id: 'AppManagement.AppDetails.TestDiaries' }),
                isActive: currentTabKey === AppDetailsTabKeys.TestDiaries,
            },
        ];
    };

    const getTabs = () => {
        return [
            <OAuthCredentialsPage key={AppDetailsTabKeys.OAuthCredentials} appAuthSettings={authSettings!} />,
            <OAuthSettingsPage key={AppDetailsTabKeys.OAuthSettings} appAuthSettings={authSettings!} />,
            <TestDiariesPage key={AppDetailsTabKeys.TestDiaries} />,
        ];
    };

    const onTabChanged = (tabKey: string) => {
        if (tabKey !== currentTabKey) {
            setCurrentTabKey(tabKey);
        }
    };

    if (isApplicationLoading || isAuthSettingsLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="app-navigation-page">
            <HubAlertManager alerts={alerts} />
            <div className="app-navigation-header">
                <div className="back-icon" onClick={() => navigate(!authInfo.isAdmin ? `/Apps` : '/Admin/Apps')}>
                    <TextWithIcon icon={<BackIcon />} text="BACK TO APPS" textClass="back-label" />
                </div>

                <div className="app-info d-flex mt-3">
                    <div className="app-icon-container">
                        <AppImageContainer application={application!} imageUpdated={false} logoUrl={logoUrl!} />
                    </div>
                    <div className="flex-column ml-2">
                        <h1>{application!.title}</h1>
                        {getAppId()}
                    </div>
                </div>
            </div>
            <Fragment>
                <SideBarContentPage
                    tabLabels={getTabLabels()}
                    currentTabKey={currentTabKey}
                    tabs={getTabs()}
                    onTabChanged={onTabChanged}
                />
            </Fragment>
        </div>
    );
};

export const AppDetailsTabKeys = {
    OAuthSettings: 'oauthsettings',
    OAuthCredentials: 'oauthcredentials',
    TestDiaries: 'testdiaries',
};

export default AppDetailsPage;
