import * as React from 'react';
import { Fragment, useState } from 'react';
import { Table, TableContainer, TableHead, TableRow, TableBody, TableCell, Link } from '@mui/material';
import { Tooltip, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import TestProvider from '../../model/TestDiaries/TestProvider';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import UnlinkIcon from '@mui/icons-material/LinkOff';

export interface TestDiaryTableProps {
    testDiaries?: TestProvider[];
    isAdmin?: boolean;
    openUnlinkTestProviderModal?: (testProvider: TestProvider) => void;
}

const TestDiaryTable = ({ testDiaries = [], isAdmin, openUnlinkTestProviderModal }: TestDiaryTableProps) => {
    const [viewTooltipsOpen, setViewTooltipsOpen] = useState(Array(4).fill(false));
    const [unlinkTooltipsOpen, setUnlinkTooltipsOpen] = useState(Array(4).fill(false));
    const toggleUnlink = (index: number) => {
        const newTooltipsOpen = unlinkTooltipsOpen.map((item, i) => (i === index ? !item : false));
        setUnlinkTooltipsOpen(newTooltipsOpen);
    };
    const toggleView = (index: number) => {
        const newTooltipsOpen = viewTooltipsOpen.map((item, i) => (i === index ? !item : false));
        setViewTooltipsOpen(newTooltipsOpen);
    };
    return (
        <Fragment>
            <TableContainer>
                <Table>
                    <TableHead sx={{ backgroundColor: '#e5e9f0' }}>
                        <TableRow>
                            <TableCell>
                                <FormattedMessage id="TestDiaries.Table.Name" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="TestDiaries.Table.ProviderId" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="TestDiaries.Table.Organisation" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="TestDiaries.Table.MicrositeName" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="TestDiaries.Table.InstalledApps" />
                            </TableCell>
                            <TableCell align="center">
                                <FormattedMessage id="TestDiaries.Table.Actions" />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {testDiaries &&
                            testDiaries.map((diary, index) => (
                                <TableRow key={diary.id}>
                                    <TableCell>{diary.name}</TableCell>
                                    <TableCell>{diary.remoteProviderId}</TableCell>
                                    <TableCell>{diary.organisationName}</TableCell>
                                    <TableCell>{diary.micrositeName}</TableCell>
                                    <TableCell>{diary.connectedApps.length}</TableCell>
                                    <TableCell align="center">
                                        {isAdmin && (
                                            <Label for="unlink">
                                                <span
                                                    className="icon-link"
                                                    onClick={() => openUnlinkTestProviderModal?.(diary)}
                                                >
                                                    <UnlinkIcon id={`unlink-info-${index}`} />
                                                </span>
                                                <Tooltip
                                                    placement="top"
                                                    isOpen={unlinkTooltipsOpen[index]}
                                                    target={`unlink-info-${index}`}
                                                    toggle={() => toggleUnlink(index)}
                                                >
                                                    <FormattedMessage id="TestDiaries.Table.Unlink" />
                                                </Tooltip>
                                            </Label>
                                        )}
                                        <Label for="view-diary">
                                            <Link href={diary.diaryUrl} target="_blank">
                                                <OpenInNewIcon id={`view-diary-info-${index}`} />
                                            </Link>
                                            <Tooltip
                                                placement="top"
                                                isOpen={viewTooltipsOpen[index]}
                                                target={`view-diary-info-${index}`}
                                                toggle={() => toggleView(index)}
                                            >
                                                <FormattedMessage id="TestDiaries.Table.View" />
                                            </Tooltip>
                                        </Label>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    );
};

export default TestDiaryTable;
