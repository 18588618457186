import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import pkceChallenge from 'pkce-challenge';
import { Container, Form, Button, Input, FormGroup, Label } from 'reactstrap';

function TestAppInstallPage() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const authorizationUrl = new URL(searchParams.get('authorization_uri'));
    const redirectUrl = new URL(searchParams.get('redirect_uri'));
    const clientIdParam = authorizationUrl.searchParams.get('client_id');

    // Generate pkce code verifier and code challenge
    const { code_verifier, code_challenge } = pkceChallenge();
    // Storing the code verifier to session storage to be used later on to exchange for the access token.
    sessionStorage.setItem('app-install-code-verifier', code_verifier);
    // Storing the redirect uri to session storage, (ResDiary will require this installation flow to redirect back to this uri)
    sessionStorage.setItem('app-install-redirect-url', redirectUrl);
    // Append the code challenge and method (OpenIddict only supports S256) to the authorization url query parameters
    authorizationUrl.searchParams.append('code_challenge', code_challenge);
    authorizationUrl.searchParams.append('code_challenge_method', 'S256');

    const [clientId, setClientId] = useState(clientIdParam);
    const [clientSecret, setClientSecret] = useState();

    const authorize = () => {
        sessionStorage.setItem('client-id', clientId);
        sessionStorage.setItem('client-secret', clientSecret);
        sessionStorage.setItem('auth-base-url', authorizationUrl.origin);
        window.location.replace(authorizationUrl);
    };

    return (
        <Container className="account-page">
            <div className="flex-container-center">
                <Form className="account-form">
                    <p className="text-danger">
                        For testing purposes only (client app credentials should be stored in a secured location).
                    </p>
                    <p className="text-danger">
                        Please supply the <b>Client Id</b> and <b>Client Secret</b> for the app.
                    </p>
                    <FormGroup>
                        <div className="d-flex">
                            <Label for="clientId">Client Id</Label>
                        </div>
                        <Input
                            type="text"
                            name="clientId"
                            id="clientId"
                            placeholder="CLIENT ID"
                            value={clientId}
                            onChange={(event) => setClientId(event.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <div className="d-flex">
                            <Label for="clientSecret">Client Secret</Label>
                        </div>
                        <Input
                            type="password"
                            name="clientSecret"
                            id="clientSecret"
                            placeholder="CLIENT SECRET"
                            onChange={(event) => setClientSecret(event.target.value)}
                        />
                    </FormGroup>
                    <Button disabled={!clientId || !clientSecret} onClick={authorize}>
                        Submit
                    </Button>
                </Form>
            </div>
        </Container>
    );
}

export default TestAppInstallPage;
