import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup } from 'reactstrap';
import useValidation from '../../hooks/useValidation';
import { useOrganisation } from '../../contexts/OrganisationContext';
import ValidationInput from '../common/ValidationInput';
import AppService from '../../services/AppService';
import appTypeEnum from '../../enums/appType';
import apiTypeEnum from '../../enums/apiType';
import { useNavigate } from 'react-router-dom';
import InfoBarManager from '../common/InfoBarManager';
import infoBarType from '../../enums/infoBarType';
import { InfoBarProps } from '../common/InfoBar';
import { FormattedMessage, useIntl } from 'react-intl';
import ErrorMessage from '../../model/services/ErrorMessage';

let _ID = 0;
const CreateAppModal = (props: CreateAppModalProps) => {
    const [title, setTitle] = useState('');
    const [organisationId, setOrganisationId] = useState(0);
    const [appType, setAppType] = useState(appTypeEnum.private);
    const [apiType, setApiType] = useState(apiTypeEnum.eposApi);
    const [infoBars, setInfoBars] = useState<InfoBarProps[]>([]);

    const { register, errors, submitDisabled, submit } = useValidation();
    const { defaultOrganisation } = useOrganisation();
    const navigate = useNavigate();
    const titleCharacterLimit = 25;
    const intl = useIntl();

    useEffect(() => {
        setOrganisationId(defaultOrganisation!.id);
    }, [defaultOrganisation]);

    const submitCreateApp = () => {
        AppService.createApplication(title, organisationId, appType, apiType)
            .then((response) => {
                props.setIsOpen(false);
                navigate(`/Apps/${response.appId}`, { state: { clientSecret: response.clientSecret } });
            })
            .catch((error: ErrorMessage) => {
                addErrorBar(error.errorMessage);
            });
    };

    const addErrorBar = (message: string) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message, expireIn: 0 }]);
    };

    const closeModal = () => {
        initModal();
        props.setIsOpen(!props.isOpen);
    };

    const initModal = () => {
        setTitle('');
        // App type is hard coded until the backend is refactored.
        setAppType(appTypeEnum.public);
        setApiType(apiTypeEnum.eposApi);
    };

    return (
        <Modal
            centered
            isOpen={props.isOpen}
            size="md"
            className="create-app-modal"
            toggle={() => {
                closeModal();
            }}
        >
            <InfoBarManager infoBarArray={infoBars} />
            <ModalHeader
                className=""
                toggle={() => {
                    closeModal();
                }}
            >
                <FormattedMessage id="AppManagement.CreateAppModal.CreateNewApp" />
            </ModalHeader>
            <ModalBody>
                <Form
                    id="create-app-form"
                    onSubmit={submit(submitCreateApp)}
                    className="modal-form border-top-3 border-bottom-0"
                >
                    <FormGroup className="w-100">
                        <div className="d-flex">
                            <Label for="title">
                                <FormattedMessage id="AppManagement.CreateAppModal.TitleHeader" />
                            </Label>
                        </div>
                        <ValidationInput
                            testId="createAppTitle"
                            type="text"
                            name="title"
                            value={title}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.currentTarget.value)}
                            innerRef={register({
                                required: intl.formatMessage({ id: 'AppManagement.CreateAppModal.AppNameRequired' }),
                            })}
                            errors={errors}
                            maxLength={titleCharacterLimit}
                            displayCharacterCount={true}
                            placeholder={intl.formatMessage({ id: 'AppManagement.CreateAppModal.AppNamePlaceholder' })}
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <div className="col-md-5 modal-button-group">
                    <Button disabled={submitDisabled} form="create-app-form" type="submit" color="primary">
                        <FormattedMessage id="AppManagement.CreateAppModal.CreateApp" />
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export interface CreateAppModalProps {
    isOpen: boolean;
    setIsOpen(isOpen: boolean): void;
}

export default CreateAppModal;
