class ApplicationProgressHelper {
    static getProgress(appProgress) {
        let progressValue = 10; //authentication is already marked at 10% as default.

        if (appProgress.basicInformationComplete) {
            progressValue += 15;
        }

        if (appProgress.certificationsComplete) {
            progressValue += 35;
        }

        if (appProgress.webhooksConfigurationComplete) {
            progressValue += 10;
        }

        if (appProgress.testDiariesComplete) {
            progressValue += 10;
        }

        if (appProgress.appSubmitted) {
            progressValue += 20;
        }

        return progressValue;
    }
}
export default ApplicationProgressHelper;
