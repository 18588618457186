import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    Typography,
    InputAdornment,
    IconButton,
    Button,
    Link,
    Checkbox,
    FormGroup,
    FormControlLabel,
    TextField,
    Alert,
} from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ReactComponent as HubLogo } from '../../content/images/RD_hub_logo.svg';
import { ReactComponent as IconConfirmed } from '../../content/icons/Icon-Confirmed.svg';
import { ReactComponent as IconFailed } from '../../content/icons/Icon-Failed.svg';
import useQueryParam from '../../hooks/useQueryParam';
import AccountService from '../../services/AccountService';
import { FormattedMessage, useIntl } from 'react-intl';
import config from '../../config/app';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import InvitationToken from '../../model/user/InvitationToken';
import ErrorMessage from '../../model/services/ErrorMessage';
import { HubAlertProps } from '../common/hubAlert/HubAlert';
import HubAlertManager from '../common/hubAlert/HubAlertManager';

const loadingIcon = require('../../content/images/rd-bowtie-anim-transp.gif');

interface ActivateAccountForm {
    password: string;
    confirmPassword: string;
    acceptTerms: boolean;
}

const ActivateAccountPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [isValid, setIsValid] = useState(true);
    const [isConfimed, setIsConfirmed] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [invitationToken, setInvitationToken] = useState<InvitationToken>();
    const [hubAlerts, setHubAlerts] = useState<HubAlertProps[]>([]);
    const [acceptTerms, setAcceptTerms] = useState(false);

    //Form value schema
    const formSchema = Yup.object()
        .shape({
            password: Yup.string()
                .required(intl.formatMessage({ id: 'Profile.ActivateAccountPage.PasswordRequired' }))
                .matches(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?])[A-Za-z\d-+_!@#$%^&*.,?]{6,}$/,
                    intl.formatMessage({ id: 'Profile.ActivateAccountPage.PasswordNotValidated' })
                ),
            confirmPassword: Yup.string()
                .oneOf(
                    [Yup.ref('password'), undefined],
                    intl.formatMessage({ id: 'Profile.ActivateAccountPage.PasswordsMustMatch' })
                )
                .required(intl.formatMessage({ id: 'Profile.ActivateAccountPage.ConfirmPasswordRequired' }))
                .matches(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?])[A-Za-z\d-+_!@#$%^&*.,?]{6,}$/,
                    intl.formatMessage({ id: 'Profile.ActivateAccountPage.PasswordNotValidated' })
                ),
            acceptTerms: Yup.bool()
                .oneOf([true], intl.formatMessage({ id: 'Profile.ActivateAccountPage.AcceptTermsRequired' }))
                .required(),
        })
        .required();

    // Form setup
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ActivateAccountForm>({ resolver: yupResolver(formSchema) });

    // Get query params
    const token = useQueryParam('token');
    const userId = useQueryParam('userId');
    const organisationId = parseInt(useQueryParam('organisationId') ?? '0');

    useEffect(() => {
        AccountService.getInvitationToken(token ?? '', userId ?? '', organisationId)
            .then((response) => {
                setInvitationToken(response);
            })
            .catch(() => {
                setIsValid(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [isLoading, isConfimed, isLoading, showPassword, organisationId, userId, token]);

    const formSubmit: SubmitHandler<ActivateAccountForm> = (data: ActivateAccountForm, e: React.FormEvent) => {
        console.log('submit');
        e.preventDefault();
        setIsLoading(true);

        AccountService.registerTeamMember({
            token: invitationToken?.token!,
            organisationId: invitationToken?.organisationId!,
            userId: invitationToken?.userId!,
            firstName: invitationToken?.firstName!,
            lastName: invitationToken?.lastName!,
            password: data.password,
        })
            .then(() => {
                setIsConfirmed(true);
            })
            .catch((error: ErrorMessage) => {
                setHubAlerts((alerts) => [...alerts, { type: 'error', message: error.errorMessage }]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleMouseDownPassword = (e: React.MouseEvent) => {
        e.preventDefault();
    };

    // Render content functions
    const renderCardContent = () => {
        if (!isValid) {
            return renderInvalidCard();
        } else if (isConfimed) {
            return renderConfirmedCard();
        } else {
            return renderActivateAccountContent();
        }
    };

    const renderInvalidCard = () => {
        return (
            <div className="activation-content">
                <div className="notification-icon">
                    <IconFailed />
                </div>
                <Typography variant="h4" className="title">
                    <FormattedMessage id="Profile.ActivateAccountPage.InviteExpired" />
                </Typography>
                <Typography variant="body1" className="description">
                    <FormattedMessage id="Profile.ActivateAccountPage.InviteExpiredDescription" />
                </Typography>
            </div>
        );
    };

    const renderConfirmedCard = () => {
        return (
            <div className="activation-content status-card">
                <div className="notification-icon">
                    <IconConfirmed />
                </div>
                <Typography variant="h4" className="title">
                    <FormattedMessage id="Profile.ActivateAccountPage.AccountActivated" />
                </Typography>
                <Typography variant="body1" className="description">
                    <FormattedMessage id="Profile.ActivateAccountPage.AccountActivatedDescription" />
                </Typography>
                <Link onClick={() => navigate(config.loginPath)} variant="body1" underline="none">
                    <FormattedMessage id="Profile.ActivateAccountPage.LogIn" />
                </Link>
            </div>
        );
    };

    const renderPasswordInput = () => {
        return (
            <FormGroup className="password-form-group">
                <TextField
                    type={showPassword ? 'text' : 'password'}
                    label={intl.formatMessage({ id: 'Profile.ActivateAccountPage.Password' })}
                    {...register('password')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    tabIndex={-1}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                    className="password"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                />
                <TextField
                    type={showConfirmPassword ? 'text' : 'password'}
                    label={intl.formatMessage({ id: 'Profile.ActivateAccountPage.ReEnterPassword' })}
                    {...register('confirmPassword')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    tabIndex={-1}
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                    className="confirm-password"
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                />
                {errors.password?.message ===
                    intl.formatMessage({ id: 'Profile.ActivateAccountPage.PasswordNotValidated' }) &&
                    renderPasswordFormatAlert()}
            </FormGroup>
        );
    };

    const renderPasswordFormatAlert = () => {
        return (
            <Alert variant="filled" severity="error" className="password-format-alert">
                <FormattedMessage id="Profile.ActivateAccountPage.PasswordRule1" />
                <br />
                <FormattedMessage id="Profile.ActivateAccountPage.PasswordRule2" />
                <br />
                <FormattedMessage id="Profile.ActivateAccountPage.PasswordRule3" />
                <br />
                <FormattedMessage id="Profile.ActivateAccountPage.PasswordRule4" />
                <br />
                <FormattedMessage id="Profile.ActivateAccountPage.PasswordRule5" />
            </Alert>
        );
    };

    const renderActivateAccountContent = () => {
        return (
            <form onSubmit={handleSubmit(formSubmit)} className="activation-content">
                <FormGroup className="title-and-password">
                    <Typography variant="h4" className="title">
                        <FormattedMessage id="Profile.ActivateAccountPage.ActivateAccountTitle" />
                    </Typography>
                    <Typography variant="body1" className="description">
                        <FormattedMessage id="Profile.ActivateAccountPage.ActivateAccountDescription" />
                    </Typography>
                    {renderPasswordInput()}
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        required
                        control={
                            <Checkbox
                                {...register('acceptTerms')}
                                value={acceptTerms}
                                onChange={() => setAcceptTerms(!acceptTerms)}
                            />
                        }
                        label={intl.formatMessage({ id: 'Profile.ActivateAccountPage.TermsAndConditions' })}
                    />
                </FormGroup>
                <Button type="submit" variant="contained" className="submit-activation-form-btn">
                    <FormattedMessage id="Profile.ActivateAccountPage.ActivateAccount" />
                </Button>
            </form>
        );
    };

    if (isLoading) {
        return (
            <div className="activate-account-page">
                <div className="logo">
                    <HubLogo />
                </div>
                <div className="content-card loading-activate-content">
                    <img alt="spinner" src={loadingIcon} />
                </div>
            </div>
        );
    }

    return (
        <div className="activate-account-page">
            <div className="logo">
                <HubLogo />
            </div>
            <div className="content-card">{renderCardContent()}</div>
            <HubAlertManager alerts={hubAlerts} />
        </div>
    );
};

export default ActivateAccountPage;
