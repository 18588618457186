import React from 'react';
import { Table } from 'reactstrap';
import EposCredentialService from '../../services/EposCredentialService';
import EposAccessibleProviderRow from './EposAccessibleProviderRow';
import PropTypes from 'prop-types';

const EposAccessibleProviderList = (props) => {
    const updateSecondarySecret = async (accessibleProvider) => {
        let response = await EposCredentialService.regenerateSecondSecret(
            accessibleProvider.providerId,
            props.isTestEnvironment
        );

        const credentials = { ...props.eposCredentials };
        let result = credentials.accessibleProviders.map((ap) =>
            ap.providerId === accessibleProvider.providerId ? { ...ap, secondSecret: response } : ap
        );
        credentials.accessibleProviders = result;

        props.setEposCredentials(credentials);
    };

    return (
        <div className="py-4">
            <Table className="settings-table">
                <thead>
                    <tr>
                        <th className="settings-table__name-column">Provider ID</th>
                        <th>Name</th>
                        <th>Provider Group</th>
                        <th>Deployment</th>
                        <th className="settings-table__secret-column">Second Secret</th>
                        <th className="settings-table__button-column">
                            <p hidden>Regenerate Button</p>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {props.eposCredentials.accessibleProviders.map((accessibleProvider) => (
                        <EposAccessibleProviderRow
                            key={props.activeEnvironmentString + accessibleProvider.providerId}
                            accessibleProvider={accessibleProvider}
                            onButtonClick={async () => await updateSecondarySecret(accessibleProvider)}
                        />
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

EposAccessibleProviderList.propTypes = {
    activeEnvironmentString: PropTypes.string.isRequired,
    isTestEnvironment: PropTypes.bool.isRequired,
    eposCredentials: PropTypes.object.isRequired,
    setEposCredentials: PropTypes.func.isRequired,
};

export default EposAccessibleProviderList;
