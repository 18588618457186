import React, { Fragment } from 'react';
import { Progress } from 'reactstrap';
import PropTypes from 'prop-types';

function ProgressTrackingBar(props) {
    return (
        <Fragment>
            <div className="progress-tracker">
                <Progress value={props.value} />
            </div>
        </Fragment>
    );
}

ProgressTrackingBar.propTypes = {
    value: PropTypes.number.isRequired,
};

export default ProgressTrackingBar;
