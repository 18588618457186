import React, { useState } from 'react';
import ConsumerApiKeyRow from './ConsumerApiKeyRow';
import { Table } from 'reactstrap';
import ConsumerApiKeyService from '../../services/ConsumerApiKeyService';
import ConfirmationModal from '../common/ConfirmationModal';
import PropTypes from 'prop-types';

const ConsumerApiKeyList = (props) => {
    const updateApiKey = async (name) => {
        const newKey = { ...props.apiKey };
        let response = await ConsumerApiKeyService.regenerateApiKey(props.isTestEnvironment, name);
        if (typeof response == 'string') {
            newKey[`${name}Token`] = response;
        }
        props.setApiKey(newKey);
    };

    const confirmApiKeyChange = async () => await updateApiKey(keyToUpdate).then(() => setModalOpen(false));

    const [isModalOpen, setModalOpen] = useState(false);
    const [keyToUpdate, setKeyToUpdate] = useState();
    const confirm = (key) => {
        setKeyToUpdate(key);
        setModalOpen(true);
    };

    return (
        <div className="py-3">
            <Table className="settings-table">
                <thead>
                    <tr>
                        <th className="settings-table__name-column">Name</th>
                        <th>Token</th>
                        <th className="settings-table__button-column"></th>
                    </tr>
                </thead>

                <tbody>
                    <ConsumerApiKeyRow
                        name="Primary Key"
                        apiKey={props.apiKey.primaryToken}
                        onButtonClick={() => confirm('primary')}
                    />
                    <ConsumerApiKeyRow
                        name="Secondary Key"
                        apiKey={props.apiKey.secondaryToken}
                        onButtonClick={() => confirm('secondary')}
                    />
                </tbody>
            </Table>
            <ConfirmationModal
                header="Regenerate Api Key?"
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onAccept={confirmApiKeyChange}
                children={`Are you sure you want to regenerate the ${keyToUpdate} Api Key?`}
                isHideCancel={false}
            ></ConfirmationModal>
        </div>
    );
};

ConsumerApiKeyList.propTypes = {
    apiKey: PropTypes.shape({
        primaryToken: PropTypes.string.isRequired,
        secondaryToken: PropTypes.string,
    }).isRequired,
    isTestEnvironment: PropTypes.bool.isRequired,
    setApiKey: PropTypes.func.isRequired,
};

export default ConsumerApiKeyList;
