import { Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import TestDiaryTable from '../../../common/TestDiaryTable';

const TestDiariesPage = () => {
    return (
        <div className="test-diaries-tab">
            <div className="header">
                <Typography variant="h5" sx={{ paddingBottom: '1rem' }}>
                    <FormattedMessage id="AppManagement.AppDetails.TestDiaries" />
                </Typography>
                <Typography variant="body1">
                    <FormattedMessage id="AppManagement.AppDetails.TestDiariesTabDescription" />
                </Typography>
            </div>
            <div className="table">
                <TestDiaryTable />
            </div>
        </div>
    );
};

export default TestDiariesPage;
