import * as React from 'react';
import { FormGroup } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import CopyToClipboard from '../../../common/CopyToClipboard';

const InstallUrlCard = (props: InstallUrlProps) => {
    const intl = useIntl();

    return (
        <div className="oauth-card">
            <h5 className="mb-3">
                <FormattedMessage id="AppManagement.AppDetails.InstallUrl" />
            </h5>
            <p className="mb-3">
                <FormattedMessage id="AppManagement.AppDetails.InstallUrlDescription" />
            </p>
            <FormGroup>
                <div className="install-url">
                    <div className="copy-box">
                        <div className="copy-text">
                            <input
                                name="installUrlInput"
                                type="text"
                                placeholder={intl.formatMessage({
                                    id: 'AppManagement.AppDetails.InstallUrlPlaceholder',
                                })}
                                value={props.installUrl}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    props.setInstallUrl(e.currentTarget.value)
                                }
                            />
                        </div>
                        <div className="copy-icon">
                            <CopyToClipboard id="clientIdCopy" text={props.installUrl} isWhite={true} />
                        </div>
                    </div>
                </div>
            </FormGroup>
        </div>
    );
};

export interface InstallUrlProps {
    installUrl: string;
    setInstallUrl(url: string): void;
}

export default InstallUrlCard;
