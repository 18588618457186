import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import UserInvite from '../../model/user/UserInvite';
import TeamMember from '../../model/user/TeamMember';
import Modal from './Modal';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import useValidation from '../../hooks/useValidation';
import { useOrganisation } from '../../contexts/OrganisationContext';
import { useNotification } from '../../contexts/NotificationProvider';
import OrganisationService from '../../services/OrganisationService';
import { emailRegex } from '../../helpers/emailValidator';

const defaultFormValues = {
    firstName: '',
    lastName: '',
    email: '',
};

const InviteTeamMemberModal = ({ isModalOpen, teamMembers, setIsModalOpen, onSuccess }: InviteTeamMemberModalProps) => {
    const [formValues, setFormValues] = useState<UserInvite>(defaultFormValues);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { errors, register, reset, submit } = useValidation();
    const { formatMessage } = useIntl();
    const { defaultOrganisation } = useOrganisation();
    const { displayNotification } = useNotification();

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const closeModal = () => {
        setIsModalOpen(!isModalOpen);
        setFormValues(defaultFormValues);
        setIsSubmitting(false);
        reset();
    };

    const handleSubmit = () => {
        setIsSubmitting(true);

        OrganisationService.inviteTeamMembers(defaultOrganisation!.id, formValues)
            .then((response) => {
                onSuccess(response);
                displayNotification({
                    message: formatMessage(
                        { id: 'Profile.TeamMembersPage.InvitationNotificationMessage' },
                        { firstName: formValues.firstName }
                    ),
                });
                closeModal();
            })
            .catch((error) => {
                // TODO: Handler error message
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const validateEmail = (email: string) => {
        // Validate if email already exist.
        if (teamMembers.some((x) => x.email === email)) {
            return formatMessage({ id: 'CommonComponents.InviteTeamMemberModal.EmailAlreadyExist' });
        }
    };

    return (
        <Modal open={isModalOpen} onClose={closeModal}>
            <Grid container justifyContent="space-between" alignItems="flex-end" p={2}>
                <Typography variant="h5">
                    <FormattedMessage id="Profile.TeamMembersPage.InviteTeamMember" />
                </Typography>
                <IconButton size="small" aria-label="close" onClick={closeModal}>
                    <CloseIcon />
                </IconButton>
            </Grid>
            <Divider role="presentation" />
            <Grid container direction="column" p={2} id="invite-team-member-form" spacing={2}>
                <Grid item>
                    <TextField
                        fullWidth
                        name="firstName"
                        label={formatMessage({ id: 'Profile.TeamMembersPage.FirstName' })}
                        variant="outlined"
                        onChange={handleValueChange}
                        inputRef={register({
                            required: formatMessage({ id: 'CommonComponents.InviteTeamMemberModal.FirstNameRequired' }),
                        })}
                        error={Boolean(errors.firstName)}
                        helperText={errors.firstName?.message}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        name="lastName"
                        label={formatMessage({ id: 'Profile.TeamMembersPage.LastName' })}
                        variant="outlined"
                        onChange={handleValueChange}
                        inputRef={register({
                            required: formatMessage({ id: 'CommonComponents.InviteTeamMemberModal.LastNameRequired' }),
                        })}
                        error={Boolean(errors.lastName)}
                        helperText={errors.lastName?.message}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        name="email"
                        label={formatMessage({ id: 'Profile.TeamMembersPage.Email' })}
                        variant="outlined"
                        onChange={handleValueChange}
                        inputRef={register({
                            required: formatMessage({ id: 'CommonComponents.InviteTeamMemberModal.EmailRequired' }),
                            pattern: {
                                value: emailRegex,
                                message: formatMessage({
                                    id: 'CommonComponents.InviteTeamMemberModal.EnterValidEmailAddress',
                                }),
                            },
                            validate: validateEmail,
                        })}
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                    />
                </Grid>
            </Grid>
            <Divider role="presentation" />
            <Grid container justifyContent="flex-end" p={2}>
                <Button sx={{ mr: 1 }} variant="outlined" onClick={closeModal}>
                    <FormattedMessage id="Profile.TeamMembersPage.Cancel" />
                </Button>
                <Button
                    variant="contained"
                    form="invite-team-member-form"
                    disabled={isSubmitting}
                    onClick={submit(handleSubmit)}
                >
                    <FormattedMessage id="Profile.TeamMembersPage.SendInvite" />
                </Button>
            </Grid>
        </Modal>
    );
};

export interface InviteTeamMemberModalProps {
    isModalOpen: boolean;
    teamMembers: TeamMember[];
    onSuccess: (newTeamMember: TeamMember) => void;
    setIsModalOpen: (isOpen: boolean) => void;
}

export default InviteTeamMemberModal;
