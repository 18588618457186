import React, { useState } from 'react';
import { Button, Input, InputGroup, InputGroupAddon, Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const EposCredentialDisplay = (props) => {
    const buttonId = `btn-${props.id}`;

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const [tooltipText, setTooltipText] = useState('Copy to Clipboard');

    const [isValueShown, setIsValueShown] = useState(false);
    const showValueButtonText = isValueShown ? 'hide' : 'show';
    const toggleIsValueShown = () => {
        setIsValueShown(!isValueShown);
    };

    const onCopy = () => {
        const oldTooltipText = tooltipText;
        setTooltipText('Copied!');
        setTooltipOpen(true);

        setTimeout(() => {
            setTooltipText(oldTooltipText);
        }, 500);
    };

    return (
        <InputGroup className="credential-input-group">
            <div className="credential-input">
                <Input
                    autoComplete="off"
                    id={props.id}
                    type={isValueShown ? 'text' : 'password'}
                    value={props.value}
                    readOnly
                />

                <Button className="show-hide-button" onClick={toggleIsValueShown}>
                    {showValueButtonText}
                </Button>
            </div>

            <InputGroupAddon addonType="append" className="copy-button">
                <CopyToClipboard id={buttonId} text={props.value} onCopy={onCopy}>
                    <Button color="secondary" id={buttonId}>
                        <img src="/images/Copy-Dark.svg" alt="Copy to clipboard" />
                    </Button>
                </CopyToClipboard>

                <Tooltip placement="top" isOpen={tooltipOpen} target={buttonId} toggle={toggle}>
                    {tooltipText}
                </Tooltip>
            </InputGroupAddon>
        </InputGroup>
    );
};

EposCredentialDisplay.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default EposCredentialDisplay;
