import { v4 as uuidv4 } from 'uuid';

class AppStartingGuideSectionStep {
    constructor(step, type) {
        this.id = step ? step.id : 0;
        this.title = step ? step.title : '';
        this.description = step ? step.description : '';
        this.sectionType = step ? step.sectionType : type;
        this.uniqueIdentifier = uuidv4();
    }

    toJson() {
        return {
            Id: this.id,
            Title: this.title,
            Description: this.description,
            SectionType: this.sectionType,
        };
    }
}

export default AppStartingGuideSectionStep;
