import * as React from 'react';
import ProfileCircle from '../common/ProfileCircle';
import MomentHelper from '../../helpers/MomentHelper';
import { useNavigate } from 'react-router-dom';
import AppImageContainer from './AppImageContainer';
import App from '../../model/appManagement/App';
import { useIntl } from 'react-intl';

const AppManagementCard = (props: AppManagementCardProps) => {
    const navigate = useNavigate();
    const intl = useIntl();

    const getAppImage = () => {
        return <AppImageContainer application={props.app} />;
    };

    const getCreatedByDate = () => {
        const formattedDate = MomentHelper.newInstance(props.app.createdDate).format('ll');
        return (
            <span className="mr-2">
                <b>{`${intl.formatMessage({ id: 'Common.Created' })}`}&#58;</b>&nbsp;{`${formattedDate}`}
            </span>
        );
    };

    return (
        <div className="app-management-card" onClick={() => navigate(`/Apps/${props.app.appId}`)}>
            <div className="d-flex flex-row p-3 align-items-center created-by">
                {getCreatedByDate()}
                <div>
                    <ProfileCircle fullName={props.app.createdByUser} />
                </div>
            </div>
            <div className="icon-container justify-content-center d-flex">{getAppImage()}</div>
            <div className="w-100 p-3 m-0">
                <h4>{props.app.title}</h4>
            </div>
        </div>
    );
};

export interface AppManagementCardProps {
    app: App;
}

export default AppManagementCard;
