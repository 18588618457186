import React, { useEffect, useState } from 'react';
import InstallationTable from './InstallationTable';
import { ReactComponent as TestDiaryIcon } from '../../content/images/Add-Diary.svg';
import appInstallationStatus from '../../enums/appInstallationStatus';
import SelectList from '../common/SelectList';
import Paginator from '../common/Paginator';
import { Button } from 'reactstrap';
import { getPageSizes } from '../../helpers/pagingHelper';
import AppService from '../../services/AppService';
import { useIntl, FormattedMessage } from 'react-intl';

const AppInstallationPage = (props) => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(true);
    const [statusFilter, setStatusFilter] = useState();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [appInstallations, setAppInstallations] = useState([]);

    const appInstallationStatuses = [
        { key: '', value: intl.formatMessage({ id: 'AppManagement.AppInstallationsPage.AllStatus' }) },
        {
            key: appInstallationStatus.pending,
            value: intl.formatMessage({ id: 'AppManagement.AppInstallationsPage.PendingStatus' }),
        },
        {
            key: appInstallationStatus.installed,
            value: intl.formatMessage({ id: 'AppManagement.AppInstallationsPage.InstalledStatus' }),
        },
        {
            key: appInstallationStatus.uninstalled,
            value: intl.formatMessage({ id: 'AppManagement.AppInstallationsPage.UninstalledStatus' }),
        },
        {
            key: appInstallationStatus.revoked,
            value: intl.formatMessage({ id: 'AppManagement.AppInstallationsPage.RevokedStatus' }),
        },
    ];

    useEffect(() => {
        setIsLoading(true);
        AppService.getApplicationInstallations(props.application.id, statusFilter, page, pageSize)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    let _appInstallations = response.data.map((i) => {
                        return {
                            diaryId: i.providerId,
                            venueName: i.providerName,
                            type: {
                                actionBy: {
                                    name: i.modifiedByName,
                                    email: i.modifiedByEmail,
                                },
                                actionDate: i.modifiedDate,
                            },
                            status: i.installationStatus,
                            installationDate:
                                i.installationStatus === appInstallationStatus.installed ? i.modifiedDate : null,
                        };
                    });
                    setAppInstallations(_appInstallations);
                    if (totalPages !== response.totalPages) {
                        setTotalPages(response.totalPages);
                    }
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [props.application.id, statusFilter, page, pageSize, totalPages]);

    const onStatusFilterChange = (e) => {
        setPage(1);
        setStatusFilter(e.target.value);
    };

    const onPageSizeChange = (e) => {
        setPage(1);
        setPageSize(e.target.value);
    };

    const appNotLive = () => {
        return (
            <div className="row mt-5">
                <div className="col-md-6 offset-md-3 pt-2 align-items-center text-center">
                    <div className="installation-icon text-center">
                        <TestDiaryIcon />
                    </div>
                    <div className="row  m-3 p-2">
                        <p className="col-12">
                            <b>
                                <FormattedMessage id="AppManagement.AppInstallationsPage.AppNotLiveDescription" />
                            </b>
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const noDiariesInvited = () => {
        return (
            <div className="row mt-5">
                <div className="col-md-6 offset-md-3 pt-2 align-items-center text-center">
                    <div className="installation-icon text-center">
                        <TestDiaryIcon />
                    </div>
                    <div className="row  m-3 p-2">
                        <p className="col-12">
                            <b>
                                <FormattedMessage id="AppManagement.AppInstallationsPage.NoInstallationsDataLabel" />
                            </b>
                        </p>
                        <p className="col-12 mb-0">
                            <FormattedMessage id="AppManagement.AppInstallationsPage.NoInstallationsDescription" />
                        </p>
                    </div>
                    <div className="col-12 p-2">
                        <button className="btn btn-primary">
                            + <FormattedMessage id="AppManagement.AppInstallationsPage.InviteDiariesLabel" />
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    //Todo: add logic to check application status (Approved/Live vs No Diaries)
    const renderEmptyResult = props.application.status ? appNotLive : noDiariesInvited;

    return (
        <div className="installations-page">
            <h2>
                <FormattedMessage id="AppManagement.AppInstallationsPage.PageHeader" />
            </h2>
            <div className="row">
                <div className="col-6">
                    <p>
                        <FormattedMessage id="AppManagement.AppInstallationsPage.PageDescription" />
                    </p>
                </div>
                <div className="col-6 d-flex">
                    <div className="col-4 d-flex justify-content-end">
                        <SelectList
                            className=""
                            label="Filter by"
                            options={appInstallationStatuses}
                            onChange={onStatusFilterChange}
                        />
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <SelectList label="Page size" options={getPageSizes()} onChange={onPageSizeChange} />
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <Button color="primary" disabled>
                            + <FormattedMessage id="AppManagement.AppInstallationsPage.InviteDiariesLabel" />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="pt-3">
                        <InstallationTable
                            data={appInstallations}
                            renderEmptyResult={renderEmptyResult}
                            isLoading={isLoading}
                        />
                    </div>
                    {totalPages > 0 && <Paginator pages={totalPages} currentPage={page} handlePaginate={setPage} />}
                </div>
            </div>
        </div>
    );
};

export default AppInstallationPage;
