const appSubmissionRejectionReason = {
    MissingAppInformation: 1,
    RedirectUrlMissingFailing: 2,
    CertificationTestingFailed: 3,
    InsufficientVideoExplanation: 4,
    MissingWebhookSetup: 5,
    StagingEnvironmentRequired: 6,
};

export default appSubmissionRejectionReason;
