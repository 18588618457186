import * as React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SidebarMenuItem from '../../../model/layout/SidebarMenuItem';
import { FormattedMessage } from 'react-intl';

const SidebarItem = (props: SidebarSubmenuProps) => {
    const location = useLocation();

    const checkIsSubItemActive = (item: SidebarMenuItem) => {
        if (location.pathname === item.path) {
            return true;
        } else {
            for (let i = 0; i < (item.subItems || []).length; i++) {
                if (checkIsSubItemActive(item.subItems![i])) {
                    return true;
                }
            }
            return false;
        }
    };

    const [subNav, setSubNav] = useState(props.item.path === location.pathname || checkIsSubItemActive(props.item));

    const showSubNav = () => setSubNav(!subNav);

    const isLinkActive = (path: string) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <div key={props.key} className="dp-sidebar__category-list">
            <div className={`dp-sidebar__category-name ${isLinkActive(props.item.path)}`}>
                <Link to={props.item.path} onClick={showSubNav}>
                    <FormattedMessage id={props.item.title} />
                </Link>
                {props.item.subItems && (
                    <img
                        src="/images/Chevron.svg"
                        className={subNav ? 'rotate-180deg' : ''}
                        alt=""
                        onClick={showSubNav}
                    />
                )}
            </div>
            {subNav && props.item.subItems && (
                <ul className="dp-sidebar__articles">
                    {props.item.subItems.map((subItem, index) => {
                        return (
                            <li key={index}>
                                <Link
                                    to={subItem.path}
                                    className={`dp-sidebar__articles__item__name ${isLinkActive(subItem.path)}`}
                                >
                                    <FormattedMessage id={subItem.title} />
                                </Link>
                                {isLinkActive(subItem.path) && <ul className="dp-sidebar__submenu"></ul>}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export interface SidebarSubmenuProps {
    item: SidebarMenuItem;
    key: number;
}

export default SidebarItem;
