import * as React from 'react';
import * as Yup from 'yup';
import { useEffect, useState, Fragment } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl, FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import ConfirmationModal from '../../../components/common/ConfirmationModal';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Organisation from '../../../model/user/Organisation';
import OrganisationService from '../../../services/admin/OrganisationService';
import FormHelperText from '@mui/material/FormHelperText';
import { FormControl } from '@mui/material';
import TestDiaryService from '../../../services/admin/TestDiaryService';
import TestProvider from '../../../model/TestDiaries/TestProvider';
import { useNotification } from '../../../contexts/NotificationProvider';

interface LinkTestDiaryForm {
    providerId: number;
    organisationId: number;
}

interface LinkTestDiaryModalProps {
    isShowModal: boolean;
    onClose: () => void;
    onSuccess: () => void;
}

const LinkTestDiaryModal = ({ isShowModal, onClose, onSuccess }: LinkTestDiaryModalProps) => {
    const intl = useIntl();
    const [showConfirmLinkModal, setShowConfirmLinkModal] = useState(false);
    const [organisations, setOrganisations] = useState<Organisation[]>();
    const [providerDetails, setProviderDetails] = useState<TestProvider>();
    const [organisationDetails, setOrgansationDetails] = useState<Organisation | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { displayNotification } = useNotification();

    const linkTestDiarySchema = Yup.object<LinkTestDiaryForm>().shape({
        providerId: Yup.number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? undefined : value))
            .required(intl.formatMessage({ id: 'Admin.TestDiaries.ProviderIdRequiredMessage' })),
        organisationId: Yup.number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? undefined : value))
            .required(intl.formatMessage({ id: 'Admin.TestDiaries.OrganisationRequiredMessage' })),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<LinkTestDiaryForm>({ resolver: yupResolver(linkTestDiarySchema) });

    useEffect(() => {
        OrganisationService.getAllOrganisations(undefined, undefined, true).then((response) => {
            if (!('errorMessage' in response)) {
                setOrganisations(response.data);
            }
        });
    }, []);

    const verifyLinkTestDiaryPramaters = () => {
        setIsLoading(true);
        handleSubmit((data: LinkTestDiaryForm) => {
            try {
                TestDiaryService.verifyTestProvider(data.providerId, data.organisationId)
                    .then((response) => {
                        if (response.ok) {
                            setProviderDetails(response.data);
                            setShowConfirmLinkModal(true);
                        } else {
                            setError('providerId', { type: 'custom', message: response.data });
                        }
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } catch {
                setIsLoading(false);
            }
        })();
    };

    const confirmLinkTestDiary = () => {
        setIsLoading(true);
        handleSubmit((data: LinkTestDiaryForm) => {
            try {
                TestDiaryService.linkTestProviderToOrganisation(data.providerId, data.organisationId)
                    .then((response) => {
                        if (response.ok) {
                            displayNotification({
                                message: intl.formatMessage(
                                    { id: 'Admin.TestDiaries.DiaryLinkSuccessMessage' },
                                    { testDiary: providerDetails?.name }
                                ),
                            });

                            setShowConfirmLinkModal(false);
                            onSuccess();
                        }
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } catch (e) {
                setIsLoading(false);
            }
        })();
    };

    const handleOrganisationChangeEvent = (e: any) => {
        if (e.target.value) {
            let organisation = organisations?.find((x) => x.id === e.target.value);
            setOrgansationDetails(organisation);
        }
    };

    return (
        <Fragment>
            {!showConfirmLinkModal && (
                <ConfirmationModal
                    header={intl.formatMessage({ id: 'Admin.TestDiaries.LinkTestDiaryModalHeader' })}
                    isOpen={isShowModal}
                    onClose={onClose}
                    onAccept={verifyLinkTestDiaryPramaters}
                    firstButtonText={intl.formatMessage({ id: 'Admin.TestDiaries.LinkDiaryButtonText' })}
                    isLoading={isLoading}
                >
                    <Stack spacing={2}>
                        <FormGroup>
                            <InputLabel htmlFor="provider-id">
                                <b>
                                    <FormattedMessage id="Admin.TestDiaries.ProviderIdLabel" />
                                </b>
                                <br />
                                <span>
                                    <FormattedMessage id="Admin.TestDiaries.ProviderIdLabelInfo" />
                                </span>
                            </InputLabel>
                            <TextField
                                id="provider-id"
                                type="number"
                                {...register('providerId')}
                                variant="outlined"
                                error={!!errors.providerId || !!errors.root?.message}
                                helperText={errors.providerId?.message}
                                data-testid="provider-id"
                            />
                        </FormGroup>
                        <FormGroup>
                            <InputLabel id="organisation-id-label">
                                <b>
                                    <FormattedMessage id="Admin.TestDiaries.OrganisationLabel" />
                                </b>
                                <br />
                                <span>
                                    <FormattedMessage id="Admin.TestDiaries.OrganisationLabelInfo" />
                                </span>
                            </InputLabel>
                            <FormControl error={errors.organisationId !== undefined}>
                                <Select
                                    labelId="organisation-id-label"
                                    id="organisation-id"
                                    value={organisationDetails?.id || ''}
                                    {...register('organisationId', {
                                        onChange: handleOrganisationChangeEvent,
                                    })}
                                >
                                    {organisations?.map((organisation) => (
                                        <MenuItem key={organisation.id} value={organisation.id}>
                                            {organisation.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.organisationId && (
                                    <FormHelperText>{errors.organisationId.message}</FormHelperText>
                                )}
                            </FormControl>
                        </FormGroup>
                    </Stack>
                </ConfirmationModal>
            )}
            <ConfirmationModal
                header={intl.formatMessage({ id: 'Admin.TestDiaries.ConfirmLinkModalHeader' })}
                isOpen={showConfirmLinkModal}
                onClose={() => {
                    setShowConfirmLinkModal(false);
                }}
                onAccept={confirmLinkTestDiary}
                firstButtonText={intl.formatMessage({ id: 'Admin.TestDiaries.ConfirmLinkButtonText' })}
                isLoading={isLoading}
                cancelButtonText={intl.formatMessage({ id: 'Admin.TestDiaries.GoBackButtonText' })}
            >
                <FormattedMessage
                    id="Admin.TestDiaries.LinkDiarySuccessfullMessage"
                    values={{
                        b: (chunk) => <strong> {chunk}</strong>,
                        testDiaryName: providerDetails?.name,
                        testDiaryId: providerDetails?.remoteProviderId,
                        organisationName: organisationDetails?.name,
                    }}
                />
            </ConfirmationModal>
        </Fragment>
    );
};

export default LinkTestDiaryModal;
