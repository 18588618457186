import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ClientIdCard from './ClientIdCard';
import ClientSecretCard from './ClientSecretCard';
import AppAuthenticationSettings from '../../../../model/appManagement/AppAuthenticationSettings';

const OAuthCredentialsPage = (props: OAuthCredentialsPageProps) => {
    return (
        <React.Fragment>
            <div>
                <h3 className="mb-3">
                    <FormattedMessage id="AppManagement.AppDetails.Credentials" />
                </h3>
                <p className="mb-2">
                    <FormattedMessage id="AppManagement.AppDetails.CredentialsDescription" />
                </p>
            </div>
            <ClientIdCard clientId={props.appAuthSettings.clientId} />
            <ClientSecretCard appAuthSettings={props.appAuthSettings} />
        </React.Fragment>
    );
};

export interface OAuthCredentialsPageProps {
    appAuthSettings: AppAuthenticationSettings;
}

export default OAuthCredentialsPage;
