import * as React from 'react';
import { Fragment, useState } from 'react';
import { ReactComponent as CopyIcon } from '../../content/icons/Icon-Copy.svg';
import { ReactComponent as CopyIconWhite } from '../../content/icons/Icon-Copy-White.svg';
import { Popover, PopoverBody } from 'reactstrap';

const CopyToClipboard = ({ text, id, isWhite }: CopyToClipboardProps) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const copyToClipboard = (e: React.MouseEvent) => {
        navigator.clipboard.writeText(text);
        setIsTooltipOpen(true);
        e.stopPropagation();
    };

    const closeTooltip = () => {
        if (isTooltipOpen) setIsTooltipOpen(false);
    };

    return (
        <Fragment>
            <i id={`copy-to-clipboard-${id}`} onClick={(e) => copyToClipboard(e)} onMouseLeave={closeTooltip}>
                {isWhite ? <CopyIconWhite /> : <CopyIcon />}
            </i>
            <Popover
                placement="top"
                isOpen={isTooltipOpen}
                target={`copy-to-clipboard-${id}`}
                toggle={() => setIsTooltipOpen(!isTooltipOpen)}
            >
                <PopoverBody>Copied</PopoverBody>
            </Popover>
        </Fragment>
    );
};

export interface CopyToClipboardProps {
    text: string;
    id: string;
    isWhite?: boolean;
}

export default CopyToClipboard;
