import React, { useState, useEffect, Fragment } from 'react';
import { Alert, Container, Form, FormGroup, Label } from 'reactstrap';
import ValidationInput from '../common/ValidationInput';
import useValidation from '../../hooks/useValidation';
import ProgressPage from '../common/ProgressPage';
import ProgressBar from '../common/ProgressBar';
import { useOrganisation } from '../../contexts/OrganisationContext';
import OrganisationService from '../../services/OrganisationService';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import { useOnboarding } from '../../contexts/OnboardingContext';
import userOnboardingStage from '../../enums/userOnboardingStage';
import AccountService from '../../services/AccountService';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import { useIntl } from 'react-intl';
import useTrackPage from '../../hooks/useTrackPage';

function OrganisationSetupPage() {
    const intl = useIntl();

    const { authInfo } = useAuthentication();
    const { defaultOrganisation } = useOrganisation();
    const formValidation = useValidation();
    const { setOnboardingStage } = useOnboarding();

    const [error, setError] = useState('');
    const [registeredMembers, setRegisteredMembers] = useState();

    const [memberDetails, setMemberDetails] = useState(setEmptyValuesForMembers());

    useTrackPage('Hub Team Members Invite page');

    const goToAccountDetailsPage = () => {
        AccountService.setUserOnboardingStage(authInfo.username, userOnboardingStage.AccountDetails)
            .then(() => {
                AnalyticsHelper.trackClick('Web teamInvite skipStep');
                setOnboardingStage(userOnboardingStage.AccountDetails);
            })
            .catch((error) => {
                setError(error);
            });
    };

    function setEmptyValuesForMembers() {
        const emptyMembers = [];
        for (let i = 0; i < 5; i++) {
            emptyMembers.push({
                id: i,
                memberName: '',
                email: '',
            });
        }
        return emptyMembers;
    }

    useEffect(() => {
        if (defaultOrganisation) {
            OrganisationService.getTeamMembers(defaultOrganisation.id).then((data) => {
                if (data.errorMessage) {
                    setError(data.errorMessage);
                } else {
                    setRegisteredMembers(data);
                }
            });
        }
    }, [defaultOrganisation]);

    const onAddTeamMembersChange = (e, i, key) => {
        updateMember(key, i, e);
    };

    const submitAddTeamMembers = () => {
        if (validateNoEmptyFields()) {
            let users = configureMemberDetails();
            OrganisationService.inviteTeamMembers(defaultOrganisation.id, users).then((result) => {
                if ('errorMessage' in result) {
                    setError(result.errorMessage);
                } else {
                    const memberProperties = [];
                    for (let i = 0; i < users.length; i++) {
                        const fullName = users[i].firstName + ' ' + users[i].lastName;
                        memberProperties.push({ teammateName: fullName, teammateEmail: users[i].email });
                    }
                    AnalyticsHelper.trackClickWithProperties('Web teamInvite SendInvite', memberProperties);
                    goToAccountDetailsPage(users.length);
                }
            });
        }
    };

    const configureMemberDetails = () => {
        let populatedMembers = memberDetails.filter((v) => {
            return v.memberName !== '' && v.email !== '';
        });

        let userDtos = populatedMembers.map((member) => {
            let nameArray = member.memberName.split(' ');
            let firstName = nameArray.length > 0 ? nameArray[0] : '';
            let lastName = nameArray.length > 1 ? nameArray[nameArray.length - 1] : '';
            return {
                firstName,
                lastName,
                email: member.email.trim(),
            };
        });

        return userDtos;
    };

    const allMembersEmpty = () => {
        return memberDetails.every((v) => {
            return v.memberName === '' && v.email === '';
        });
    };

    const validateNoEmptyFields = () => {
        if (allMembersEmpty()) {
            formValidation.setError('name0', { message: 'At least one name is required' }, { shouldFocus: true });
            formValidation.setError('email0', { message: 'At least one email is required' }, { shouldFocus: true });
            return false;
        }
        return true;
    };

    const validateTextPair = (value, pairedName, index) => {
        if (!value && formValidation.getValues(pairedName)) {
            return pairedName.indexOf('email') > -1 ? 'Name is required.' : 'Email address is required.';
        }
        if (pairedName.indexOf('email') === -1) {
            let userExist = registeredMembers.find((x) => {
                return x.email === value;
            });
            if (userExist) {
                return 'Email address is already part of this organisation';
            }
            return true;
        }
    };

    const updateMember = (key, i, val) => {
        if (key.indexOf('memberName') > -1) {
            setMemberDetails(memberDetails.map((item) => (item.id === i ? { ...item, memberName: val } : item)));
        } else {
            setMemberDetails(memberDetails.map((item) => (item.id === i ? { ...item, email: val } : item)));
        }
    };

    const progressBar = () => {
        return (
            <ProgressPage
                pageTitle={intl.formatMessage({ id: 'Common.GetStartedMessage' })}
                progressBar={
                    <ProgressBar
                        createAccountStatus={'completed'}
                        organisationSetupStatus={'incomplete'}
                        accountDetailsStatus={'notStarted'}
                    />
                }
            />
        );
    };

    const renderError = () => {
        if (error) {
            return (
                <Alert className="account-form__error">
                    <img src="/images/Alert-white.svg" alt="" />
                    {error}
                </Alert>
            );
        }
    };

    const listTeamInputs = memberDetails.map((member, i) => (
        <div className="row" key={i}>
            <div className="col">
                <FormGroup className="account-details-form-group">
                    <Label for={'name' + i} id={'name' + i}>
                        Name
                    </Label>
                    <ValidationInput
                        type="text"
                        name={'name' + i}
                        value={member.memberName}
                        onChange={(e) => onAddTeamMembersChange(e.currentTarget.value, i, 'memberName' + i)}
                        innerRef={formValidation.register({
                            validate: (value) => validateTextPair(value, 'email' + i, i),
                        })}
                        errors={formValidation.errors}
                        ariaLabelledBy={'Name'}
                        ariaRequired
                    />
                </FormGroup>
            </div>
            <div className="col">
                <FormGroup className="account-details-form-group">
                    <Label for={'email' + i} id={'email' + i}>
                        Email address
                    </Label>
                    <ValidationInput
                        type="text"
                        name={'email' + i}
                        value={memberDetails[i].email}
                        onChange={(e) => onAddTeamMembersChange(e.currentTarget.value, i, 'email' + i)}
                        innerRef={formValidation.register({
                            validate: (value) => validateTextPair(value, 'name' + i, i),
                            pattern: {
                                value: /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/gi, // eslint-disable-line no-control-regex
                                message: 'Please enter a valid email address',
                            },
                        })}
                        errors={formValidation.errors}
                        ariaLabelledBy={'email'}
                        ariaRequired
                    />
                </FormGroup>
            </div>
        </div>
    ));

    const addTeamMembersForm = () => {
        return (
            <Fragment>
                <div>{progressBar()}</div>
                <div className="form-wrapper">
                    <div className="form-content">
                        {renderError()}
                        <div className="intro-header">
                            <div className="heading text-uppercase bold-text">INVITE TEAM MEMBERS</div>
                            <p>Enter your team's information</p>
                        </div>
                        <div className="mt-3 row">
                            <div className="col-md-12">
                                <Form onSubmit={formValidation.submit(submitAddTeamMembers)}>
                                    <div> {listTeamInputs} </div>
                                    <div className="row">
                                        <div className="col-md-6 offset-md-6">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <FormGroup>
                                                        <input
                                                            className="btn-primary btn w-100"
                                                            value="Skip this step"
                                                            onClick={() => goToAccountDetailsPage(0)}
                                                            type="button"
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6">
                                                    <FormGroup>
                                                        <input
                                                            className="btn-primary btn w-100"
                                                            disabled={formValidation.submitDisabled}
                                                            value="Send invite"
                                                            type="submit"
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <p className="small">You can always invite your team members later</p>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    return (
        <Container className="account-page">
            <div className="flex-container-center">
                <div className="container">{addTeamMembersForm()}</div>
            </div>
        </Container>
    );
}

export default OrganisationSetupPage;
