const appSubmissionStatus = {
    notSubmitted: 'NotSubmitted',
    submitted: 'Submitted',
    approved: 'Approved',
    rejected: 'Rejected',
    listingSubmitted: 'ListingSubmitted',
    listingApproved: 'ListingApproved',
    listingRejected: 'ListingRejected',
    listingLive: 'ListingLive',
    actionsRequired: 'ActionsRequired',
    accountClosed: 'AccountClosed',
};

export default appSubmissionStatus;
