import * as React from 'react';
import { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Container, Form, FormGroup } from 'reactstrap';
import { ReactComponent as ResIcon } from '../../content/images/RD_hub_logo.svg';
import { ReactComponent as AlertIcon } from '../../content/icons/Icon-Alert.svg';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import config from '../../config/app';
import SignInService from '../../services/SignInService';
import { useIntl, FormattedMessage } from 'react-intl';
import useQueryParam from '../../hooks/useQueryParam';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

interface ForgotPasswordForm {
    email: string;
}

const ForgotPasswordPage = () => {
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [hasError, setHasError] = useState(false);
    const intl = useIntl();

    const forgotPasswordFormSchema = Yup.object<ForgotPasswordForm>().shape({
        email: Yup.string()
            .required(intl.formatMessage({ id: 'Common.FieldIsRequired' }))
            .email(intl.formatMessage({ id: 'Login.LoginPage.EmailValidationMessage' })),
    });

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm({ resolver: yupResolver(forgotPasswordFormSchema) });

    const resetTokenExpired = useQueryParam('expiredResetToken');

    useEffect(() => {
        // Add error if redirected from expired reset token
        if (resetTokenExpired) {
            setErrorMessage(intl.formatMessage({ id: 'Login.LoginPage.ResetTokenExpired' }));
            setHasError(true);
        }
    }, [resetTokenExpired, intl]);

    const submitForgotPassword = (data: ForgotPasswordForm) => {
        setIsSubmitting(true);
        SignInService.forgotPassword(data.email)
            .then((response) => {
                if (response !== true) {
                    setErrorMessage(response.errorMessage);
                    setHasError(true);
                } else {
                    setSubmitted(true);
                    setHasError(false);
                    AnalyticsHelper.trackClickWithProperties('Web forgottenPassword Submit', { email: data.email });
                }
            })
            .catch((err) => {
                setErrorMessage(err);
                setHasError(true);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const toLoginPage = () => {
        window.location.href = config.loginPath;
    };

    return (
        <Container className="account-page container">
            <div>
                <div className="flex-container-center">
                    <div className="account-page__body__logo">
                        <ResIcon className="brand-svg" onClick={toLoginPage} />
                    </div>
                    <div className="account-form--fixed-width">
                        <Form className="account-form__content" onSubmit={handleSubmit(submitForgotPassword)}>
                            <Fragment>
                                <div className="account-form__title">
                                    {!submitted && <FormattedMessage id="Login.LoginPage.ResetYourPassword" />}
                                    {submitted && <FormattedMessage id="Login.LoginPage.PasswordResetSuccessTitle" />}
                                </div>
                                {submitted && (
                                    <>
                                        <FormGroup className="form-group--double-margin">
                                            <div className="text-left mb-4">
                                                {intl.formatMessage(
                                                    { id: 'Login.LoginPage.PasswordResetSuccessText' },
                                                    { emailAddress: getValues('email') }
                                                )}
                                            </div>
                                        </FormGroup>
                                        <FormGroup className="form-group--double-margin">
                                            <Link
                                                to={config.loginPath}
                                                onClick={() =>
                                                    AnalyticsHelper.trackClick('Web forgottenPassword loginScreen')
                                                }
                                                className="login-button btn btn-hub btn-hub--blue-outline col"
                                            >
                                                <FormattedMessage id="Login.LoginPage.BackToLogin" />
                                            </Link>
                                        </FormGroup>
                                    </>
                                )}
                                {hasError && (
                                    <Alert className="account-form__error">
                                        <AlertIcon />
                                        {errorMessage}
                                    </Alert>
                                )}
                                {!submitted && (
                                    <>
                                        <FormGroup className={hasError ? '' : 'form-group--double-margin'}>
                                            <div className="text-left mb-4">
                                                <FormattedMessage id="Login.LoginPage.EnterEmailAddress" />
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <TextField
                                                fullWidth
                                                label={intl.formatMessage({ id: 'Common.EmailAddress' })}
                                                placeholder={intl.formatMessage({
                                                    id: 'Login.LoginPage.EmailPlaceholder',
                                                })}
                                                {...register('email')}
                                                variant="outlined"
                                                error={!!errors.email}
                                                helperText={errors.email?.message}
                                                data-testid="email"
                                            />
                                        </FormGroup>
                                        <FormGroup className="form-group--double-margin">
                                            <input
                                                type="submit"
                                                value={intl.formatMessage({ id: 'Login.LoginPage.ResetPassword' })}
                                                className="login-button btn btn-hub btn-hub--blue-outline col"
                                                data-testid="forgot-password-form-submit-button"
                                                disabled={isSubmitting}
                                            />
                                        </FormGroup>
                                    </>
                                )}
                            </Fragment>
                        </Form>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default ForgotPasswordPage;
