import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Label, Form, FormGroup, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ValidationInput from '../common/ValidationInput';
import useValidation from '../../hooks/useValidation';

function FormModal({ header, description, onAccept, isOpen, onClose, formFields, firstButtonText, customValidations }) {
    const formValidation = useValidation();
    const [formData, setFormData] = useState([]);

    const addValue = (val, keyName, i) => {
        const thisFormData = [...formData];
        if (thisFormData.length === 0) {
            setFormData([...formData, { [keyName]: val }]);
        } else {
            thisFormData[i] = { [keyName]: val };
            setFormData(thisFormData);
        }
    };

    useEffect(() => {}, []);

    const handleSubmit = () => {
        onAccept(formData);
    };
    const customValidate = async (value, textFieldType, i, fieldName) => {
        if (formFields[i].hasCustomValidation) {
            let validationMessage = null;
            await customValidations(value, i, fieldName).then((response) => {
                if (response.ok && !response.data) {
                    validationMessage = 'Please enter a valid ' + formFields[i].label;
                }
            });
            if (validationMessage === null) {
                return true;
            } else {
                return validationMessage;
            }
        }
    };
    const cancelButton = () => {
        return (
            <Button color="secondary" onClick={onClose}>
                Cancel
            </Button>
        );
    };
    const formInputs = formFields.map((formField, i) => (
        <div className="row" key={i}>
            <div className="col">
                <FormGroup className="account-details-form-group">
                    <Label for={formField.label.replace(/\s/g, '')} id={formField.label.replace(/\s/g, '')}>
                        {formField.label}
                    </Label>
                    <ValidationInput
                        type={formField.textFieldType}
                        name={formField.label.replace(/\s/g, '')}
                        value={formData[i] === undefined ? '' : formData[i][formField.label.replace(/\s/g, '')]}
                        onChange={(e) => addValue(e.currentTarget.value, formField.label.replace(/\s/g, ''), i)}
                        innerRef={formValidation.register({
                            validate: (value) =>
                                customValidate(value, formField.textFieldType, i, formField.label.replace(/\s/g, '')),
                            required: formField.isRequired ? 'This field is required' : '',
                        })}
                        errors={formValidation.errors}
                        ariaLabelledBy={formField.label.replace(/\s/g, '') + i}
                        ariaRequired
                    />
                </FormGroup>
            </div>
        </div>
    ));

    return (
        <Modal className="confirmation-modal" centered isOpen={isOpen} toggle={onClose}>
            <ModalHeader className="border-bottom-0" toggle={onClose}>
                {header && <span>{header}</span>}
            </ModalHeader>
            <Form onSubmit={formValidation.submit(handleSubmit)}>
                <ModalBody>
                    <p>{description}</p>
                    {formInputs}
                </ModalBody>
                <ModalFooter>
                    {cancelButton()}
                    <Button color="primary" disabled={formValidation.submitDisabled} type="submit">
                        {firstButtonText == null ? 'Confirm' : firstButtonText}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

FormModal.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    description: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onAccept: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    firstButtonText: PropTypes.string,
    customValidations: PropTypes.func,
    formFields: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            textFieldType: PropTypes.string.isRequired,
            formFieldValue: PropTypes.string,
            isRequired: PropTypes.bool,
            hasCustomValidation: PropTypes.bool,
        })
    ).isRequired,
};

export default FormModal;
