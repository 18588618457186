import React, { Fragment, useEffect, useState } from 'react';
import ConsumerApiKeyService from '../../services/ConsumerApiKeyService';
import EposCredentialService from '../../services/EposCredentialService';
import ConsumerApiSection from './ConsumerApiSection';
import EposCredentialSection from './EposCredentialSection';
import LoadingSpinner from '../common/LoadingSpinner';
import Sidebar from '../layout/Sidebar';
import Toggle from '../common/Toggle';
import { UncontrolledAlert } from 'reactstrap';
import config from '../../config/app';

const menuItems = [
    {
        title: 'Authentication',
        subItems: [
            {
                title: 'Consumer API Keys',
            },
            {
                title: 'EPOS Credentials',
            },
        ],
    },
];

const AuthenticationPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isApiError, setIsApiError] = useState(false);

    const [isTestEnvironment, setIsTestEnvironment] = useState(true);
    const activeEnvironmentString = isTestEnvironment ? config.testDisplayName : config.prodDisplayName;
    const inactiveEnvironmentString = !isTestEnvironment ? config.testDisplayName : config.prodDisplayName;

    const [apiKey, setApiKey] = useState({
        primaryToken: '',
        secondaryToken: '',
    });

    const [eposCredentials, setEposCredentials] = useState({
        consumerKey: '',
        consumerSecret: '',
        accessibleProviders: [],
    });

    useEffect(() => {
        ConsumerApiKeyService.getApiKeys(isTestEnvironment)
            .then((data) => {
                setApiKey(data);
                setIsApiError(false);
                setIsLoading(false);
            })
            .catch(() => {
                setIsApiError(true);
                setIsLoading(false);
            });

        EposCredentialService.getCredentials(isTestEnvironment)
            .then((data) => {
                setEposCredentials(data);
                setIsApiError(false);
                setIsLoading(false);
            })
            .catch(() => {
                setIsApiError(true);
                setIsLoading(false);
            });
    }, [isTestEnvironment]);

    if (isApiError) {
        return (
            <UncontrolledAlert className="error-bar" color="danger">
                Error!
            </UncontrolledAlert>
        );
    }

    return (
        <>
            <div className="container-fluid settings-page">
                <div className="row flex-xl-nowrap">
                    <div className="sidebar-column col-md-3 col-xl-2 p-0 d-none d-md-block">
                        <Sidebar menuItems={menuItems} />
                    </div>
                    <main className="offset-md-3 col-md-9 offset-xl-2 col-xl-10 content">
                        {isLoading ? (
                            <LoadingSpinner loadingText="Loading..." />
                        ) : (
                            <Fragment>
                                <h1>Authentication</h1>
                                <Toggle
                                    label={`Viewing ${activeEnvironmentString} API keys, toggle to view${' '}
                  ${inactiveEnvironmentString} API keys`}
                                    checked={isTestEnvironment}
                                    handleChange={setIsTestEnvironment}
                                    checkedLabel="Test"
                                    uncheckedLabel="Live"
                                />
                                <hr />

                                <ConsumerApiSection
                                    isTestEnvironment={isTestEnvironment}
                                    activeEnvironmentString={activeEnvironmentString}
                                    apiKey={apiKey}
                                    setApiKey={setApiKey}
                                />
                                <hr />

                                <EposCredentialSection
                                    activeEnvironmentString={activeEnvironmentString}
                                    isTestEnvironment={isTestEnvironment}
                                    eposCredentials={eposCredentials}
                                    setEposCredentials={setEposCredentials}
                                />
                            </Fragment>
                        )}
                    </main>
                </div>
            </div>
        </>
    );
};

export default AuthenticationPage;
