import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Label, FormGroup, Input, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import appSubmissionStatus from '../../../enums/appSubmissionStatus';
import appSubmissionRejectionReasons from '../../../enums/appSubmissionRejectionReasons';
import RadioButton from '../../common/RadioButton';
import AppService from '../../../services/admin/AppService';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { getAppSubmissionRejectionReasonLabel } from './common.js';
import { useIntl } from 'react-intl';

function AppSubmissionApprovalModal(props) {
    const intl = useIntl();

    const reasonsDropDownInitial = intl.formatMessage({ id: 'Admin.PleaseSelectReason' });

    const [rejectionReason, setRejectionReason] = useState(reasonsDropDownInitial);

    const [appSubmissionApproval, setAppSubmissionApproval] = useState({
        appId: props.application.id,
        submissionStatus: props.application?.submissionStatus || appSubmissionStatus.approved,
        submissionRejectionReason: props.latestAppSubmissionLog?.submissionRejectionReason
            ? props.latestAppSubmissionLog?.submissionRejectionReason
            : reasonsDropDownInitial,
    });
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const setFieldValueByFieldName = (fieldName, value) => {
        const data = { ...appSubmissionApproval, [fieldName]: value };

        const reason =
            fieldName === 'submissionRejectionReason'
                ? getAppSubmissionRejectionReasonLabel(value, props.submissionRejectionReasons)
                : reasonsDropDownInitial;
        setRejectionReason(reason);
        setAppSubmissionApproval(data);
    };

    useEffect(() => {
        if (
            props.latestAppSubmissionLog?.submissionStatus !== appSubmissionStatus.approved &&
            props.latestAppSubmissionLog?.submissionRejectionReason
        )
            setRejectionReason(
                getAppSubmissionRejectionReasonLabel(
                    appSubmissionRejectionReasons[props.latestAppSubmissionLog?.submissionRejectionReason],
                    props.submissionRejectionReasons
                )
            );
        else setRejectionReason(reasonsDropDownInitial);
    }, [props, reasonsDropDownInitial]);

    useEffect(() => {
        if (appSubmissionApproval.submissionStatus === appSubmissionStatus.approved) {
            setRejectionReason(reasonsDropDownInitial);
        } else {
            props.latestAppSubmissionLog?.submissionRejectionReason
                ? setRejectionReason(
                      getAppSubmissionRejectionReasonLabel(
                          appSubmissionRejectionReasons[props.latestAppSubmissionLog?.submissionRejectionReason],
                          props.submissionRejectionReasons
                      )
                  )
                : setRejectionReason(reasonsDropDownInitial);
        }
    }, [appSubmissionApproval.submissionStatus, reasonsDropDownInitial, props]);

    const setFieldValue = (e) => {
        const data = { ...appSubmissionApproval, [e.currentTarget.name]: e.currentTarget.value };
        setAppSubmissionApproval(data);
    };

    const updateAppSubmission = () => {
        AppService.createAppSubmissionApproval(props.application.id, appSubmissionApproval)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    if (props.onConfirm) {
                        props.onConfirm(response);
                    }
                }
            })
            .finally(() => {
                props.toggle();
            });
    };

    return (
        <Modal centered size="lg" autoFocus={true} isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader className="border-bottom-0" toggle={props.toggle}>
                Submission approval
            </ModalHeader>
            <ModalBody>
                <p>Developers will be informed of the approval status and your comments below:</p>
                <br />
                <FormGroup className="mb-2">
                    <Label className="d-block">Approval status</Label>
                    <RadioButton
                        className="d-inline mb-3 mr-5 "
                        id="appovalStatus-approve"
                        name="submissionStatus"
                        value={appSubmissionStatus.approved}
                        onChange={(value) => setFieldValueByFieldName('submissionStatus', value)}
                        description="Approve"
                        checked={appSubmissionApproval?.submissionStatus === appSubmissionStatus.approved}
                    />
                    <RadioButton
                        className="d-inline mb-3"
                        id="appovalStatus-reject"
                        name="submissionStatus"
                        value={appSubmissionStatus.rejected}
                        onChange={(value) => setFieldValueByFieldName('submissionStatus', value)}
                        description="Reject"
                        checked={appSubmissionApproval?.submissionStatus === appSubmissionStatus.rejected}
                    />
                </FormGroup>
                <br />
                {appSubmissionApproval?.submissionStatus === appSubmissionStatus.rejected && (
                    <FormGroup className="mb-2">
                        <Label for="remarks">Select rejection reason</Label>
                        <Dropdown className="reject-reasons-dropdown" isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle caret>{rejectionReason}</DropdownToggle>
                            <DropdownMenu>
                                {props.submissionRejectionReasons.map((reason, index) => (
                                    <DropdownItem
                                        key={index}
                                        onClick={() =>
                                            setFieldValueByFieldName('submissionRejectionReason', reason.value)
                                        }
                                        value={reason.value}
                                    >
                                        {reason.label}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </FormGroup>
                )}
                <FormGroup>
                    <Label for="comments">Comments</Label>
                    <Input
                        id="comments"
                        name="comments"
                        type="textarea"
                        value={appSubmissionApproval.comments || ''}
                        rows="4"
                        onChange={setFieldValue}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.toggle}>Cancel</Button>
                <Button color="primary" onClick={updateAppSubmission}>
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>
    );
}

AppSubmissionApprovalModal.propTypes = {
    application: PropTypes.object.isRequired,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    onConfirm: PropTypes.func,
    submissionRejectionReasons: PropTypes.arrayOf(PropTypes.object),
    latestAppSubmissionLog: PropTypes.arrayOf(PropTypes.object),
};

export default AppSubmissionApprovalModal;
