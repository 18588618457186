/* eslint-disable */
import { api } from '../helpers/ApiHelper';
import environment from '../enums/environment';
import ErrorMessage from '../model/services/ErrorMessage';
import ConsumerApiKeys from '../model/consumerApiKeys/ConsumerApiKeys';

let keys: ConsumerApiKeys | undefined = undefined;

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const getApiKeys = async (isTestEnvironment: boolean) => {
    if (keys === undefined) {
        let response = await api.get<ConsumerApiKeys, string>('ConsumerApiKeys').then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
        if ('errorMessage' in response) {
            throw response.errorMessage;
        }
        keys = response;
    }
    return isTestEnvironment ? keys.test : keys.production;
};

const regenerateApiKey = async (isTestEnvironment: boolean, name: string) => {
    const env = isTestEnvironment ? environment.test : environment.production;
    return await api
        .post<string, string>(`ConsumerApiKeys/regenerate?accessTokenType=${name}&environment=${env}`)
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

export default {
    getApiKeys,
    regenerateApiKey,
};
