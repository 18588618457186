import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML, convertFromHTML } from 'draft-convert';

function RichTextEditor(props) {
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(convertFromHTML(props.content)));

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    };
    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        props.setEditorState(currentContentAsHTML);
    };

    return (
        <Fragment>
            <Editor
                placeholder={props.placeholder}
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                    options: ['inline', 'list', 'link'],
                    inline: { options: ['bold', 'italic'] },
                    list: { options: ['ordered', 'unordered'] },
                    link: { options: ['link'] },
                }}
            />
        </Fragment>
    );
}

RichTextEditor.propTypes = {
    placeholder: PropTypes.string,
    setEditorState: PropTypes.func.isRequired,
    content: PropTypes.string.isRequired,
};

export default RichTextEditor;
