import React, { useState, useCallback, useEffect } from 'react';
import { Button, Input, InputGroup, InputGroupAddon, FormGroup, Col, Label } from 'reactstrap';
import CopyToClipboard from '../common/CopyToClipboard';
import Tag from '../common/Tag';
import TogglePasswordVisibility from '../common/TogglePasswordVisibility';
import { ReactComponent as OpenLinkIcon } from '../../content/icons/Open-Link.svg';
import appSubmissionStatus from '../../enums/appSubmissionStatus';
import tagColour from '../../enums/tagColour';
import AppService from '../../services/AppService';
import textDisplayHelper from '../../helpers/TextDisplayHelper';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import { FormattedMessage, useIntl } from 'react-intl';

function AppAuthentication(props) {
    const { authInfo } = useAuthentication();
    const [inputType, setInputType] = useState({ clientSecret: 'password', accessSecret: 'password' });
    const [authenticationSettings, setAuthenticationSettings] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [isReadOnly] = useState(authInfo.isAdmin);
    const intl = useIntl();

    useEffect(() => {
        setIsLoading(true);
        AppService.getApplicationAuthenticationSettings(props.application.id, props.isTestEnvironment)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    setAuthenticationSettings(response);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [props.isTestEnvironment, props.application.id]);

    const toggleInputType = useCallback(
        (fieldName, value) => {
            let newInputType = {
                ...inputType,
                [fieldName]: value,
            };
            setInputType(newInputType);
        },
        [inputType]
    );

    const toggleEditing = useCallback(() => {
        setIsEditing(!isEditing);
    }, [isEditing]);

    const saveAuthenticationSettings = () => {
        AppService.saveApplicationAuthenticationSettings(props.application.id, authenticationSettings).then(
            (response) => {
                console.log(response);
            }
        );
    };

    const onValueChange = (e) => {
        let tempAuthSettings = {
            ...authenticationSettings,
            [e.target.name]: e.target.value,
        };
        setAuthenticationSettings(tempAuthSettings);
    };

    return (
        !isLoading && (
            <div className="authentication-page">
                <h1>
                    <FormattedMessage id="AppManagement.Auth.PageHeader" />
                </h1>
                <h6 className="mb-5">
                    <FormattedMessage id="AppManagement.Auth.PageDescription" />
                </h6>
                <Label>
                    <h5>
                        <FormattedMessage id="AppManagement.Auth.AppCredential" />
                    </h5>
                </Label>

                <FormGroup row>
                    <Label sm={4}>
                        <h6>
                            <FormattedMessage id="AppManagement.Auth.ClientId" />
                        </h6>
                        <span>
                            <FormattedMessage id="AppManagement.Auth.ClientIdDescription" />
                        </span>
                    </Label>
                    <Col sm={6}>
                        <InputGroup>
                            <Input
                                disabled={isReadOnly}
                                placeholder={intl.formatMessage({
                                    id: 'AppManagement.Auth.ClientId',
                                })}
                                defaultValue={authenticationSettings.clientId}
                            />
                            <InputGroupAddon addonType="append">
                                <CopyToClipboard id="clientId" text={authenticationSettings.clientId} />
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={4}>
                        <h6>
                            <FormattedMessage id="AppManagement.Auth.ClientSecret" />
                        </h6>
                        <span>
                            <FormattedMessage id="AppManagement.Auth.ClientSecretDescription" />
                        </span>
                    </Label>
                    <Col sm={6}>
                        <InputGroup>
                            <Input
                                disabled={isReadOnly}
                                className="border-right-0"
                                type={inputType.clientSecret}
                                placeholder={intl.formatMessage({
                                    id: 'AppManagement.Auth.ClientSecret',
                                })}
                                defaultValue={authenticationSettings.clientSecret}
                            />
                            <InputGroupAddon addonType="append">
                                <TogglePasswordVisibility
                                    className="border-left-0"
                                    inputType={inputType.clientSecret}
                                    togglePassword={(value) => toggleInputType('clientSecret', value)}
                                />
                            </InputGroupAddon>
                            <InputGroupAddon addonType="append">
                                <CopyToClipboard id="clientSecret" text={authenticationSettings.clientSecret} />
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </FormGroup>

                <hr />

                <FormGroup row>
                    <Label sm={4}>
                        <h6>
                            <FormattedMessage id="AppManagement.Auth.InstallUrl" />
                        </h6>
                        <span>
                            <FormattedMessage id="AppManagement.Auth.InstallUrlDescription" />
                        </span>
                        <p className="mt-3">
                            <a className="open-link" href="/">
                                <FormattedMessage id="AppManagement.Auth.LearnMore" />{' '}
                                <FormattedMessage id="AppManagement.Auth.OAuth" /> &nbsp;&nbsp;
                                <i>
                                    <OpenLinkIcon />
                                </i>
                            </a>
                        </p>
                    </Label>
                    <Col sm={6}>
                        <InputGroup>
                            <Input
                                disabled={isReadOnly}
                                name="installUrl"
                                placeholder={intl.formatMessage({
                                    id: 'AppManagement.Auth.InstallUrlPlaceholder',
                                })}
                                defaultValue={authenticationSettings.installUrl}
                                onChange={onValueChange}
                            />
                            <InputGroupAddon addonType="append">
                                <CopyToClipboard id="installUrl" text={authenticationSettings.installUrl} />
                            </InputGroupAddon>
                        </InputGroup>
                        <span>{`http://app.resdiary.com/oauth/authorise?client_id=1&`}</span>
                    </Col>
                </FormGroup>

                <hr />

                <FormGroup row>
                    <Label sm={4}>
                        <h6>
                            <FormattedMessage id="AppManagement.Auth.RedirectUrl" /> (
                            <FormattedMessage id="AppManagement.Auth.OAuth" />)
                        </h6>
                        <span>
                            <FormattedMessage id="AppManagement.Auth.RedirectUrlDescription" />
                        </span>
                        <p className="mt-3">
                            <a className="open-link" href="/">
                                <FormattedMessage id="AppManagement.Auth.LearnMore" />{' '}
                                <FormattedMessage id="AppManagement.Auth.RedirectUrl" /> &nbsp;&nbsp;
                                <i>
                                    <OpenLinkIcon />
                                </i>
                            </a>
                        </p>
                    </Label>
                    <Col sm={6}>
                        <Input
                            disabled={isReadOnly}
                            placeholder={intl.formatMessage({
                                id: 'AppManagement.Auth.RedirectUrl',
                            })}
                            name="redirectUrl"
                            defaultValue={authenticationSettings.redirectUrl}
                            onChange={onValueChange}
                        />
                    </Col>
                </FormGroup>

                <hr />

                <FormGroup row>
                    <Label sm={4}>
                        <h6>
                            <FormattedMessage id="AppManagement.Auth.SelectedAPI" />
                        </h6>
                    </Label>
                    <Col sm={6}>
                        <div className="d-inline-block">
                            <Tag
                                value={textDisplayHelper.formatPascalText(props.application.apiType)}
                                color={tagColour.Navy}
                            />
                        </div>
                    </Col>
                </FormGroup>

                <hr />
                <FormGroup row>
                    <div className="col-md-5 modal-button-group">
                        {authInfo.isAdmin && !isEditing && (
                            <Button
                                type="button"
                                color="primary"
                                disabled={props.application.submissionStatus === appSubmissionStatus.submitted}
                                onClick={toggleEditing}
                            >
                                <FormattedMessage id="Common.Edit" />
                            </Button>
                        )}

                        {(!authInfo.isAdmin || isEditing) && (
                            <Button type="button" color="primary" className="mr-2" onClick={saveAuthenticationSettings}>
                                <FormattedMessage id="Common.SaveAndContinue" />
                            </Button>
                        )}

                        {authInfo.isAdmin && isEditing && (
                            <Button type="button" color="outline-primary" onClick={toggleEditing}>
                                <FormattedMessage id="Common.Cancel" />
                            </Button>
                        )}
                    </div>
                </FormGroup>
            </div>
        )
    );
}

export default AppAuthentication;
