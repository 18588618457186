import React, { useState } from 'react';
import ConfirmationModal from '../common/ConfirmationModal';
import TestDiariesPage from '../common/TestDiariesPage';
import TestDiaryService from '../../services/TestDiaryService';
import { useIntl } from 'react-intl';

const AppTestDiariesPage = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [isInstall, setIsInstall] = useState(true);
    const [installDiaryName, setInstallDiaryName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [selectedTestDiary, setSelectedTestDiary] = useState({});
    const [diaryData, setDiaryData] = useState([]);
    const intl = useIntl();

    const renderModal = () => {
        const message = intl.formatMessage(
            {
                id: isInstall
                    ? 'AppManagement.AppTestDiariesPage.InstallMessage'
                    : 'AppManagement.AppTestDiariesPage.UninstallMessage',
            },
            { applicationTitle: props.application.title, diaryName: installDiaryName }
        );
        return (
            <ConfirmationModal
                isOpen={showModal}
                onClose={() => {
                    setShowModal(false);
                    setInstallDiaryName('');
                }}
                onAccept={() => installUninstallApp()}
                header={message}
                isHideCancel={false}
            ></ConfirmationModal>
        );
    };

    const installUninstallAppHandleClick = (x) => {
        let isAppInstalled = x.connectedApps.indexOf(props.application.title) > -1;
        setIsInstall(!isAppInstalled);
        setShowModal(true);
        setInstallDiaryName(x.name);
        setSelectedTestDiary(x);
    };

    const installUninstallApp = () => {
        setIsLoading(true);
        TestDiaryService.installUninstallApp(props.application.appId, selectedTestDiary.id, isInstall)
            .then((response) => {
                if (response.status === 200) {
                    const appTitle = props.application.title;
                    const copyConnectedApps = selectedTestDiary.connectedApps;
                    if (isInstall) {
                        copyConnectedApps.push(appTitle);
                    } else {
                        const index = copyConnectedApps.indexOf(appTitle);
                        copyConnectedApps.splice(index, 1);
                    }
                    setSelectedTestDiary((selectedTestDiary) => ({
                        ...selectedTestDiary,
                        connectedApps: copyConnectedApps,
                    }));
                    //update diary list with updated selectedDiary
                    setDiaryData(
                        diaryData.map((item) =>
                            item.id === selectedTestDiary.id ? { ...item, selectedTestDiary } : item
                        )
                    );
                } else {
                    setErrorMessage(response.errorMessage);
                }
            })
            .finally(() => {
                setShowModal(false);
                setIsLoading(false);
            });
    };

    return (
        <div>
            <TestDiariesPage
                installUninstallAppHandleClick={installUninstallAppHandleClick}
                application={props.application}
                error={errorMessage}
                isLoading={isLoading}
                isAppPage={true}
            />
            {showModal && renderModal()}
        </div>
    );
};

export default AppTestDiariesPage;
