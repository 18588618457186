/* eslint-disable */
import { adminApi } from '../../helpers/ApiHelper';
import ErrorMessage from '../../model/services/ErrorMessage';
import Organisation from '../../model/user/Organisation';
import OrganisationUser from '../../model/user/admin/OrganisationUser';
import PagedResult from '../../model/utility/PagedResult';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const getAllOrganisations = async (pageSize?: number, pageNumber?: number, getAll?: boolean) => {
    return await adminApi
        .get<PagedResult<Organisation>, string>(`organisation`, { pageSize, pageNumber, getAll })
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const getUsersInOrganisation = async (organisationId: number) => {
    return await adminApi.get<OrganisationUser[], string>(`organisation/${organisationId}/users`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const resendOrganisationInvitation = async (organisationId: number, userId: string) => {
    return await adminApi
        .post<undefined, string>(`organisation/${organisationId}/users/${userId}/resend-invite`)
        .then((response) => {
            if (response.ok) {
                return true;
            } else if (!response.ok && response.data !== undefined) {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            } else {
                return undefinedResponse();
            }
        });
};

export default {
    getAllOrganisations,
    getUsersInOrganisation,
    resendOrganisationInvitation,
};
