import * as React from 'react';
import { useState, useEffect } from 'react';
import { Typography, Button } from '@mui/material';
import TestDiaryTable from '../common/TestDiaryTable';
import { FormattedMessage, useIntl } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import HubAlertManager from '../common/hubAlert/HubAlertManager';
import { HubAlertProps } from '../common/hubAlert/HubAlert';
import TestDiaryService from '../../services/TestDiaryService';
import { useOrganisation } from '../../contexts/OrganisationContext';
import TestProvider from '../../model/TestDiaries/TestProvider';
import ErrorMessage from '../../model/services/ErrorMessage';

const TestDiariesPage = () => {
    const MAX_TEST_DIARIES = 3;
    const intl = useIntl();
    const organisationContext = useOrganisation();
    const [remainingDiaries, setRemainingDiaries] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [alerts, setAlerts] = useState<HubAlertProps[]>([]);
    const [testDiaries, setTestDiaries] = useState<TestProvider[]>([]);

    const getTestDiaries = () => {
        // Get test diaries
        setIsLoading(true);
        TestDiaryService.getTestDiaries(organisationContext.defaultOrganisation!.id)
            .then((response: TestProvider[]) => {
                setTestDiaries(response);
                setRemainingDiaries(MAX_TEST_DIARIES - response.length);
            })
            .catch((error: ErrorMessage) => {
                setAlerts((e) => [
                    ...e,
                    {
                        type: 'error',
                        message: error.errorMessage,
                        title: intl.formatMessage({ id: 'TestDiaries.Table.ErrorLoadingTable' }),
                    },
                ]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const createTestDiary = () => {
        TestDiaryService.createTestDiary(organisationContext.defaultOrganisation!.id)
            .then(() => {
                getTestDiaries();
                setAlerts((e) => [
                    ...e,
                    { type: 'success', message: intl.formatMessage({ id: 'TestDiaries.Page.DiaryCreatedSuccess' }) },
                ]);
            })
            .catch(() => {
                setAlerts((e) => [
                    ...e,
                    { type: 'error', message: intl.formatMessage({ id: 'TestDiaries.Page.DiaryCreatedFailure' }) },
                ]);
            });
    };

    useEffect(getTestDiaries, [
        organisationContext,
        intl,
        setRemainingDiaries,
        setTestDiaries,
        setAlerts,
        setIsLoading,
    ]);

    return (
        <div className="test-diary-page">
            <div className="content">
                <div className="header">
                    <div className="titles">
                        <Typography variant="h4" sx={{ paddingBottom: '1rem' }}>
                            <FormattedMessage id="TestDiaries.Page.Title" />
                        </Typography>
                        <Typography variant="body1">
                            <FormattedMessage id="TestDiaries.Page.Description" />
                        </Typography>
                        {!isLoading && (
                            <Typography variant="body1" color={remainingDiaries > 0 ? 'primary.main' : 'error.main'}>
                                {remainingDiaries !== 1
                                    ? intl.formatMessage(
                                          { id: 'TestDiaries.Page.DiariesRemaining' },
                                          { remaining: remainingDiaries }
                                      )
                                    : intl.formatMessage(
                                          { id: 'TestDiaries.Page.DiaryRemaining' },
                                          { remaining: remainingDiaries }
                                      )}
                            </Typography>
                        )}
                    </div>
                    <div className="add-diary">
                        <Button disabled={remainingDiaries === 0} variant="contained" onClick={createTestDiary}>
                            <AddIcon />
                            <FormattedMessage id="TestDiaries.Page.CreateTestDiary" />
                        </Button>
                    </div>
                </div>
                <div className="table">
                    <TestDiaryTable testDiaries={testDiaries} />
                </div>
            </div>
            <HubAlertManager alerts={alerts} />
        </div>
    );
};

export default TestDiariesPage;
