const appNavigationTabs = {
    getStarted: 'GetStarted',
    configure: 'Configure',
    basicInformation: 'BasicInformation',
    authentication: 'Authentication',
    certification: 'Certification',
    webhookConfiguration: 'WebhookConfiguration',
    testDiary: 'TestDiary',
    submitApp: 'SubmitApp',
    logs: 'Logs',
    webhookLogs: 'WebhookLogs',
    apiLogs: 'ApiLogs',
    installations: 'Installations',
    storeListing: 'StoreListing',
    startingGuide: 'StartingGuide',
    appSubmission: 'AppSubmission',
};

export default appNavigationTabs;
