import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DataTable from '../common/DataTable';
import ButtonBar from '../common/ButtonBar';
import { FormGroup, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

function LogTable({
    columns,
    data,
    isLoading,
    nextPageUrl,
    handleLoadMoreClick,
    filter,
    setFilter,
    tableName,
    expandRow,
}) {
    const intl = useIntl();
    function renderFilters(filter, setFilter, tableName) {
        filter = filter || {};

        const responseStatusFilterType = {
            all: null,
            success: 'success',
            failure: 'failure',
        };

        const environmentTypes = [
            {
                index: 'all',
                value: responseStatusFilterType.all,
                isActive: filter.responseStatus === responseStatusFilterType.all,
                label: intl.formatMessage({ id: 'AppManagement.LogTable.All' }),
            },
            {
                index: 'success',
                value: responseStatusFilterType.success,
                isActive: filter.responseStatus === responseStatusFilterType.success,
                label: intl.formatMessage({ id: 'AppManagement.LogTable.Success' }),
            },
            {
                index: 'failure',
                value: responseStatusFilterType.failure,
                isActive: filter.responseStatus === responseStatusFilterType.failure,
                label: intl.formatMessage({ id: 'AppManagement.LogTable.Failure' }),
            },
        ];

        const setResponseStatus = (value) => {
            setFilter({
                ...filter,
                responseStatus: value,
            });
        };

        return (
            <div className="row">
                <div className="log-list__filters col-md-6">
                    <FormGroup>
                        <Label>
                            <h6>
                                <FormattedMessage id="AppManagement.LogTable.FilterBy" />
                            </h6>
                        </Label>
                        <ButtonBar items={environmentTypes} setValue={setResponseStatus} />
                    </FormGroup>
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            {renderFilters(filter, setFilter, tableName)}
            <DataTable
                tableName={tableName}
                columns={columns}
                data={data}
                isLoading={isLoading}
                nextPageUrl={nextPageUrl}
                handleLoadMoreClick={handleLoadMoreClick}
                expandRow={expandRow}
            />
        </Fragment>
    );
}

LogTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    handleLoadMoreClick: PropTypes.func,
    filter: PropTypes.object,
    setFilter: PropTypes.func,
    tableName: PropTypes.string,
    renderPagination: PropTypes.func,
    application: PropTypes.object,
};

export default LogTable;
