import * as React from 'react';
import { Fragment, useState, useEffect, useRef } from 'react';
import config from '../../config/app';
import { Alert, Container } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as ResIcon } from '../../content/images/RD_hub_logo.svg';
import AccountService from '../../services/AccountService';
import Notification from '../common/Notification';
import useQueryParam from '../../hooks/useQueryParam';
import { useIntl, FormattedMessage } from 'react-intl';
import { Modal, Form, FormGroup, Label } from 'reactstrap';
import ValidationInput from '../common/ValidationInput';
import useValidation from '../../hooks/useValidation';
import { Link } from 'react-router-dom';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import InfoBarManager from '../common/InfoBarManager';
import infoBarType from '../../enums/infoBarType';
import { InfoBarProps } from '../common/InfoBar';
import ErrorMessage from '../../model/services/ErrorMessage';
import LoadingSpinner from '../common/LoadingSpinner';

let _ID = 0;

function VerifyAccountPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();

    const verificationEmail = (location.state as LocationState)?.email;
    const autoverifyPassword = useRef((location.state as LocationState)?.password);
    const verificationToken = useQueryParam('token');
    const userId = useQueryParam('userId');

    const [isInvalidToken, setIsInvalidToken] = useState(false);
    const [isResendVerificationEmail, setIsResendVerificationEmail] = useState(false);
    const [resendError, setResendError] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);
    const [username, setUsername] = useState(verificationEmail);
    const [password, setPassword] = useState(autoverifyPassword.current);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [infoBars, setInfoBars] = useState<InfoBarProps[]>([]);

    const { register, errors, submitDisabled, submit } = useValidation();

    // Redirect to login page if props has no email or query params has no token
    if (!verificationEmail && !verificationToken) {
        navigate(config.loginPath);
    }

    const addErrorBar = (message: string) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message, expireIn: 0 }]);
    };

    useEffect(() => {
        if (!verificationToken) {
            AnalyticsHelper.trackPage('Hub Email Verification page');
        }

        if (verificationToken) {
            setIsVerifying(true);
            AccountService.verify(verificationToken, userId!)
                .then((response: any) => {
                    if (response === true) {
                        setEmailVerified(true);
                        AnalyticsHelper.trackPage('Hub Email Verified page');
                    } else {
                        setIsInvalidToken(true);
                    }
                })
                .catch((error: ErrorMessage) => {
                    addErrorBar(error.errorMessage);
                })
                .finally(() => {
                    setIsVerifying(false);
                });
        }

        if (autoverifyPassword && verificationEmail) {
            autoverifyPassword.current = '';
            AccountService.resendVerificationEmail(username, password).then((response: any) => {
                setPassword('');
                if (!response.errorMessage) {
                    setResendError(response.errorMessage);
                }
            });
        }
    }, [userId, verificationToken, verificationEmail, setResendError, setPassword, password, username]);

    const resendVerificationEmail = async (): Promise<void> => {
        setShowLoginModal(false);
        setResendButtonDisabled(true);
        await AccountService.resendVerificationEmail(username, password).then((response: any) => {
            setPassword('');
            if (!response.errorMessage) {
                setResendError('');
                setIsResendVerificationEmail(true);
                AnalyticsHelper.trackClickWithProperties('Web Hub Verification Resend', {
                    emailAddress: verificationEmail,
                });
            } else {
                setResendError(response.errorMessage);
            }
            setResendButtonDisabled(false);
        });
    };

    const renderInvalidToken = (): JSX.Element | undefined => {
        if (isInvalidToken) {
            return (
                <Notification
                    alertType="AlertIcon"
                    title={intl.formatMessage({ id: 'Login.VerifyAccount.VerificationExpired' })}
                    message1={intl.formatMessage({ id: 'Login.VerifyAccount.VerificationExpiredMsg1' })}
                    message2={intl.formatMessage({ id: 'Login.VerifyAccount.VerificationExpiredMsg2' })}
                />
            );
        }
    };

    const renderVerifyEmail = (): JSX.Element => {
        return (
            <Fragment>
                <Notification
                    alertType="ConfirmedIcon"
                    title={intl.formatMessage({ id: 'Login.VerifyAccount.VerifyEmail' })}
                    message1={intl.formatMessage(
                        { id: 'Login.VerifyAccount.VerifyEmailMsg1' },
                        { verificationEmail: `<b>${verificationEmail}</b>` }
                    )}
                    message2={intl.formatMessage({ id: 'Login.VerifyAccount.VerifyEmailMsg2' })}
                />
            </Fragment>
        );
    };

    const renderEmailVerified = (): JSX.Element => {
        return (
            <Notification
                alertType="ConfirmedIcon"
                title={intl.formatMessage({ id: 'Login.VerifyAccount.EmailVerified' })}
                message1={intl.formatMessage({ id: 'Login.VerifyAccount.EmailVerifiedMsg1' })}
                message2={intl.formatMessage({ id: 'Login.VerifyAccount.EmailVerifiedMsg2' })}
            />
        );
    };

    const renderVerificationForm = (): JSX.Element => {
        if (emailVerified) {
            return (
                <Fragment>
                    {renderEmailVerified()}
                    <button
                        className="button-link"
                        onClick={() => {
                            AnalyticsHelper.trackClick('Web Hub Verification SignIn');
                            navigate(config.loginPath);
                        }}
                    >
                        <FormattedMessage id="Common.LogIn" />
                    </button>
                </Fragment>
            );
        }

        return (
            <Fragment>
                {!verificationToken && renderVerifyEmail()}
                {verificationToken && isInvalidToken && renderInvalidToken()}
                {resendError && <Alert className="account-form__error">{resendError}</Alert>}
                {isResendVerificationEmail && (
                    <Alert className="account-form__success">
                        <FormattedMessage id="Login.VerifyAccount.TokenSent" />
                    </Alert>
                )}
                <button
                    className="btn btn-primary"
                    onClick={() => setShowLoginModal(!showLoginModal)}
                    disabled={resendButtonDisabled}
                >
                    <FormattedMessage id="Login.VerifyAccount.ResendVerificationBtn" />
                </button>
                {renderLoginModal()}
            </Fragment>
        );
    };

    const renderLoginModal = (): JSX.Element => {
        return (
            <Modal centered scrollable={false} isOpen={showLoginModal}>
                <Form className="account-form" onSubmit={submit(resendVerificationEmail)}>
                    <div className="account-form__title">Login to continue</div>
                    <FormGroup>
                        <div className="d-flex">
                            <Label for="username">
                                <FormattedMessage id="Common.Username" />
                            </Label>
                        </div>
                        <ValidationInput
                            testId="username"
                            type="text"
                            name="username"
                            placeholder="youremail@domain.com"
                            value={username}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.currentTarget.value)}
                            innerRef={register({
                                required: intl.formatMessage({ id: 'Login.LoginPage.UsernameRequired' }),
                            })}
                            errors={errors}
                        />
                    </FormGroup>
                    <FormGroup>
                        <div className="d-flex">
                            <Label for="password">
                                <FormattedMessage id="Common.Password" />
                            </Label>
                            <Link className="ml-auto" to="/ForgotPassword">
                                <FormattedMessage id="Login.LoginPage.ForgotPassword" />
                            </Link>
                        </div>
                        <ValidationInput
                            testId="password"
                            type="password"
                            name="password"
                            placeholder="password"
                            value={password}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)}
                            innerRef={register({
                                validate: (value) => setPasswordValidation(value),
                            })}
                            errors={errors}
                            showPasswordToggle={true}
                        />
                    </FormGroup>
                    <FormGroup>
                        <input
                            disabled={submitDisabled}
                            type="submit"
                            value={intl.formatMessage({ id: 'Common.Submit' })}
                            className="login-button btn btn-primary col"
                            data-testid="account-form-submit-button"
                        />
                    </FormGroup>
                    <FormGroup>
                        <input
                            type="button"
                            value={intl.formatMessage({ id: 'Common.Cancel' })}
                            className="login-button btn btn-primary col"
                            onClick={() => setShowLoginModal(!showLoginModal)}
                        />
                    </FormGroup>
                </Form>
            </Modal>
        );
    };

    const setPasswordValidation = (value: string): string | undefined => {
        if (value === null || value.trim() === '') {
            return intl.formatMessage({ id: 'Login.LoginPage.PasswordRequired' });
        }
    };

    return (
        <Fragment>
            {isVerifying ? (
                <LoadingSpinner />
            ) : (
                <Fragment>
                    <div className="container-fluid">
                        {isResendVerificationEmail && <InfoBarManager infoBarArray={infoBars} />}
                    </div>
                    <Container className="account-page">
                        <div className="flex-container-center">
                            <div className="account-page__body__logo">
                                <ResIcon className="brand-svg" />
                            </div>
                            <div className="account-form">{renderVerificationForm()}</div>
                        </div>
                    </Container>
                </Fragment>
            )}
        </Fragment>
    );
}

interface LocationState {
    email: string;
    password: string;
}

export default VerifyAccountPage;
