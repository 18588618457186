import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiLogService from '../../services/ApiLogService';
import environment from '../../enums/environment.js';
import LogTable from '../common/LogTable';
import { useOrganisation } from '../../contexts/OrganisationContext';
import AppLogDetails from './AppLogDetails.js';
import { FormattedMessage, useIntl } from 'react-intl';

function AppApiLogs(props) {
    const [apiLogEntries, setApiLogsEntries] = useState({ data: [], nextPageUrl: null, pageNumber: 0 });
    const [isLoading, setIsLoading] = useState(true);
    const intl = useIntl();

    const [filter, setFilter] = useState({
        responseStatus: apiLogEntries.responseStatusFilter ?? null,
        environment: apiLogEntries.environmentFilter ?? environment.test, // Set test environment as default for now.
    });

    const navigate = useNavigate();
    const { defaultOrganisation } = useOrganisation();
    const [isDisplayingLogEntry, setIsDisplayingLogEntry] = useState(false);
    const [requestId, setRequestId] = useState(null);
    const [env, setEnv] = useState(environment.test);

    useEffect(() => {
        ApiLogService.findApiRequestLogEntries(
            props.application.id,
            defaultOrganisation.id,
            filter.responseStatus,
            filter.environment
        )
            .then((response) => {
                if (!('errorMessage' in response)) {
                    setApiLogsEntries(response);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [props.application.id, defaultOrganisation.id, filter.responseStatus, filter.environment]);

    const columns = [
        {
            name: intl.formatMessage({ id: 'AppManagement.ApiLogs.TimeStamp' }),
            className: 'log-table__timestamp-column',
            displayFormat: (x) => {
                const statusDotClassName = `status-dot ${x.responseStatus >= 400 ? 'status-dot--fail' : ''}`;
                return (
                    <Fragment>
                        <div className={statusDotClassName}></div>
                        {x.timestamp.replace('T', ' ').replace(/\.\d+/, '')}
                    </Fragment>
                );
            },
        },
        {
            name: intl.formatMessage({ id: 'AppManagement.ApiLogs.Summary' }),
            selector: (x) => (
                <Fragment>
                    <span className="log-table__request-summary" title={x.requestSummary}>
                        {x.requestSummary}
                    </span>
                </Fragment>
            ),
        },
        {
            name: intl.formatMessage({ id: 'AppManagement.ApiLogs.Status' }),
            selector: (x) => x.responseStatus,
        },
        {
            name: intl.formatMessage({ id: 'AppManagement.ApiLogs.Time' }),
            selector: (x) => x.elapsedMilliseconds,
        },
        {
            name: '',
            displayFormat: (x) => {
                return renderGotoDetailPage(x.id, navigate, filter.environment);
            },
        },
    ];

    const handleLoadMoreClick = async () => {
        setIsLoading(true);
        ApiLogService.loadNextPage(apiLogEntries.nextPageUrl)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    setApiLogsEntries(response);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    function displayLogEntry(id, env) {
        setIsDisplayingLogEntry(true);
        setRequestId(id);
        setEnv(env);
    }

    const renderGotoDetailPage = (id, navigate, environment) => {
        return (
            <button
                className="log-table__goto-detail-page float-right"
                onClick={() => {
                    displayLogEntry(id, environment);
                }}
            >
                <img src="/images/Chevron.svg" className="rotate-90deg" alt="" />
            </button>
        );
    };

    return (
        <div className="logs-page">
            <h1>
                <FormattedMessage id="AppManagement.ApiLogs.PageHeader" />
            </h1>
            {!isDisplayingLogEntry && (
                <LogTable
                    tableName={intl.formatMessage({ id: 'AppManagement.ApiLogs.PageHeader' })}
                    columns={columns}
                    data={apiLogEntries.data}
                    isLoading={isLoading}
                    nextPageUrl={apiLogEntries.nextPageUrl}
                    handleLoadMoreClick={handleLoadMoreClick}
                    filter={filter}
                    setFilter={setFilter}
                    application={props.application}
                />
            )}
            {isDisplayingLogEntry && (
                <AppLogDetails
                    application={props.application}
                    requestId={requestId}
                    environment={env}
                    setIsDisplayingLogEntry={setIsDisplayingLogEntry}
                />
            )}
        </div>
    );
}

export default AppApiLogs;
