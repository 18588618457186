import * as React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import SidebarMenuItem from '../../../model/layout/SidebarMenuItem';
import SidebarItem from './SidebarItem';

const SidebarTitle = (props: SidebarTitleProps) => {
    const location = useLocation();

    const checkIsSubItemActive = (item: SidebarMenuItem) => {
        if (location.pathname === item.path) {
            return true;
        } else {
            for (let i = 0; i < (item.subItems || []).length; i++) {
                if (checkIsSubItemActive(item.subItems![i])) {
                    return true;
                }
            }
            return false;
        }
    };

    const [menuActive, setMenuActive] = useState(
        location.pathname === props.item.path || checkIsSubItemActive(props.item)
    );

    const showMenuItems = () => setMenuActive(!menuActive);

    const isActive = (item: SidebarMenuItem) => {
        return location.pathname === item.path ? 'active' : '';
    };

    return (
        <div className="dp-sidebar__base" key={props.key}>
            <div className={`dp-sidebar__header-name ${isActive(props.item)}`}>
                <Link to={props.item.path} onClick={showMenuItems}>
                    <FormattedMessage id={props.item.title} />
                </Link>
                {props.item.subItems && (
                    <img
                        src="/images/Chevron.svg"
                        className={menuActive ? 'rotate-180deg' : ''}
                        alt=""
                        onClick={showMenuItems}
                    />
                )}
            </div>
            {menuActive &&
                props.item.subItems?.map((item, index) => {
                    return <SidebarItem item={item} key={index} />;
                })}
        </div>
    );
};

export interface SidebarTitleProps {
    key: number;
    item: SidebarMenuItem;
}

export default SidebarTitle;
