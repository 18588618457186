const tagColour = {
    Navy: 'Navy',
    Green: 'Green',
    Red: 'Red',
    Orange: 'Orange',
    None: 'None',
    Grey: 'Grey',
    Purple: 'Purple',
    Blue: 'Blue',
    LightGreen: 'LightGreen',
    Disabled: 'Disabled',
};

export default tagColour;
