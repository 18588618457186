import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import CopyToClipboard from '../../../common/CopyToClipboard';

const ClientIdCard = (props: ClientIdCardProps) => {
    return (
        <div className="client-id-card mb-4">
            <h5 className="mb-3">
                <FormattedMessage id="AppManagement.AppDetails.ClientId" />
            </h5>
            <div className="copy-box">
                <div className="copy-text">
                    <p className="m-0">{props.clientId}</p>
                </div>
                <div className="copy-icon">
                    <CopyToClipboard id="clientIdCopy" text={props.clientId} isWhite={true} />
                </div>
            </div>
        </div>
    );
};

export interface ClientIdCardProps {
    clientId: string;
}

export default ClientIdCard;
