import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CheckBox from './CheckBox';
import ValidationMessage from '../common/ValidationMessage';

function CheckboxGroup(props) {
    function isChecked(value) {
        return props.checkedValues && props.checkedValues.includes(value) ? true : false;
    }

    return (
        <Fragment>
            {props.options.map((option) => {
                return (
                    <CheckBox
                        name={props.name}
                        id={option.value}
                        key={'checkbox-group-item ' + option.value}
                        option={option}
                        onChange={props.onChange}
                        isChecked={isChecked(option.value)}
                        disabled={props.disabled}
                        getLink={props.getLink}
                        ariaRequired={props.ariaRequired}
                        ariaLabel={option.text}
                        innerRef={props.innerRef}
                    />
                );
            })}
            <div className="validation-input-feedback">
                {props.errors[props.name] && <ValidationMessage message={props.errors[props.name].message} />}
            </div>
        </Fragment>
    );
}

CheckboxGroup.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    checkedValues: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    getLink: PropTypes.func,
};

export default CheckboxGroup;
