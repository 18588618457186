import AppStartingGuideSectionStep from './AppStartingGuideSectionStep';
import AppStartingGuideSectionType from '../enums/appManagement/AppStartingGuideSectionType';

class AppStartingGuideSection {
    constructor(section) {
        this.id = section ? section.id : 0;
        this.description = section ? section.description : '';
        this.setupSteps =
            section && section.setupSteps
                ? this.getSectionItems(section.setupSteps)
                : [new AppStartingGuideSectionStep(null, AppStartingGuideSectionType.setup)];
        this.usageSteps =
            section && section.usageSteps
                ? this.getSectionItems(section.usageSteps)
                : [new AppStartingGuideSectionStep(null, AppStartingGuideSectionType.usage)];
    }

    getSectionItems(steps) {
        return steps.map((step) => {
            return new AppStartingGuideSectionStep(step);
        });
    }

    toJson() {
        return {
            Id: this.id,
            Description: this.description,
            SetupSteps: this.convertStepsToJson(this.setupSteps),
            UsageSteps: this.convertStepsToJson(this.usageSteps),
        };
    }

    convertStepsToJson(steps) {
        return steps.map((step) => {
            return step.toJson();
        });
    }
}

export default AppStartingGuideSection;
