/* eslint-disable */
import { api } from '../helpers/ApiHelper';
import ApiCertificationAnswered from '../model/certificationChecklist/ApiCertificationAnswered';
import ApiCertificationChecklist from '../model/certificationChecklist/ApiCertificationChecklist';
import ErrorMessage from '../model/services/ErrorMessage';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const getCertificationChecklist = async (apiType: string, appId: string) => {
    return await api
        .get<ApiCertificationChecklist, string>(`CertificationsChecklist/appid/${appId}?apiType=${apiType}`)
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const saveChecklistAnswer = async (
    appId: string,
    checklistQuestionId: number,
    answerId: number,
    isChecked: boolean
) => {
    let checklistAnswered = {
        checklistQuestionId: checklistQuestionId,
        checklistAnswerId: Number(answerId),
        isChecked: isChecked,
        appId: appId,
    };
    return await api
        .post<ApiCertificationAnswered, string>(`CertificationsChecklist`, JSON.stringify(checklistAnswered))
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const deleteChecklistAnswer = async (
    appId: string,
    checklistQuestionId: number,
    answerId: number,
    isChecked: boolean
) => {
    let checklistAnswered = {
        checklistQuestionId: checklistQuestionId,
        checklistAnswerId: Number(answerId),
        isChecked: isChecked,
        appId: appId,
    };
    return await api
        .delete<undefined, string>(`CertificationsChecklist`, JSON.stringify(checklistAnswered))
        .then((response) => {
            if (response.data === undefined && response.ok) {
                return true;
            } else if (response.data !== undefined && !response.ok) {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            } else {
                return undefinedResponse();
            }
        });
};

export default {
    getCertificationChecklist,
    saveChecklistAnswer,
    deleteChecklistAnswer,
};
