import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

const ConsumerApiKeyRow = (props) => {
    return (
        <tr>
            <td>{props.name}</td>
            <td className="settings-table__token-column">{props.apiKey}</td>
            <td>
                <Button color="primary" onClick={props.onButtonClick}>
                    Regenerate
                </Button>
            </td>
        </tr>
    );
};

ConsumerApiKeyRow.propTypes = {
    apiKey: PropTypes.string,
    name: PropTypes.string,
    onButtonClick: PropTypes.func,
};

export default ConsumerApiKeyRow;
