import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
import TestDiaryService from '../../services/TestDiaryService';
import { ReactComponent as TestDiaryIcon } from '../../content/images/test-diary.svg';
import ConfirmationModal from './ConfirmationModal';
import FormModal from './FormModal';
import TestDiaryTable from './TestDiaryTableOld';
import infoBarType from '../../enums/infoBarType';
import InfoBarManager from './InfoBarManager';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import { useOrganisation } from '../../contexts/OrganisationContext';
import { FormattedMessage, useIntl } from 'react-intl';
import App from '../../model/appManagement/App';
import ErrorMessage from '../../model/services/ErrorMessage';
import TestProvider from '../../model/TestDiaries/TestProvider';
import { InfoBarProps } from './InfoBar';

const TestDiariesPage = (props: TestDiariesPageProps) => {
    const [diaryData, setDiaryData] = useState<TestProvider[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [createTdDisabled, setCreateTdDisabled] = useState(false);
    const [showCreateTestDiaryModal, setShowCreateTestDiaryModal] = useState(false);
    const [infoBars, setInfoBars] = useState<InfoBarProps[]>([]);
    const [showLinkTestDiaryModal, setShowLinkTestDiaryModal] = useState(false);
    const { authInfo } = useAuthentication();
    const { defaultOrganisation } = useOrganisation();
    const [isShowingCreateDiaryButton, setIsShowingCreateDiaryButton] = useState(false);
    const intl = useIntl();

    let formFields = () => {
        return [
            {
                label: intl.formatMessage({ id: 'AppManagement.TestDiariesPage.TestVenueName' }),
                textFieldType: 'text',
                formfieldValue: '',
                isRequired: true,
                hasCustomValidation: false,
            },
            {
                label: intl.formatMessage({ id: 'AppManagement.TestDiariesPage.TestVenueId' }),
                textFieldType: 'number',
                formFieldValue: '',
                isRequired: true,
                hasCustomValidation: true,
            },
            {
                label: intl.formatMessage({ id: 'AppManagement.TestDiariesPage.MicrositeName' }),
                textFieldType: 'text',
                formfieldValue: '',
                isRequired: true,
                hasCustomValidation: false,
            },
        ];
    };

    useEffect(() => {
        TestDiaryService.getTestDiaries(defaultOrganisation!.id)
            .then((response) => {
                const ownTestDiaries = response.filter((x) => x.type.indexOf('New') >= 0);
                if (ownTestDiaries.length === 3) {
                    setCreateTdDisabled(true);
                }

                // If on a App page, only show the diaries that have the app installed
                // and only show the create diary button if only less than 3 diaries
                // have been created
                setIsShowingCreateDiaryButton(props.isAppPage && ownTestDiaries.length < 3 ? true : false);
                setDiaryData(props.isAppPage ? response.filter((x) => x.connectedApps.length > 0) : response);
            })
            .catch((error: ErrorMessage) => {
                // TODO: add error toast
            })
            .finally(() => {
                if (props.error) {
                    addErrorBar(props.error);
                }
                setIsLoading(false);
            });
    }, [defaultOrganisation, props.error, props.isAppPage]);

    function addSuccessBar(message: string) {
        setInfoBars((i) => [...i, { type: infoBarType.success, message: message }]);
    }

    function addErrorBar(message: string) {
        setInfoBars((i) => [...i, { type: infoBarType.error, message: message }]);
    }

    const validateCustomFields = async (value: string, i: number, fieldName: string) => {
        if (i === 1 && fieldName === 'TestvenueID') {
            return TestDiaryService.isValidTestVenue(value);
        }
    };

    const newTestDiaryNoData = () => {
        // Not yet implemented
    };

    const newTestDiaryWithData = () => {
        TestDiaryService.createTestDiary(defaultOrganisation!.id)
            .then((response) => {
                setDiaryData([...diaryData, response]);
                addSuccessBar(intl.formatMessage({ id: 'AppManagement.TestDiariesPage.CreateTestDiarySuccess' }));
            })
            .catch((error: ErrorMessage) => {
                addErrorBar(error.errorMessage);
            })
            .finally(() => {
                setShowCreateTestDiaryModal(false);
            });
    };

    const linkTestDiary = async (testDiaryData: any[]) => {
        const thisDiaryData = [...diaryData];
        let findDiary = thisDiaryData.find(
            (x) =>
                x.remoteProviderId ===
                testDiaryData[1][intl.formatMessage({ id: 'AppManagement.TestDiariesPage.TestVenueId' })]
        );
        if (findDiary === undefined) {
            TestDiaryService.linkTestDiary(
                defaultOrganisation!.id,
                testDiaryData[0][intl.formatMessage({ id: 'AppManagement.TestDiariesPage.TestVenueName' })],
                testDiaryData[1][intl.formatMessage({ id: 'AppManagement.TestDiariesPage.TestVenueId' })],
                testDiaryData[2][intl.formatMessage({ id: 'AppManagement.TestDiariesPage.MicrositeName' })]
            )
                .then((response) => {
                    setDiaryData([...diaryData, response]);
                    addSuccessBar(intl.formatMessage({ id: 'TestDiaryPage.LinkTestDiaryLinkSuccess' }));
                    setShowLinkTestDiaryModal(false);
                })
                .catch(() => {
                    addErrorBar(intl.formatMessage({ id: 'AppManagement.TestDiariesPage.LinkTestDiaryLinkError' }));
                });
        } else {
            addErrorBar(
                intl.formatMessage(
                    {
                        id: 'AppManagement.TestDiariesPage.LinkTestDiaryLinkSuccess',
                    },
                    { venueNameAndId: testDiaryData[1]['TestVenueID'] }
                )
            );
        }
    };

    const createDiaryModal = () => {
        if (showCreateTestDiaryModal) {
            return (
                <ConfirmationModal
                    header={intl.formatMessage({ id: 'AppManagement.TestDiariesPage.CreateNewDiaryText' })}
                    isOpen={showCreateTestDiaryModal}
                    onClose={() => {
                        setShowCreateTestDiaryModal(false);
                    }}
                    onAccept={newTestDiaryWithData}
                    children={intl.formatMessage({ id: 'AppManagement.TestDiariesPage.ConfirmModalChildrenText' })}
                    isHideCancel={true}
                    firstButtonText={intl.formatMessage({
                        id: 'AppManagement.TestDiariesPage.ConfirmModalFirstButton',
                    })}
                    secondButtonFunc={() => {
                        newTestDiaryNoData();
                    }}
                    secondButtonText={intl.formatMessage({
                        id: 'AppManagement.TestDiariesPage.ConfirmModalSecondButton',
                    })}
                />
            );
        }
    };

    const tdButtons = () => {
        if (diaryData.length > 0 && !authInfo.isAdmin) {
            return (
                <div className="12">
                    <button
                        className="btn btn-primary td-add-btn"
                        disabled={createTdDisabled}
                        onClick={() => {
                            newTestDiaryWithData();
                        }}
                    >
                        <FormattedMessage id="AppManagement.TestDiariesPage.CreateNewDiaryText" />
                    </button>
                </div>
            );
        }
    };

    const linkTestDiaryModal = () => {
        if (showLinkTestDiaryModal) {
            return (
                <Fragment>
                    <FormModal
                        header={intl.formatMessage({ id: 'AppManagement.TestDiariesPage.LinkTestDiaryModalHeader' })}
                        description={intl.formatMessage({
                            id: 'AppManagement.TestDiariesPage.LinkTestDiaryModalDescription',
                        })}
                        onClose={() => setShowLinkTestDiaryModal(false)}
                        isOpen={showLinkTestDiaryModal}
                        onAccept={linkTestDiary}
                        formFields={formFields()}
                        customValidations={validateCustomFields}
                    />
                </Fragment>
            );
        }
    };

    const noDiaryButtons = () => {
        if (!authInfo.isAdmin && isShowingCreateDiaryButton) {
            return (
                <div className="col-12 text-center align-items-center">
                    <p>
                        <FormattedMessage id="AppManagement.TestDiariesPage.NoDiaryButtonHeader" />
                    </p>
                    <div className="col-12 p-2">
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                newTestDiaryWithData();
                            }}
                        >
                            <FormattedMessage id="AppManagement.TestDiariesPage.CreateNewDiaryText" />
                        </button>
                    </div>
                </div>
            );
        }
    };

    const noDiary = () => {
        return (
            <div className="col-md-6 offset-md-3 pt-2 align-items-center text-center">
                <div className="test-diary-icon text-center">
                    <TestDiaryIcon className="" />
                </div>
                <div className="row  m-3 p-2">
                    <p className="col-12">
                        <b>
                            <FormattedMessage id="AppManagement.TestDiariesPage.NoDiaryText" />
                        </b>
                    </p>
                    <p className="col-12 mb-0">
                        <FormattedMessage id="AppManagement.TestDiariesPage.NoDiaryDescription" />
                    </p>
                </div>
            </div>
        );
    };

    const installUninstallAppHandleClick = (x: TestProvider) => {
        props.installUninstallAppHandleClick!(x);
    };

    return (
        <div className="container-fluid">
            <div className="row flex-xl-nowrap">
                <main className="col col-12 content test-diaries-page">
                    <InfoBarManager infoBarArray={infoBars} />
                    <h2 className="col">
                        <FormattedMessage id="AppManagement.TestDiariesPage.TestDiariesPageHeader" />
                    </h2>
                    <div className="row">
                        <div className="col-6 ">
                            <p className="col td-page__description">
                                <FormattedMessage id="AppManagement.TestDiariesPage.TestDiariesPageDescription" />
                            </p>
                        </div>
                        <div className="col-6 float-right">{tdButtons()}</div>
                    </div>
                    <div className="row">
                        <div className="col-12 test-diaries-table">
                            <TestDiaryTable
                                data={diaryData || []}
                                isLoading={isLoading}
                                renderEmptyResult={noDiary}
                                showAccessToken={true}
                                showLinkToDiary={true}
                                handleApplicationSetup={installUninstallAppHandleClick}
                                application={props.application}
                            />
                            {diaryData.length <= 0 && noDiaryButtons()}
                            {createDiaryModal()}
                            {linkTestDiaryModal()}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export interface TestDiariesPageProps {
    installUninstallAppHandleClick?: Function;
    renderPagination?: Function;
    renderEmptyResult?: Function;
    className?: string;
    application?: App;
    error?: string;
    isLoading?: boolean;
    isAppPage?: boolean;
}

export default TestDiariesPage;
