import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import appStatus from '../../../enums/appSubmissionStatus';
import SelectList from '../../common/SelectList';
import SearchInput from '../../common/SearchInput';
import DataTable from '../../common/DataTable';
import Paginator from '../../common/Paginator';
import Tag from '../../common/Tag';
import tagColour from '../../../enums/tagColour';
import textDisplay from '../../../helpers/TextDisplayHelper';
import { ReactComponent as Chevron } from '../../../content/icons/Chevron.svg';
import { ReactComponent as AppsIcon } from '../../../content/icons/Icon-Apps.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import AppType from '../../../enums/appType';

const AppList = (props) => {
    const [appStatuses, setAppStatuses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPageSize, setSelectedPageSize] = useState(10);
    const [apps, setApps] = useState(props.apps);
    const [pages, setPages] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1); // eslint-disable-line
    const pageSize = [
        { key: 10, value: 10 },
        { key: 25, value: 25 },
        { key: 50, value: 50 },
        { key: 100, value: 100 },
    ];
    const defaultColumnTextLength = 20;
    const intl = useIntl();
    const navigate = useNavigate();

    const pageApps = useCallback(
        (arraySliceStart, pageSize) => {
            const selectedPagedApps = props.apps.slice(
                Number(arraySliceStart),
                Number(arraySliceStart) + Number(pageSize)
            );
            setApps(selectedPagedApps);
        },
        [props.apps]
    );

    useEffect(() => {
        if (appStatuses.length === 0) {
            setUpPages(props.apps.length, selectedPageSize);
            setAppStatuses((appStatuses) => [
                ...appStatuses,
                { key: 'All', value: intl.formatMessage({ id: 'Admin.AppList.All' }, { length: props.apps.length }) },
            ]);

            Object.values(appStatus).forEach((status) => {
                const statusCount = props.apps.filter((app) => app.submissionStatus === status).length;
                setAppStatuses((appStatuses) => [
                    ...appStatuses,
                    { key: status, value: `${textDisplay.formatPascalText(status)} (${statusCount})` },
                ]);
            });
        }

        props.apps.forEach((app) => {
            app.statusTag = setStatusTag(app.submissionStatus);
        });
        pageApps(0, selectedPageSize);
        setIsLoading(false);
    }, [appStatuses.length, pageApps, props.apps, selectedPageSize, intl]);

    const setStatusTag = (status) => {
        switch (status) {
            case appStatus.pending:
                return tagColour.Navy;
            case appStatus.notSubmitted:
                return tagColour.Grey;
            case appStatus.approved:
                return tagColour.Green;
            case appStatus.rejected:
                return tagColour.Orange;
            case appStatus.listingApproved:
                return tagColour.Green;
            case appStatus.listingRejected:
                return tagColour.Orange;
            case appStatus.actionsRequired:
                return tagColour.Blue;
            case appStatus.accountClosed:
                return tagColour.Disabled;
            default:
                return tagColour.Disabled;
        }
    };

    const getStatusName = (status) => {
        switch (status) {
            case appStatus.pending:
                return intl.formatMessage({ id: 'Admin.AppList.PendingStatus' });
            case appStatus.notSubmitted:
                return intl.formatMessage({ id: 'Admin.AppList.NotSubmittedStatus' });
            case appStatus.approved:
                return intl.formatMessage({ id: 'Admin.AppList.ApprovedStatus' });
            case appStatus.rejected:
                return intl.formatMessage({ id: 'Admin.AppList.PendingStatus' });
            case appStatus.listingApproved:
                return intl.formatMessage({ id: 'Admin.AppList.ListingApprovedStatus' });
            case appStatus.listingRejected:
                return intl.formatMessage({ id: 'Admin.AppList.ListingRejectedStatus' });
            case appStatus.actionsRequired:
                return intl.formatMessage({ id: 'Admin.AppList.ActionsRequiredStatus' });
            case appStatus.accountClosed:
                return intl.formatMessage({ id: 'Admin.AppList.AccountClosedStatus' });
            case appStatus.listingLive:
                return intl.formatMessage({ id: 'Admin.AppList.ListingLiveStatus' });
            case appStatus.listingSubmitted:
                return intl.formatMessage({ id: 'Admin.AppList.ListingSubmittedStatus' });
            default:
                return textDisplay.formatPascalText(status);
        }
    };

    const paginateNextButtonClick = () => {
        alert('next' + selectedPage);
    };

    const paginatePrevButtonClick = () => {
        alert('prev' + selectedPage);
    };
    const handleAppStatusChange = (e) => {
        e.target.value === 'All'
            ? setApps(props.apps)
            : setApps(props.apps.filter((app) => app.submissionStatus === e.target.value));
        setUpPages(apps.length, selectedPageSize);
    };

    const handlepageSizeChange = (e) => {
        setSelectedPageSize(e.target.value);
        setUpPages(props.apps.length, e.target.value);
        pageApps(0, e.target.value);
    };

    const setUpPages = (appsCount, pageSize) => {
        setPages(Math.ceil(Number(appsCount) / Number(pageSize)));
    };
    const filterBySearchPhrase = (e) => {
        setApps(
            props.apps.filter(
                (app) =>
                    app.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    app.appId.includes(e.target.value) ||
                    app.organisationName.toLowerCase().includes(e.target.value.toLowerCase())
            )
        );
    };

    const paginate = (index) => {
        setSelectedPage(index + 1);
        const arraySliceStart = index === 0 ? 0 : (index + 1) * selectedPageSize - selectedPageSize;

        pageApps(arraySliceStart, selectedPageSize);
    };
    const columns = [
        {
            name: intl.formatMessage({ id: 'Admin.AppList.AppNameCol' }),
            displayFormat: (x) => (
                <b className="text-capitalize"> {textDisplay.truncateText(x.title, defaultColumnTextLength)} </b>
            ),
        },
        {
            name: intl.formatMessage({ id: 'Admin.AppList.IdCol' }),
            selector: (x) => textDisplay.truncateText(x.appId, defaultColumnTextLength),
        },
        {
            name: intl.formatMessage({ id: 'Admin.AppList.OrganisationsCol' }),
            displayFormat: (x) => (
                <span className="text-capitalize">
                    {' '}
                    {textDisplay.truncateText(x.organisationName, defaultColumnTextLength)}{' '}
                </span>
            ),
        },
        {
            name: intl.formatMessage({ id: 'Admin.AppList.ApiCol' }),
            selector: (x) => textDisplay.formatPascalText(x.apiType),
        },
        {
            name: intl.formatMessage({ id: 'Admin.AppList.SubmissionDateCol' }),
            selector: (x) => {
                if (x.submittedDate === null) {
                    return '';
                } else {
                    const date = new Date(x.submittedDate);
                    const dateFormat = {
                        dateStyle: 'medium',
                        timeStyle: 'short',
                    };
                    return new Intl.DateTimeFormat(undefined, dateFormat).format(date);
                }
            },
        },
        {
            name: intl.formatMessage({ id: 'Admin.AppList.LastSubmittedCol' }),
            selector: (x) => {
                if (x.submittedDate === null) {
                    return '';
                } else {
                    const submitted = new Date(x.submittedDate);
                    const now = new Date();
                    const utcNow = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
                    const utcSubmitted = Date.UTC(submitted.getFullYear(), submitted.getMonth(), submitted.getDate());
                    const msPerDay = 1000 * 60 * 60 * 24;
                    return intl.formatMessage(
                        { id: 'Admin.AppList.DaysAgo' },
                        { days: Math.floor((utcNow - utcSubmitted) / msPerDay) }
                    );
                }
            },
        },
        {
            name: intl.formatMessage({ id: 'Admin.AppList.StatusCol' }),
            displayFormat: (x) => (
                <div className="">
                    <Tag value={getStatusName(x.submissionStatus)} color={x.statusTag} />
                </div>
            ),
        },
        {
            name: '',
            displayFormat: (x) => (
                <div className="ms-auto mr-1 bd-highlight">
                    <NavLink to={`/Admin/Apps/${x.id}`}>
                        <Chevron className="w-50 h-50 d-inline-block rotate-90deg" />
                    </NavLink>
                </div>
            ),
        },
    ];

    const emptyResult = () => {
        return (
            <div className="col-md-6 offset-md-3 pt-2 align-items-center text-center">
                <div className="text-center mt-5">
                    <AppsIcon className="svg-large" />
                </div>
                <div className="row m-3 p-2">
                    <p className="col-12">
                        <b>
                            <FormattedMessage id="Admin.AppList.NoApps" />
                        </b>
                    </p>
                    <p className="col-12 mb-0">
                        <FormattedMessage id="Common.CheckBackLater" />
                    </p>
                </div>
            </div>
        );
    };

    const rowOnChange = (appId) => {
        navigate(`/Admin/Apps/${appId}`);
    };

    let appType;

    if (props.appType === AppType.public) {
        appType = <FormattedMessage id="Admin.AppList.SubmittedPublicApps" />;
    } else {
        appType = <FormattedMessage id="Admin.AppList.SubmittedPrivateApps" />;
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <h2 className="mb-4">{props.title}</h2>
                    <p>{appType}</p>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <SearchInput
                                placeholder={intl.formatMessage({ id: 'Admin.AppList.SearchPlaceholder' })}
                                onChange={(e) => filterBySearchPhrase(e)}
                            />
                        </div>
                        <div className="col-6 d-flex">
                            <div className="col-8 d-flex justify-content-end">
                                <SelectList
                                    className=""
                                    label={intl.formatMessage({ id: 'Admin.AppList.FilterBy' })}
                                    options={appStatuses}
                                    onChange={handleAppStatusChange}
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-end">
                                <SelectList
                                    label={intl.formatMessage({ id: 'Admin.AppList.PageSize' })}
                                    options={pageSize}
                                    onChange={handlepageSizeChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="pt-3">
                            <DataTable
                                data={apps}
                                columns={columns}
                                isLoading={isLoading}
                                tableName="Apps List table"
                                renderEmptyResult={emptyResult}
                                rowOnChange={rowOnChange}
                                rowClassName="clickable"
                            />
                        </div>
                        {props.apps.length >= selectedPageSize && (
                            <Paginator
                                pages={pages}
                                handleNextButtonClick={paginateNextButtonClick}
                                handlePrevButtonClick={paginatePrevButtonClick}
                                handlePaginate={(i) => paginate(i)}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AppList;
