import React, { useState, useEffect, Fragment } from 'react';
import AppService from '../../services/AppService';
import { useNavigate, useParams } from 'react-router-dom';
import { Label, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import TextWithIcon from '../common/TextWithIcon';
import CopyToClipboard from '../common/CopyToClipboard';
import { ReactComponent as BackIcon } from '../../content/icons/Icon-Arrow-Left.svg';
import appType from '../../enums/appType';
import Tag from '../common/Tag';
import tagColour from '../../enums/tagColour';
import LoadingSpinner from '../common/LoadingSpinner';
import appSubmissionStatus from '../../enums/appSubmissionStatus';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import SideBarContentPage from '../common/SideBarContentPage';
import appNavigationTabs from '../../enums/appNavigationTabs';
import AppNavigationTabLabels from './AppNavigationTabLabels';
import AppBasicInformationPage from './AppBasicInformationPage';
import AppSubmissionPage from './AppSubmissionPage';
import WebhooksLogs from './AppWebhooksLogs';
import WebhooksConfiguration from './AppWebhooksConfiguration';
import AppAuthentication from './AppAuthentication';
import ApiLogs from './AppApiLogs';
import AppGetStartedPage from './AppGetStartedPage';
import AppImageContainer from './AppImageContainer';
import InfoBarManager from '../common/InfoBarManager';
import infoBarType from '../../enums/infoBarType';
import AppCertification from './AppCertification';
import AppSubmissionStatusTag from './AppSubmissionStatusTag';
import AppTestDiariesPage from './AppTestDiariesPage';
import AppStartingGuidePage from './public/AppStartingGuidePage';
import AppStoreListingSubmissionPage from './public/AppStoreListingSubmissionPage';
import AppInstallationPage from './AppInstallationPage';
import { FormattedMessage, useIntl } from 'react-intl';
import environment from '../../enums/environment.js';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import useQueryParam from '../../hooks/useQueryParam';

function AppNavigationBasePage(props) {
    const intl = useIntl();
    const installations = useQueryParam('installations');

    const [application, setApplication] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingProgress, setIsLoadingProgress] = useState(true);
    const [tabLabels, setTabLabels] = useState(AppNavigationTabLabels);
    const [progress, setProgress] = useState(null);
    const [imageUpdated, setImageUpdated] = useState(false);
    const [logoUrl, setLogoUrl] = useState(null);
    const [isTestEnvironment, setIsTestEnvironment] = useState(true);
    const [isInvalidEnvironmentToggle, setIsInvalidEnvironmentToggle] = useState();
    const [currentTabKey, setCurrentTabKey] = useState(appNavigationTabs.getStarted);
    const [infoBars, setInfoBars] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const { authInfo } = useAuthentication();
    const navigate = useNavigate();
    const { appId } = useParams();

    const applicationId = parseInt(appId);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    // NOTE: Tempory code to show the client secret to the UI for testing purposes only (the current app creation flow does not accomodate the requirement for https://resdiary.atlassian.net/browse/RDN-1261).
    let clientSecret = sessionStorage.getItem(`app-${applicationId}-client-secret`);
    if (clientSecret) {
        setInfoBars((i) => [
            ...i,
            { type: infoBarType.success, message: `client secret: ${clientSecret}`, expireIn: 0 },
        ]);
        sessionStorage.removeItem(`app-${applicationId}-client-secret`);
    }

    const addSuccessInfoBar = (message) => {
        setInfoBars((i) => [...i, { type: infoBarType.success, message: message }]);
    };

    const addErrorInfoBar = (message) => {
        setInfoBars((i) => [...i, { type: infoBarType.error, message: message }]);
    };

    const switchEnvironment = (env) => {
        if (env === environment.production) {
            // Check if app status as already approved to be able to switch to the `Live` environment
            if (application.submissionStatus !== 'SubmissionApproved') {
                setIsInvalidEnvironmentToggle(true);
                return;
            }
        }

        setIsTestEnvironment(env === environment.test);
    };

    const onInvalidEnvironmentToggleModelClose = () => {
        setIsInvalidEnvironmentToggle(false);
    };

    useEffect(() => {
        if (installations === 'true') setCurrentTabKey(appNavigationTabs.installations);
    }, [installations]);

    useEffect(() => {
        AppService.getApplicationById(applicationId)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    setApplication(response);

                    if (response.submissionStatus !== appSubmissionStatus.notSubmitted) {
                        const filteredTabLables = AppNavigationTabLabels().filter(
                            (x) => x.key !== appNavigationTabs.getStarted
                        );
                        setTabLabels(filteredTabLables);
                    }
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [applicationId]);

    useEffect(() => {
        if (currentTabKey === appNavigationTabs.getStarted) {
            AppService.getApplicationProgress(applicationId)
                .then((response) => {
                    setProgress(response);
                })
                .catch((error) => {})
                .finally(() => {
                    setIsLoadingProgress(false);
                });
        }
    }, [applicationId, currentTabKey]);

    useEffect(() => {
        if (isLoading) return;

        if (application.appType === appType.public) {
            const startIndex = AppNavigationTabLabels().findIndex((t) => t.key === appNavigationTabs.logs);
            const tabLabelsCopy = [...AppNavigationTabLabels()];
            const storeListing = {
                key: appNavigationTabs.storeListing,
                name: intl.formatMessage({ id: 'AppManagement.AppStoreListingSubmissionPage.StoreListing' }),
                isActive: true,
                subItems: [
                    {
                        key: appNavigationTabs.startingGuide,
                        name: intl.formatMessage({ id: 'AppManagement.AppStoreListingSubmissionPage.StartingGuide' }),
                    },
                    {
                        key: appNavigationTabs.appSubmission,
                        name: intl.formatMessage({
                            id: 'AppManagement.AppStoreListingSubmissionPage.AppListingSubmission',
                        }),
                    },
                ],
            };

            tabLabelsCopy.splice(startIndex, 0, storeListing);
            setTabLabels(tabLabelsCopy);
        }
    }, [isLoading, application, intl]);

    function getAppId() {
        return (
            <div className="app-id-container">
                <Label>
                    <FormattedMessage id="AppManagement.AppNavigationBasePage.AppID" />
                    {` ${application.appId}`}
                </Label>
                <CopyToClipboard id="appId" text={application.appId} />
            </div>
        );
    }

    function getTabs() {
        // insert navigation components here with appropriate key
        const tabs = [
            <AppGetStartedPage
                key={appNavigationTabs.getStarted}
                onTabChanged={OnTabChanged}
                application={application}
                appProgress={progress}
                isTestEnvironment={isTestEnvironment}
            />,
            <AppBasicInformationPage
                key={appNavigationTabs.basicInformation}
                onTabChanged={OnTabChanged}
                application={application}
                onChange={setApplication}
                imageUpdated={imageUpdated}
                setImageUpdated={setImageUpdated}
                setLogoUrl={setLogoUrl}
            />,
            <AppAuthentication
                key={appNavigationTabs.authentication}
                application={application}
                isTestEnvironment={isTestEnvironment}
            />,
            <AppCertification
                key={appNavigationTabs.certification}
                application={application}
                onTabChanged={OnTabChanged}
            />,
            <AppTestDiariesPage key={appNavigationTabs.testDiary} application={application} />,
            <AppSubmissionPage
                key={appNavigationTabs.submitApp}
                application={application}
                onTabChanged={OnTabChanged}
                onSuccess={addSuccessInfoBar}
                onError={addErrorInfoBar}
                appProgress={progress}
            />,
            <WebhooksLogs key={appNavigationTabs.webhookLogs} application={application} />,
            <WebhooksConfiguration
                key={appNavigationTabs.webhookConfiguration}
                application={application}
                onTabChanged={OnTabChanged}
                isTestEnvironment={isTestEnvironment}
            />,
            <ApiLogs key={appNavigationTabs.apiLogs} application={application} />,
            <AppStartingGuidePage key={appNavigationTabs.startingGuide} application={application} />,
            <AppStoreListingSubmissionPage
                key={appNavigationTabs.appSubmission}
                application={application}
                onSuccess={addSuccessInfoBar}
                onError={addErrorInfoBar}
                setIsLoading={(e) => setIsLoading(e)}
            />,
            <AppInstallationPage key={appNavigationTabs.installations} application={application} />,
        ];

        return tabs;
    }

    function OnTabChanged(tabKey) {
        if (tabKey !== currentTabKey) {
            setCurrentTabKey(tabKey);
        }
    }

    function SetIsOpen(key) {
        const labelsCopy = [...tabLabels];
        const index = labelsCopy.findIndex((t) => t.key === key);
        labelsCopy[index].isActive = !labelsCopy[index].isActive;
        setTabLabels(labelsCopy);
    }

    if (isLoading || isLoadingProgress) {
        return <LoadingSpinner />;
    }

    return (
        <div className="app-navigation-page">
            <InfoBarManager infoBarArray={infoBars} />
            <div className={`app-navigation-header ${authInfo?.isAdmin ? ' admin' : ''}`}>
                <div className="back-icon" onClick={() => navigate(!authInfo.isAdmin ? `/Apps` : '/Admin/Apps')}>
                    <TextWithIcon icon={<BackIcon />} text="BACK TO APPS" textClass="back-label" />
                </div>

                <div className="app-info d-flex mt-3">
                    <div className="app-icon-container">
                        <AppImageContainer application={application} imageUpdated={imageUpdated} logoUrl={logoUrl} />
                    </div>
                    <div className="flex-column ml-2">
                        <h1>{application.title}</h1>
                        {getAppId()}
                        <div className="d-flex">
                            <Tag
                                value={application.appType}
                                color={application.appType === appType.private ? tagColour.Blue : tagColour.Purple}
                            />
                            <AppSubmissionStatusTag submissionStatus={application.submissionStatus} />
                            <div className="rd-tag">
                                <Dropdown className="environment-dropdown" isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle caret>Environment (Live)</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem header>Environment</DropdownItem>
                                        <DropdownItem onClick={() => switchEnvironment(environment.production)} active>
                                            Live
                                        </DropdownItem>
                                        <DropdownItem onClick={switchEnvironment}>Test</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Fragment>
                <SideBarContentPage
                    tabLabels={tabLabels}
                    tabs={getTabs()}
                    onTabChanged={OnTabChanged}
                    currentTabKey={currentTabKey}
                    isLoading={isLoading}
                    setIsOpen={SetIsOpen}
                    isNotCollapsible={false}
                />
            </Fragment>
            <Modal centered isOpen={isInvalidEnvironmentToggle} toggle={onInvalidEnvironmentToggleModelClose}>
                <ModalHeader className="border-bottom-0" toggle={onInvalidEnvironmentToggleModelClose}>
                    <FormattedMessage id="AppManagement.AppNavigationBasePage.UnableToSwitchToLive" />
                </ModalHeader>
                <ModalBody>
                    <FormattedMessage id="AppManagement.AppNavigationBasePage.UnableToSwitchDesc" />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onInvalidEnvironmentToggleModelClose}>
                        <FormattedMessage id="AppManagement.AppNavigationBasePage.Close" />
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default AppNavigationBasePage;
