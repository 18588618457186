import React, { Fragment, useEffect, useState } from 'react';
import WebhooksService from '../../services/WebhooksService';
import WebhooksSettingsList from './WebhooksSettingsList';
import LoadingSpinner from '../common/LoadingSpinner';
import Toggle from '../common/Toggle';
import appSubmissionStatus from '../../enums/appSubmissionStatus';
import { Alert, Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip } from 'reactstrap';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import useValidation from '../../hooks/useValidation';
import ValidationInput from '../common/ValidationInput';
import appNavigationTabs from '../../enums/appNavigationTabs';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as InfoIcon } from '../../content/icons/Icon-Info.svg';

function AppWebhooksConfiguration(props) {
    const appId = props.application.id;
    const { authInfo } = useAuthentication();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const [webhookSettings, setWebhookSettings] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenSuccessModal, setOpenSuccessModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const { register, errors, errorCount } = useValidation();
    const intl = useIntl();

    useEffect(() => {
        setIsLoading(true);
        let params = {
            isTestEnvironment: props.isTestEnvironment,
            appId,
            organisationId: props.application.organisationId,
        };

        WebhooksService.getSettings(params)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    setWebhookSettings(response);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [props.isTestEnvironment, appId, props.application.organisationId]);

    const toggleEditing = () => {
        setIsEditing(!isEditing);
    };

    const onSuccessModalClose = () => {
        setOpenSuccessModal(false);
        props.onTabChanged(appNavigationTabs.testDiary);
    };

    const onEventTypeChanged = (eventType, isSubscribed) => {
        let updateWebhooksTypes = webhookSettings.webhooksTypes.map((item) => {
            if (item.id === eventType.id) {
                item.isSubscribed = isSubscribed;
            }
            return item;
        });
        let updateSettings = {
            ...webhookSettings,
            webhooksTypes: updateWebhooksTypes,
        };
        setWebhookSettings(updateSettings);
    };

    const onRestaurantChanged = (eventType, isSubscribed) => {
        let updateRestaurants = webhookSettings.providers.map((item) => {
            if (item.id === eventType.id) {
                item.isSubscribed = isSubscribed;
            }
            return item;
        });

        let updateSettings = {
            ...webhookSettings,
            providers: updateRestaurants,
        };

        setWebhookSettings(updateSettings);
    };

    const saveWebhooksSettings = () => {
        WebhooksService.updateSettings({
            isTestEnvironment: props.isTestEnvironment,
            appId,
            organisationId: props.application.organisationId,
            settings: webhookSettings,
        }).then((response) => {
            if (response === true) {
                setOpenSuccessModal(true);
            }
        });
    };

    const reEnableWebhooks = () => {
        WebhooksService.reEnableWebhooks({
            isTestEnvironment: props.isTestEnvironment,
            appId,
            organisationId: props.application.organisationId,
        }).then((response) => {
            if (response === true) {
                let updateSettings = {
                    ...webhookSettings,
                    webhooksDisabledBySystem: false,
                };
                setWebhookSettings(updateSettings);
                setOpenSuccessModal(true);
            }
        });
    };

    const handleInputChange = (key, value) => {
        setWebhookSettings({ ...webhookSettings, [key]: value });
    };

    const enableWebhooks = (isEnabled) => {
        let updateSettings = {
            ...webhookSettings,
            areWebhooksEnabled: isEnabled,
        };

        if (isEnabled) {
            let updateWebhooksTypes = webhookSettings.webhooksTypes.map((item) => {
                item.isSubscribed = true;
                return item;
            });

            updateSettings.webhooksTypes = updateWebhooksTypes;
        }
        setWebhookSettings(updateSettings);
    };

    return (
        <div>
            {isLoading ? (
                <LoadingSpinner loadingText="Loading..." />
            ) : (
                <div className="row container-fluid col-md-8 p-0">
                    <Fragment>
                        <fieldset className="w-100" disabled={!isEditing && authInfo.isAdmin}>
                            <div className="col-md-10">
                                <h1>
                                    <FormattedMessage id="AppManagement.WebhookConfig.PageHeader" />
                                </h1>

                                <div className="mt-3">
                                    <label>
                                        <FormattedMessage id="AppManagement.WebhookConfig.PageDescription" />
                                    </label>
                                </div>
                            </div>
                            {webhookSettings.webhooksDisabledBySystem && (
                                <div className="col-md-10">
                                    <Alert className="row" color="danger">
                                        <div className="col-md-10">
                                            <p>
                                                <FormattedMessage id="AppManagement.WebhookConfig.AutoDisabled" />
                                                <br />
                                                <FormattedMessage id="AppManagement.WebhookConfig.AutoDisabledFix" />
                                            </p>
                                        </div>
                                        <div className="col-md-2">
                                            <Button color="danger" onClick={reEnableWebhooks}>
                                                {intl.formatMessage({ id: 'AppManagement.WebhookConfig.ReEnable' }) +
                                                    ' ' +
                                                    intl.formatMessage({ id: 'AppManagement.WebhookConfig.Webhooks' })}
                                            </Button>
                                        </div>
                                    </Alert>
                                </div>
                            )}
                            <div className="col-md-10">
                                <FormGroup row>
                                    <Label sm={4}>
                                        <h5>
                                            {intl.formatMessage({ id: 'AppManagement.WebhookConfig.Enable' }) +
                                                ' ' +
                                                intl.formatMessage({ id: 'AppManagement.WebhookConfig.Webhooks' })}
                                        </h5>
                                    </Label>
                                    <Col sm={6}>
                                        <Toggle
                                            disabled={!isEditing && authInfo.isAdmin}
                                            className="ml-0"
                                            checked={webhookSettings.areWebhooksEnabled}
                                            handleChange={enableWebhooks}
                                            checkedLabel="Yes"
                                            uncheckedLabel="No"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="webhook-url">
                                        <h6>
                                            <FormattedMessage id="AppManagement.WebhookConfig.Url" />
                                        </h6>
                                    </Label>
                                    <ValidationInput
                                        type="url"
                                        name="webhooksUrl"
                                        placeholder={intl.formatMessage({
                                            id: 'AppManagement.WebhookConfig.UrlPlaceholder',
                                        })}
                                        value={webhookSettings.webhooksUrl || ''}
                                        onChange={(e) => handleInputChange('webhooksUrl', e.currentTarget.value)}
                                        innerRef={register({
                                            required: intl.formatMessage({
                                                id: 'AppManagement.WebhookConfig.UrlRequired',
                                            }),
                                            pattern: {
                                                value: /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/gi, // eslint-disable-line no-control-regex
                                                message: intl.formatMessage({
                                                    id: 'AppManagement.WebhookConfig.UrlMessage',
                                                }),
                                            },
                                        })}
                                        errors={errors}
                                        autoComplete="off"
                                        disabled={!webhookSettings.areWebhooksEnabled}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="webhook-secret">
                                        <h6 class="webhooks-secret-label-icon">
                                            {intl.formatMessage({ id: 'AppManagement.WebhookConfig.Webhooks' }) +
                                                ' ' +
                                                intl.formatMessage({ id: 'AppManagement.WebhookConfig.Secret' })}
                                            <span className="badge">
                                                <i color="secondary" id={'webhook-secret-info'}>
                                                    <InfoIcon id="info-icon" />
                                                </i>
                                            </span>
                                        </h6>
                                        <Tooltip
                                            placement="top"
                                            isOpen={tooltipOpen}
                                            target={'webhook-secret-info'}
                                            toggle={toggle}
                                        >
                                            <FormattedMessage id="AppManagement.WebhookConfig.SecretDescription" />
                                        </Tooltip>
                                    </Label>
                                    <ValidationInput
                                        type="text"
                                        name="webhooksSecret"
                                        placeholder={intl.formatMessage({
                                            id: 'AppManagement.WebhookConfig.SecretPlaceholder',
                                        })}
                                        value={webhookSettings.webhooksSecret || ''}
                                        onChange={(e) => handleInputChange('webhooksSecret', e.currentTarget.value)}
                                        innerRef={register({
                                            required: intl.formatMessage({
                                                id: 'AppManagement.WebhookConfig.SecretRequired',
                                            }),
                                            minLength: {
                                                value: 10,
                                                message: intl.formatMessage({
                                                    id: 'AppManagement.WebhookConfig.SecretMessage',
                                                }),
                                            },
                                        })}
                                        errors={errors}
                                        autoComplete="off"
                                        disabled={!webhookSettings.areWebhooksEnabled}
                                    />
                                </FormGroup>
                            </div>
                            <hr />
                            <div className="col-md-10">
                                <WebhooksSettingsList
                                    title={intl.formatMessage({ id: 'AppManagement.WebhookConfig.EventTypes' })}
                                    items={webhookSettings.webhooksTypes}
                                    onSettingChanged={onEventTypeChanged}
                                    disabled={!webhookSettings.areWebhooksEnabled}
                                />
                            </div>
                        </fieldset>
                        <hr />
                        <div className="col-md-10">
                            {webhookSettings.providers.length > 0 && (
                                <WebhooksSettingsList
                                    title={intl.formatMessage({ id: 'AppManagement.WebhookConfig.EnabledDiaries' })}
                                    items={webhookSettings.providers}
                                    onSettingChanged={onRestaurantChanged}
                                />
                            )}
                        </div>

                        <div className="col-md-10">
                            <hr />
                            {authInfo.isAdmin && !isEditing && (
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={props.application.submissionStatus === appSubmissionStatus.submitted}
                                    onClick={() => toggleEditing()}
                                >
                                    <FormattedMessage id="Common.Edit" />
                                </Button>
                            )}

                            {(!authInfo.isAdmin || isEditing) && (
                                <Button
                                    color="primary"
                                    disabled={errorCount > 0 && webhookSettings.areWebhooksEnabled}
                                    className="mr-2"
                                    onClick={saveWebhooksSettings}
                                >
                                    <FormattedMessage id="Common.SaveAndContinue" />
                                </Button>
                            )}

                            {authInfo.isAdmin && isEditing && (
                                <Button type="submit" color="outline-primary" onClick={() => toggleEditing()}>
                                    <FormattedMessage id="Common.Cancel" />
                                </Button>
                            )}
                        </div>
                    </Fragment>
                </div>
            )}
            <Modal centered isOpen={isOpenSuccessModal} toggle={onSuccessModalClose}>
                <ModalHeader className="border-bottom-0" toggle={onSuccessModalClose}>
                    <FormattedMessage id="AppManagement.WebhookConfig.SuccessModalHeader" />
                </ModalHeader>
                <ModalBody>
                    <p>
                        <FormattedMessage id="AppManagement.WebhookConfig.SuccessModalDescription" />
                    </p>
                    <p>
                        <FormattedMessage id="AppManagement.WebhookConfig.SuccessModalMessage" />
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onSuccessModalClose}>
                        <FormattedMessage id="Common.Close" />
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default AppWebhooksConfiguration;
