import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import AnalyticsHelper from '../helpers/AnalyticsHelper';
import GeneralConfiguration from '../model/configuration/GeneralConfiguration';
import ConfigurationService from '../services/ConfigurationService';

const ConfigurationsContext = React.createContext<IConfigurationsContext>({
    canCreateAccount: false,
});

interface IConfigurationsContext {
    canCreateAccount: boolean;
}

type ConfigurationsProviderProps = {
    children: React.ReactNode;
};

export const useConfigurations = () => {
    return useContext(ConfigurationsContext);
};

export const ConfigurationsProvider = ({ children }: ConfigurationsProviderProps) => {
    const [configsLoaded, setConfigsLoaded] = useState(false);
    const [configurations, setConfigurations] = useState<GeneralConfiguration>({
        canCreateAccount: false,
        segmentKey: null,
    });

    useEffect(() => {
        if (!configsLoaded) {
            ConfigurationService.getConfigurationSettings().then((configSettings) => {
                setConfigurations(configSettings as GeneralConfiguration);
                if ('segmentKey' in configSettings) {
                    AnalyticsHelper.loadAnalytics(configSettings.segmentKey);
                }
                setConfigsLoaded(true);
            });
        }
    }, [configsLoaded]);

    return (
        <ConfigurationsContext.Provider value={configurations}>
            {configsLoaded && children}
        </ConfigurationsContext.Provider>
    );
};
