import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import EposCredentialDisplay from './EposCredentialDisplay';

const EposAccessibleProviderRow = (props) => {
    return (
        <tr>
            <td>{props.accessibleProvider.providerId}</td>
            <td>{props.accessibleProvider.name}</td>
            <td>{props.accessibleProvider.providerGroup}</td>
            <td>{props.accessibleProvider.deployment}</td>
            <td>
                <EposCredentialDisplay
                    value={props.accessibleProvider.secondSecret}
                    id={`cred${props.accessibleProvider.providerId}`}
                />
            </td>
            <td>
                <Button color="primary" onClick={props.onButtonClick} className="float-right">
                    Regenerate
                </Button>
            </td>
        </tr>
    );
};

EposAccessibleProviderRow.propTypes = {
    accessibleProvider: PropTypes.object.isRequired,
    onButtonClick: PropTypes.func.isRequired,
};

export default EposAccessibleProviderRow;
