import * as React from 'react';
import { Fragment } from 'react';
import LoadingSpinner from './LoadingSpinner';
import { Collapse } from 'reactstrap';
const SideBarContentPage = (props: SideBarContentPageProps) => {
    const isSubItemActive = (label: SideBarTabLabel): boolean => {
        if (label.subItems) {
            for (let i = 0; i < label.subItems.length; i++) {
                if (label.subItems[i].isActive) {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    };

    const getTabLabels = () => {
        const labels: JSX.Element[] = [];
        props.tabLabels.forEach((label) => {
            let tabStyles =
                label.isActive || isSubItemActive(label)
                    ? 'tab text-uppercase bold-text active'
                    : 'tab text-uppercase bold-text';

            if (label.subItems && label.subItems.length > 0) {
                const labelElement = (
                    <Fragment key={label.key}>
                        <div key={label.key} className={tabStyles}>
                            <div onClick={() => props.onTabChanged(label.key)} className={'tab-header'}>
                                {label.name}
                            </div>
                        </div>
                        <div className="tab-collapse">
                            <Collapse isOpen={label.isActive || isSubItemActive(label)}>
                                {label.subItems!.map((subItem: SideBarTabLabel) => {
                                    let subItemStyle = 'subItem';
                                    if (props.currentTabKey === subItem.key) {
                                        subItemStyle += ' selected';
                                    }
                                    return (
                                        <div
                                            onClick={() => props.onTabChanged(subItem.key)}
                                            key={subItem.key}
                                            className={subItemStyle}
                                        >
                                            {subItem.name}
                                        </div>
                                    );
                                })}
                            </Collapse>
                        </div>
                    </Fragment>
                );

                labels.push(labelElement);
            } else {
                const labelElement = (
                    <div onClick={() => props.onTabChanged(label.key)} key={label.key} className={tabStyles}>
                        {label.name}
                    </div>
                );
                labels.push(labelElement);
            }
        });
        return labels;
    };

    const getContent = () => {
        const pageContent = props.tabs.find((x) => x.key === props.currentTabKey);
        return <div>{pageContent}</div>;
    };

    const getPageContent = () => {
        if (props.isLoading) {
            return <LoadingSpinner />;
        } else {
            return (
                <div className="flex-container-inline row">
                    <div className="sidebar-content-column col-md-2">{getTabLabels()}</div>
                    <div className={`sidebar-content col-xl ${props.tabbedContentClass}`}>{getContent()}</div>
                </div>
            );
        }
    };

    return (
        <Fragment>
            <div className="overflow-hidden">{getPageContent()}</div>
        </Fragment>
    );
};

export interface SideBarContentPageProps {
    tabLabels: SideBarTabLabel[];
    currentTabKey: string;
    tabs: JSX.Element[];
    onTabChanged(tabKey: string): void;
    isLoading?: boolean;
    tabbedContentClass?: string;
    isNotCollapsible?: boolean;
    setIsOpen?(key: string): void;
}

export interface SideBarTabLabel {
    key: string;
    name: string;
    isActive?: boolean;
    subItems?: SideBarTabLabel[];
}

export default SideBarContentPage;
